(function () {
    'use strict';

    Services.ReactModalService = class ReactModalService {
        // @ngInject
        constructor($rootScope, $q, HelpService, DatadogRUMService) {
            this.$rootScope = $rootScope;
            this.$q = $q;
            this.HelpService = HelpService;
            this.isOpen = false;
            this.defer = null;

            this.onClose = (optionalResolveData) => {
                // successful closing
                this.defer && this.defer.resolve(optionalResolveData);
                this.reset();
            };

            this.onDismiss = (optionalRejectData) => {
                // dismissing the modal
                this.defer && this.defer.reject(optionalRejectData);
                this.reset();
            };
            this.DatadogRUMService = DatadogRUMService;
        }

        reset() {
            this.$rootScope.$applyAsync(() => {
                this.isOpen = false;
                this.defer = null;

                setTimeout(() => {
                    this.$rootScope.$applyAsync(() => {
                        this.componentName = '';
                    });
                }, 150);
            });
            this._unHideHelpService();
        }

        _closeModalOnStateChange() {
            const unsubscribe = this.$rootScope.$on('$stateChangeSuccess', () => {
                this.onClose();
                unsubscribe();
            });
        }

        _openModal(componentName, data) {
            this.defer = this.$q.defer();
            this.componentName = componentName;
            this.isOpen = true;
            this.data = data;
            
            // when the user clicks the browser back button, the modal will be dismissed
            this._closeModalOnStateChange();

            return this.defer.promise;
        }

        openGlobalModal(modalName, data) {
            return new Promise((resolve, reject) => {
                this.globalModalName = modalName;

                const onCloseOfGlobalModalOpenedFromAngular = () => {
                    this.globalModalName = null;
                    this.data = null;
                };

                this.data = Object.assign(data, {
                    resolve,
                    reject,
                    onCloseOfGlobalModalOpenedFromAngular
                });
            });
        }

        openInviteTeamMemberModal() {
            return this._openModal('InviteTeamMembersModalRoot');
        }

        openEditTeamMemberRoleModal(data) {
            return this._openModal('EditTeamMemberRoleModalRoot', data);
        }

        openScheduleSessionPreview(data) {
            return this._openModal('SchedulerTroubleShootModalRoot', data);
        }

        _hideHelpService() {
            this.isHelpServiceHidden = true;
            this.HelpService.hideHelp();
        }

        _unHideHelpService() {
            if (this.isHelpServiceHidden) {
                this.isHelpServiceHidden = false;
                this.HelpService.showHelp();
            }
        }

        openBlockFlowsModal() {
            return this._openModal('BlockFlowsModalRoot');
        }

        openEmailVerificationModal() {
            return this._openModal('EmailVerificationModalRoot');
        }

        openLeadFormModal() {
            return this._openModal('CreateLeadFormModalRoot');
        }

        openTimeTrackerWorkspaceModal(data) {
            return this._openModal('TimeTrackerWorkspaceEntryModalRoot', data);
        }

        openAddServiceTemplateModal(data) {
            return this._openModal('ServiceTemplateModalRoot', data);
        }

        openMobileAppQRCodeModalRoot() {
            return this._openModal('MobileAppQRCodeModalRoot');
        }

        openUpgradePlanModal(data) {
            return this._openModal('UpgradePlanModalRoot', data);
        }

        openRefundModal(data) {
            return this._openModal('RefundModalRoot', data);
        }

        openCreateFlowModal(data) {
            data.isNavBarScoped = true;
            return this._openModal('CreateFlowRoot', data);
        }

        openSendTestOnboardingModal() {
            return this._openModal('SendTestOnboardingModalRoot');
        }

        openEmailEditorModal(options) {
            if(!options || (options && !options.config) || (options && options.config && !options.config.editorType)) {
              this.DatadogRUMService.addError(new Error('no config in email editor options'), {options});
            }
            const _options = Object.assign(options, {
                users: options.users.map(
                    (user) => (user.dataOnly && user.dataOnly()) || user
                ),
            });
            return this._openModal("OneComposerModalRoot", _options);
        }

        openFeesComparisonModal() {
            return this._openModal('FeesComparisonModalRoot');
        }

        openEnableCardOnFileModal(data) {
            return this._openModal('EnableCardOnFileModalRoot', data);
        }

        openEditProjectTypesModal(source, onSaveHandler) {
            this._hideHelpService();
            return this._openModal('EditProjectTypesModalRoot', { source, onSaveHandler });
        }

        openCustomProjectFieldsModal(source) {
            return this.openGlobalModal('CustomProjectFieldsModalWrapper', {source});
        }

        openCustomContactFieldsModal(source) {
            return this.openGlobalModal('CustomContactFieldsModalWrapper', {source});
        }

        openTagsModal() {
            return this.openGlobalModal('CompanyTagsModalWrapper', {});
        }

        openDisabledTeamMemberDialog() {
            return this._openModal('DisabledTeamMemberDialogRoot');
        }

        openDisabledCompanyDialog() {
            return this._openModal('DisabledCompanyDialogRoot');
        }

        openTrialerDialog(data) {
            return this._openModal('ExTrialerDialogRoot', data);
        }

        openCanceledAccountDialog(data) {
            return this._openModal('CanceledAccountDialogRoot', data);
        }

        openReferralModal() {
            return this._openModal('ReferralModalRoot');
        }

        openAppStoreModal() {
            return this._openModal('AppStoreModalRoot');
        }

        openPicTimeWelcomeModal() {
            return this._openModal('PicTimeWelcomeModalRoot');
        }

        openCreateClientModal() {
            return this.openGlobalModal('AddClientFromTopNavModalWrapper', {});
        }
        openUploadPDFModal() {
            return this._openModal('ImportPDFModalRoot', { source: 'lifecycle email deeplink' });
        }

        openPicTimeSuccessModal(eligible, source) {
            return this._openModal('PicTimeSuccessModalRoot', {isCouponEligible: eligible, source: source});
        }

        openTINResolveModal() {
            return this._openModal('TINResolveModalRoot');
        }

        openOooInEffectModal() {
            this.openGlobalModal('OooInEffectModalWrapper', {});
        }

        openOooHasEndedModal() {
            this.openGlobalModal('BackFromOooModalWrapper', {});
        }

        openTemplatesForLegacyUsersModal(){
            return this._openModal('LegacyUsersImportTemplateDialogRoot');
        }

        openSignupModal(){
            return this._openModal('SignupModalRoot');
        }
    };
})();
