(function () {
    "use strict";

    Services.ContactFormService = class ContactFormService {

        // @ngInject
        constructor($timeout, $translate, _, UsersManager, ToastService, $q, PopupMessageService, CompaniesManager, QuestionsStateService,
                    ngToast, UiPersistenceService, AnalyticsService, ModalService, AppConfigService, TemplatesManager, OnboardingService,
                    Enums, PubSubService, UIUtils, FeaturesService, AbTestService, CompanyService, StatsigService) {
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.ngToast = ngToast;
            this.UiPersistenceService = UiPersistenceService;
            this.AnalyticsService = AnalyticsService;
            this.ModalService = ModalService;
            this.AppConfigService = AppConfigService;
            this.QuestionsStateService = QuestionsStateService;
            this._ = _;
            this.PopupMessageService = PopupMessageService;
            this.ToastService = ToastService;
            this.CompaniesManager = CompaniesManager;
            this.$q = $q;
            this.UsersManager = UsersManager;
            this.TemplatesManager = TemplatesManager;
            this.OnboardingService = OnboardingService;
            this.Enums = Enums;
            this.UIUtils = UIUtils;
            this.FeaturesService = FeaturesService;
            this.AbTestService = AbTestService;
            this.CompanyService = CompanyService

            this.thankYouPreview = false;
            this.contactFormToast = null;
            this.dismissExamplesBanner = false;

            this.appState = this.AppConfigService.appState();

            PubSubService.ventMyBitchUp(this);

			this.asideMenuData = {
				menuItemsStack : []
            };
            this.StatsigService = StatsigService;
            this.isLeadFormSetupGuide = false;

            this.StatsigService.getExperimentConfigValue(Enums.StatsigExperiments.leadFormsSetupGuide, 'lead_forms_setup_guide', false).then(function (variant) {
                this.isLeadFormSetupGuide = variant;
            }.bind(this));

            this.shouldShowInstantPayment = false;

            StatsigService.isGateEnabled(Enums.StatsigFeatureGates.giftCardQuickPay).then(function (result) {
                this.shouldShowInstantPayment = result;
            }.bind(this));
        }

        initSideBarStack(contactForm) {

            this.asideMenuData.menuItemsStack = [
                {
                    displayText: "Options",
                    value: this.Enums.contactFormSideBarMenuKeys.options,
                    list: [
                        {
                            iconClassName: 'icon-hb-nx-wand',
                            displayText: "Design",
                            value: this.Enums.contactFormSideBarMenuKeys.design,
                            list: [
                                {displayText: "Colors", iconClassName: 'icon-hb-nx-drop', value: this.Enums.contactFormSideBarMenuKeys.colors},
                                {displayText: "Fonts", iconClassName: 'icon-hb-nx-font', value: this.Enums.contactFormSideBarMenuKeys.fonts}
                            ]
                        },
                        {displayText: "Settings", iconClassName: 'icon-hb-nx-screwdriver', value: this.Enums.contactFormSideBarMenuKeys.settings},

                    ]
                }
            ];

            if(contactForm.isGiftCard()) {
                this.asideMenuData.menuItemsStack[0].list.push({
                    displayText: "Gift Cards", iconClassName: 'icon-hb-nx-gift-card-24', value: this.Enums.contactFormSideBarMenuKeys.giftCards
                });
            } else {
                this.asideMenuData.menuItemsStack[0].list.push({
                    displayText: "Contact Forms", iconClassName: 'icon-hb-nx-file-plain', value: this.Enums.contactFormSideBarMenuKeys.contactForms
                });
            }
		}

        getSideBarItemByKey(key) {
            return this.UIUtils.findDeep(this.asideMenuData.menuItemsStack, (item) => item.value === key, 'list');
        }

        resetSideBarStack() {
            this.asideMenuData.menuItemsStack = this.asideMenuData.menuItemsStack.slice(0, 1);
        }

        pushItemToSideBarStack(item) {
            this.asideMenuData.menuItemsStack.push(item);
        }

        getCurrentSideBarStackItem() {
            return this.asideMenuData.menuItemsStack[this.asideMenuData.menuItemsStack.length - 1];
        }

        removeSideBarStackItemsAfterIndex(index) {
            this.asideMenuData.menuItemsStack = this.asideMenuData.menuItemsStack.slice(0, index + 1);
        }

        hasItemsOnSideBarStack() {
            return this.asideMenuData.menuItemsStack.length > 1;
        }

        _successfulPublish() {
            this.AnalyticsService.track(this, 'Contact form publish was successful', {
                companyId: this._getCompany()._id
            });
        }

        _successfulGiftCardPublish() {
            this.AnalyticsService.track(this, 'success: gift card published', {
                companyId: this._getCompany()._id
            });
        }

        openGiftCardDirectLinkModal(contactFormModel) {
            this.ModalService.openGiftCardDirectLinkModal(contactFormModel)
        }

        openEmbedCodeModal(contactFormState, contactFormModel) {
            return this.ModalService.openEmbededContactFormModal(contactFormModel, this._getCompany(), contactFormState).then(function() {
                const contactFormDynamicOnboarding = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.contactFormDynamicOnboarding, {});
                if (!contactFormDynamicOnboarding.seenPublishModal) {
                    this.UiPersistenceService.setUiPersistenceInnerKey(this.UiPersistenceService.keys.contactFormDynamicOnboarding, 'seenPublishModal', true);
                }
                if (!this.isLeadFormSetupGuide) {
                    this.OnboardingService.onContactFormPublished();
                }
            }.bind(this));
        }

        _showToast(translateKey, icon) {
            this.$timeout(() => {
                this.ToastService.showSuccess({
                    contentTranslation: translateKey,
                    iconCssClass: icon,
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout:3000
                });
            }, 1000);
        }

        _failureInPublish() {
            this.AnalyticsService.track(this, 'Contact from publish failed', {
                companyId: this._getCompany()._id
            });
        }

        _failureInReset() {
            this.AnalyticsService.track(this, 'Contact from reset failed', {
                companyId: this._getCompany()._id
            });
        }

        _isFormValid(contactFormModel) {
            let isFormValid = contactFormModel.isValid();
            this.QuestionsStateService.triggerErrorCheck();
            return isFormValid;
        }

        _isRedirectUrlValid(contactFormModel) {
            let redirect_url = contactFormModel.contact_form_settings.redirect_url;
            let redirect_type = contactFormModel.contact_form_settings.redirect_type;

            let isValid = true;
            if (redirect_type === "custom_url" && this._.isEmpty(redirect_url)) {
                isValid = false;
            }
            return isValid;
        }

        openContactFormPreview(contactFormModel) {

            var deferred = this.$q.defer();
            if (contactFormModel.data_model === 'dynamic_file') {

                this.ModalService.openPreviewContactFormModal(this._getCompany(), contactFormModel);
                deferred.resolve();
            } else {
                // this.gettingPreviewCode = true;
                contactFormModel.preview().then(function gotFormHtml(resp) {
                    deferred.resolve();
                    var wnd = window.open("about:blank", "");
                    wnd.document.write(resp.data.contact_form_html);
                }.bind(this));
            }

            return deferred.promise;
        }

        _validateFormAndSettingsPane(contactFormModel) {
            let isFormValid = this._isFormValid(contactFormModel);
            let isRedirectUrlValid = this._isRedirectUrlValid(contactFormModel);

            let resultObject = {
                isValid: true,
                error: {
                    type: '',
                    message: ''
                }
            };

            if (!isFormValid) {
                resultObject.isValid = false;
                resultObject.error.message = this.$translate.instant('CONTACT_FORM.ERRORS._INVALID_FORM_');
                resultObject.error.type = this.Enums.contactFormErrorValidationTypes.general;
            } else if (!isRedirectUrlValid) {
                resultObject.isValid = false;
                resultObject.error.message = this.$translate.instant('CONTACT_FORM.ERRORS._INVALID_REDIRECT_URL_');
                resultObject.error.type = this.Enums.contactFormErrorValidationTypes.redirectUrl;
            }

            return resultObject;
        }

        isFormAndSettingsValid(contactFormModel) {
            const validationResult = this._validateFormAndSettingsPane(contactFormModel);

            if (validationResult.isValid) {
                return true;
            }

            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, validationResult.error.message);

            if (validationResult.error.type === this.Enums.contactFormErrorValidationTypes.redirectUrl) {
                this.toggleAsideMenu(true);
                contactFormModel.contact_form_settings.redirectUrlIsEmpty = true;
                this.resetSideBarStack();
                this.pushItemToSideBarStack(this.getSideBarItemByKey(this.Enums.contactFormSideBarMenuKeys.settings));

            } else {
                contactFormModel.contact_form_settings.redirectUrlIsEmpty = false;
            }

            return false;
        }

        handleToggleActivation(contactFormModel) {
            if (contactFormModel.contact_form_settings.is_active) {
                this._showInactivePopup(contactFormModel);
            } else {
                return this._toggleActivation(contactFormModel)
            }
        }

        _toggleActivation(contactFormModel) {
            contactFormModel.toggleActivation()
            .then((res) => {
                this.AnalyticsService.track(this, 'click: gift card active toggle', {
                    new_value: res.data.contact_form_settings.is_active 
                });
            })
        }

        _showInactivePopup(contactFormModel) {
            return this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.none,
                this.$translate.instant('CONTACT_FORM.ACTIVATION._MESSAGE_', {url: this.getPublicUrl(contactFormModel)}), 
                this.$translate.instant('CONTACT_FORM.ACTIVATION._CONFIRM_'), 
                null, 
                this.$translate.instant('CONTACT_FORM.ACTIVATION._TITLE_')).then(
                function confirmCallback() {
                    return this._toggleActivation(contactFormModel);
                }.bind(this),
                function dismissCallback() {
                    return;
                }.bind(this));
        }
        
        toggleAsideMenu(stateToForce) {
            if (stateToForce) {
                this.appState.isContactFormAsideMenuOpen = stateToForce;
            } else {
                this.appState.isContactFormAsideMenuOpen = !this.appState.isContactFormAsideMenuOpen;
            }
        }

        getPublicUrl(contactFormModel, options = {}) {
            var company = this._getCompany();
            var url  = this.AppConfigService.appConfiguration().url + "widget/" + company.hb_url + "/cf_id/" + contactFormModel._id;

            if (this.AppConfigService.isDevelopment()) {
                url = this.AppConfigService.appConfiguration().url + "assets/cf/wrapper.html?cf_id=" + contactFormModel._id + "&hb_url=" + company.hb_url;
            }

            if (this.shouldShowInstantPayment &&
                contactFormModel.isGiftCard() &&
                company.company_url_prefixes && company.company_url_prefixes.length > 0) {
                var schema = this.AppConfigService.isDevelopment() ? 'http://': 'https://';
                var domain = this.AppConfigService.isDevelopment() ? 'localhost:7080' : company.company_url_domain;
                var company_urls = company.company_url_prefixes;
                var custom_domain = this.CompanyService.getActiveCustomDomain(company.company_custom_domains);

                const path = options.path === 'instant_pay_preview' 
                    ? `/pay/5ec53c55e080ffd4cbfbc105?previewMode=true` // The ID is just a random mongoid-like ID. S3 gives a 404 if it's not a mongoid-like ID
                    : `/giftcard/${contactFormModel._id}`;
                url = schema + (custom_domain ? custom_domain.full_hostname : company_urls[company_urls.length - 1] + '.' + domain) + path;
            }

            return url;
        }

        openAddGiftCardModal() {
            const variation = this._getUser().getExistingAbTestVariation(this.Enums.ABTests.giftCardStartWithTemplate);

            const openVariationModal = (variation) => {
                if (variation === 'cf_template_examples_modal' ) {
                    return this.ModalService.openTemplateExamplesModal('gift_card_examples', {create_new: 'true'})
                } else {
                    return this.ModalService.openAddPromotionModal();
                }
            };

            if (variation) {
                return openVariationModal(variation);
            } else {
                return this.AbTestService.getAbTestVariation(this.Enums.ABTests.giftCardStartWithTemplate).then( variation => {
                    return openVariationModal(variation);
                });
            }
        }

        publish(showToast, showEmbedModal, contactFormModel) {
            let deferred = this.$q.defer();
            let contactFormState = contactFormModel.getContactFormState();

            // Call publish in a timeout so all the requests to
            // update the contact form content will fire before
            // DON'T REMOVE THIS TIMEOUT UNLESS YOU KNOW WHAT YOU ARE DOING
            this.$timeout(() => {
                contactFormModel.publish()
                    .then(() => {
                        this._successfulPublish();
                        if (showToast) {
                        	
                            var icon = 'icon icon-hb-nx-check-mark-circle-16';
                        	var translationKey = contactFormModel.isGiftCard() ?
								'COMPANY_SETTING.WIDGET._CHANGES_PUBLISHED__TOAST_TEXT_GIFT_CARD_' :
								'COMPANY_SETTING.WIDGET._CHANGES_PUBLISHED__TOAST_TEXT_';
                            
                            if (contactFormModel.isGiftCard() && !contactFormModel.contact_form_settings.is_active) {
                                translationKey = 'COMPANY_SETTING.WIDGET._CHANGES_PUBLISHED__TOAST_TEXT_INACTIVE_GIFT_CARD_';
                                icon = 'icon icon-hb-nx-eye-crossed';
                            }

                            this._showToast(translationKey, icon);
                        }
                        if (showEmbedModal) {
                            this.openEmbedCodeModal(contactFormState, contactFormModel);
                        }

                        deferred.resolve();
                    })
                    .catch((e) => {
                        this._failureInPublish();
                        deferred.reject(e);
                    });
            }, 200);

            return deferred.promise;
        }

        publishGiftCard(contactFormModel) {
            let deferred = this.$q.defer();

            // Call publish in a timeout so all the requests to
            // update the contact form content will fire before
            // DON'T REMOVE THIS TIMEOUT UNLESS YOU KNOW WHAT YOU ARE DOING
            this.$timeout(() => {
                contactFormModel.publish()
                    .then(() => {
                        this._successfulGiftCardPublish();
                        this.openGiftCardDirectLinkModal(contactFormModel)

                        deferred.resolve();
                    })
                    .catch((e) => {
                        this._failureInPublish();
                        deferred.reject(e);
                    });
            }, 200);

            return deferred.promise;
        }

        copyContactFormExampleToCompany(exampleKey) {
            return this.TemplatesManager.copyContactFormExampleToCompany(this._getCompany(), exampleKey);
        }

        createDefaultGiftCardForm() {
            return this.TemplatesManager.createGiftForm(this._getCompany());
        }

        _getCompany() {
            return this._getUser().company;
        }

        _getUser() {
            return this.UsersManager.getCurrUser();
        }

        resetContactForm(contactFormModel) {
            return this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info,
                    this.$translate.instant('CONTACT_FORM.VALIDATIONS._UNDO_'), this.$translate.instant('CONTACT_FORM.VALIDATIONS._CONFIRM_'), this.$translate.instant('CONTACT_FORM.VALIDATIONS._CANCEL_')).then(() => {
                return contactFormModel.resetContactFormToLastPublished()
                    .then(() => {
                        this.AnalyticsService.track(this, 'Contact form reset was successful', {
                            companyId: this._getCompany()._id
                        });
                    })
                    .catch(this._failureInReset.bind(this));
            });
        }

        getMigratedContactFormFlags() {
            const onboardingData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.contactFormDynamicOnboarding, {});

            const showTooltipTruthTable = {
                seenDynamicCFIntro: onboardingData.seenDynamicCFIntro,
                fromMigration: onboardingData.fromMigration,
                notSeenPublishModal: !onboardingData.seenPublishModal,
                notDismissedMigratedTooltip : !onboardingData.dismissedMigratedTooltip
            }

            const showRedDotTruthTable = {
                seenDynamicCFIntro: onboardingData.seenDynamicCFIntro,
                fromMigration: onboardingData.fromMigration,
                notSeenPublishModal: !onboardingData.seenPublishModal,
                dismissedMigratedTooltip : onboardingData.dismissedMigratedTooltip
            }

            const showHeaderOnPublishModalTruthTable = {
                seenDynamicCFIntro: onboardingData.seenDynamicCFIntro,
                fromMigration: onboardingData.fromMigration,
                notSeenPublishModal: !onboardingData.seenPublishModal
            }

            return {
                showTooltip: this._.every(showTooltipTruthTable, function(condition) { return condition}),
                showRedDot: this._.every(showRedDotTruthTable, function(condition) { return condition}),
                showHeaderOnPublishModal: this._.every(showHeaderOnPublishModalTruthTable, function(condition) { return condition})
            };
        }

        validateCanAddBlock(contactForm, blockType) {
            if (blockType && blockType.type === 'component' && blockType.subType === this.Enums.suggestedQuestions.project_type) {
                let promise = this._commonValidationChangeOrAddQuestion(contactForm, this.Enums.suggestedQuestions.project_type);
                if (promise) {
                    return promise;
                }
            }
            return Promise.resolve();
        }

        validateCanChangeQuestionType(contactForm, newType, isMain) {
            if (newType.key === this.Enums.suggestedQuestions.full_name || newType.key === this.Enums.suggestedQuestions.email) {
                let questionTypeText = this.$translate.instant(newType.textTranslation);
                let warningText = this.$translate.instant('DYNAMIC_CONTENT.FORM_COMPONENT._CONNECTED_QUESTION_REPLACE_WARNING_', {question_type: questionTypeText});
                return this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.warning, warningText);
            }
            let promise = this._commonValidationChangeOrAddQuestion(contactForm, newType.key);
            if (promise) {
                return promise;
            } else {
                return Promise.resolve();
            }
        }

        _commonValidationChangeOrAddQuestion(contactForm, newQuestionType) {
            if (newQuestionType === this.Enums.suggestedQuestions.project_type && contactForm.contact_form_settings.project_type) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, this.$translate.instant('CONTACT_FORM.ERRORS._PROJECT_TYPE_QUESTION_CONFLICT_'),
                    function () {
                        this.trigger('showContactFormSidebar', this.Enums.contactFormSideBarMenuKeys.settings, 'assignProjectType');
                    }.bind(this), 'FREQUENT_BUTTONS._GOT_IT_');
                return Promise.reject();
            }
            return null;
        }


    };

}());
