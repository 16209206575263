/**
 * Created by udicohen on 18/1/16.
 */
(function () {
    'use strict';

    // @ngInject
    function OnboardingService(Gon, AppStates, $state, $q, $injector, DatadogRUMService,
                               UsersManager, SelfOnboardingManager, CompaniesManager, $timeout, $log,
                               Enums, PubSubService, ModalService, UiPersistenceService, AbTestService, moment, dev_tools, DeviceService, AppConfigService, FeaturesService,
                               InitialAppLoadParamsService, PricingPlansService, HomeService, AnalyticsService, _) {

        this.ONBOARDING_STAGE_ORDER = Gon.onboarding_enums.ONBOARDING_STAGE_ORDER;

        this.$injector = $injector;
        this.UsersManager = UsersManager;
        this.AppStates = AppStates;
        this.AppConfigService = AppConfigService;
        this.appState = AppConfigService.appState();
        this.SelfOnboardingManager = SelfOnboardingManager;
        this.ModalService = ModalService;
        this.FeaturesService = FeaturesService;
        this.InitialAppLoadParamsService = InitialAppLoadParamsService;
        this.UiPersistenceService = UiPersistenceService;
        this.CompaniesManager = CompaniesManager;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.$log = $log;
        this.$state = $state;
        this.moment = moment;
        this.$q = $q;
        this.$timeout = $timeout;
        this.HomeService = HomeService;
        this.AnalyticsService = AnalyticsService;
        this._ =_;
        this.DatadogRUMService = DatadogRUMService;

        this.onboardingAbTestType = null;
        this.onboardingAbTest = null;

        this.ONBOARDING_TYPES = {
            T1: 't1',
            SkipTemplate: 'skip_template',
            LegacyLike: 'legacy_like',
            IIO: 'invoice_intent',
            SIO: 'scheduler_intent',
            CIO: 'contract_intent',
            LIO: 'lead_capture_intent',
            CRMIO: 'crm_intent'
        };

        this.onboardingWasActiveInThisSession = false;
        this.showOnboardingOptionsMenu = null;
        this.DeviceService = DeviceService;
        this.PricingPlansService = PricingPlansService;
        this.onboardingMenuClosedDuringSession = false;
        this.onboardingOverviewPersistencyHash = null;

        this.coverImageUpload = null;

        this.isIntentOnboardingCollapsed = false;

        this.isPhaseOneComplete = false;

        PubSubService.ventMyBitchUp(this);

        this.currUser = this.UsersManager.getCurrUser();

        this.isAttributionLandingPageAgreement =
            this.isAttributionLandingPage('photography-contract-template') ||
            this.isAttributionLandingPage('event-planner-contract-template') ||
            this.isAttributionLandingPage('designer-contract-template');

        this.initOnboardingData();
        this.shouldShowPopovers = true;
        this.isToOpenBeforeOnboardingModal = false;

        //create the persistency regardless if the onboarding was initiated since in atws and pvl we need it beforehand
        this.defaultUiPersistencyForOnBoardingOverview = {
            newLeadClicked: false,
            customizeLinkClicked: false,
            bringInExistingClientClicked: false,
            watchIntroVideoLinkClicked: false,
            brochureVideoClicked: false,
            clientSideClicked: false,
            webinarClicked: false,
            walkthroughClicked: false,
            proposalHelpClicked: false,
            setupAccountClicked: false,
            customizeTemplatesClicked: false,
            bannerComplete: false,
            bannerViewExampleProposal: false, // AB Test
            bannerVisitBrandingPage: false, // AB Test
            bannerCreateExampleProposal: false, // AB Test
            hasSentTrialerOfferAccountSetup: false,
        };

        this.onboardingOverviewPersistencyHash = this.UiPersistenceService.getUiPersistence(
            this.UiPersistenceService.keys.onBoardingOverview,
            this.defaultUiPersistencyForOnBoardingOverview);


        if (this.currUser._id && this.isOnboardingActive()) { //user exists in cache, refresh its current step from the server
            SelfOnboardingManager.getCurrStep(this.currUser);

            this.currUser.once('success', function success() {
                var lastKnownStep = this.currentStep;
                this.initOnboardingData();
                if (lastKnownStep && this.currentStep && lastKnownStep !== this.currentStep) { //for some reason step was updated from the sever
                    this.triggerNextStep(this.currentStep);
                }
            }, this);
        }

        this.refreshOnboardingState = function refreshOnboardingState(){
            this.currUser = this.UsersManager.getCurrUser();
            this.initOnboardingData();
            this.trigger('OnboardingStateRefreshed');
        }.bind(this);

        this.onServiceTemplateCreated = function onServiceTemplateCreated() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.servicesHub});
                this.completeOnboardingStep(step);
            }
        }.bind(this);

        UsersManager.on('loggingIn', this.refreshOnboardingState);

        UsersManager.on('loginLess', this.refreshOnboardingState);

        UsersManager.on('resetPsw', this.refreshOnboardingState);

        UsersManager.on('loggingOut', this.onLogoutCleanup.bind(this));

        CompaniesManager.on(this.Enums.PubSubTriggers.companySubscribed, this.refreshOnboardingState);

        UsersManager.on(this.Enums.PubSubTriggers.extendedTrial, this.refreshOnboardingState);

        InitialAppLoadParamsService.cleanParamsFromView('target');
    }

    Services.OnboardingService = Class({
        constructor: OnboardingService,

        OnboardingSteps: {
            none: 'none',
            initialUserInfo: 'initial_user_info',
            // welcomeMovie: 'welcome_movie',
            createEventTooltip: 'create_event_tooltip',
            createEvent: 'create_event',
            addUser: 'add_user',
            createFile: 'create_file',
            // weWillCallYou: 'we_will_call_you',
            paymentMethod: 'payment_method',
            sendFile: 'send_file',
            completed: 'completed',
            introPanels: 'intro_panels'
        },

        initOnboardingData: function initOnboardingData() {
            if (this.currUser.isVendor() && this.currUser.onboarding_container) {
                this.currentStep = this.currUser.onboarding_container.onboarding_step;
                this.onboardingEntryPoint = this.currUser.onboarding_container.onboarding_entry_point;
                this.workspaceFileId = this.currUser.onboarding_container.workspace_file_id;
                this.workspaceId = this.currUser.onboarding_container.workspace_id;
                this.eventId = this.currUser.onboarding_container.event_id;
                if (this.currUser.onboarding_container.onboarding_active) {
                    this.onboardingWasActiveInThisSession = true;
                }
                this.SelfOnboardingManager.getOnboardingSequence(this.currUser)
                    .then(function success(resp) {
                        this.onboardingSequence = resp.data;
                    }.bind(this));
            }

            this.onboardingOverviewPersistencyHash = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.onBoardingOverview,
                this.defaultUiPersistencyForOnBoardingOverview);
        },

        getOnboardingType: function getOnboardingType() {
            if (!this.onboardingAbTestType) {
                var IntentService = this.$injector.get('IntentService');

                if (IntentService.shouldGetInvoiceIntentOnboarding()) {
                    this.onboardingAbTestType = this.ONBOARDING_TYPES.IIO;
                } else if (IntentService.shouldGetSchedulerIntentOnboarding()) {
                    this.onboardingAbTestType = this.ONBOARDING_TYPES.SIO;
                } else if (IntentService.isContractIntentAndIntentTestGroup()) {
                    this.onboardingAbTestType = this.ONBOARDING_TYPES.CIO;
                } else if (IntentService.shouldGetLeadCaptureIntentOnboarding()) {
                    this.onboardingAbTestType = this.ONBOARDING_TYPES.LIO;
                } else if (IntentService.shouldGetCrmIntentOnboarding()) {
                    this.onboardingAbTestType = this.ONBOARDING_TYPES.CRMIO;
                } else {
                    var intentInfraTestVariation = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.intentInfra);
                    var t1ActivationABCVariant = this.getT1ActivationABCVariant();
                    if (t1ActivationABCVariant === 'control' || !t1ActivationABCVariant || intentInfraTestVariation === 'holdout') {
                        this.onboardingAbTestType = this.ONBOARDING_TYPES.T1;
                    } else if (t1ActivationABCVariant === 'legacy_like') {
                        this.onboardingAbTestType =  this.ONBOARDING_TYPES.LegacyLike;
                    } else if (t1ActivationABCVariant === 'skip_template') {
                        this.onboardingAbTestType =  this.ONBOARDING_TYPES.SkipTemplate;
                    } else {
                        this.DatadogRUMService.addError(new Error('Failed to detect onboarding type'), {
                            user_id: this.currUser._id,
                            intent_onboarding: 1.0
                        });
                    }
                }
            }

            return this.onboardingAbTestType;
        },

        getOnboardingAbTest: function getOnboardingAbTest() {
            if (!this.onboardingAbTest) {
                var intentInfraTestVariation = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.intentInfra);
                var invoiceIntentVariation = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.invoiceIntentServicesOnboarding);
                var schedulerIntentVariation = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.schedulerIntentOnboarding);
                var contractIntentVariationV2 = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.contractIntentOnboardingV2);

                if (intentInfraTestVariation === 'holdout'){
                    this.onboardingAbTest = {name: this.Enums.ABTests.intentInfra, variant: 'holdout'};
                } else if (invoiceIntentVariation){
                    this.onboardingAbTest = {name: this.Enums.ABTests.invoiceIntentServicesOnboarding, variant: invoiceIntentVariation};
                } else if (schedulerIntentVariation){
                    this.onboardingAbTest = {name: this.Enums.ABTests.schedulerIntentOnboarding, variant: schedulerIntentVariation};
                } else if (contractIntentVariationV2){
                    this.onboardingAbTest = {name: this.Enums.ABTests.contractIntentOnboardingV2, variant: contractIntentVariationV2};
                }
            }
            return this.onboardingAbTest;
        },

        setOnboardingPhaseOneComplete: function setOnboardingPhaseOneComplete(isPhaseOneComplete) {
            this.isPhaseOneComplete = isPhaseOneComplete;
        },

        getT1ActivationABCVariant: function getT1ActivationABCVariant() {
            var abTestVariant = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.t1ActivationAbc);
            if (abTestVariant) {
                return abTestVariant;
            }

            var IntentService = this.$injector.get('IntentService');

            if (IntentService.shouldGetLegacyLikeOnboarding()) {
                return 'legacy_like';
            }
            
            return 'control';
        },

        getPhaseOneComplete: function getPhaseOneComplete() {
            return this.isPhaseOneComplete;
        },

        onLogoutCleanup: function onLogoutCleanup() {
            this.currUser = null;
            this.onboardingWasActiveInThisSession = false;
            this.onboardingOverviewPersistencyHash = null;
            this.abTestPromise = null;
            this.currentStep = null;
            this.onboardingSequence = null;
        },

        setIsIntentOnboardingCollapsed: function setIsIntentOnboardingCollapsed(isCollapsed) {
            this.isIntentOnboardingCollapsed = isCollapsed;
        },

        abortOnboarding: function abortOnboarding(reason){
            this.UsersManager.abortOnboarding(reason);
        },

        isOnboardingActive: function isOnboardingActive() {
            return this.currUser != null &&
                this.currUser.onboarding_container != null &&
                this.currUser.onboarding_container.onboarding_active;
        },

        isOnboardingActiveInSession: function isOnboardingActiveInSession() {
            return this.isOnboardingActive() || this.onboardingWasActiveInThisSession;
        },

        setOnboardingWorkspaceFile: function setOnboardingWorkspaceFile(fileId) {
            if (this.isOnboardingActive()) {
                this.workspaceFileId = fileId;
            }
        },

        setOnboardingWorkspace: function setOnboardingWorkspace(workspaceId) {
            if (this.isOnboardingActive()) {
                this.workspaceId = workspaceId;
            }
        },

        setOnboardingEvent: function setOnboardingEvent(eventId) {
            if (this.isOnboardingActive()) {
                this.eventId = eventId;
            }
        },

        setStepComplete: function setStepComplete(completedStep, skipped) {
            var self = this;

            if (!this.isOnboardingActive()) {
                return;
            }

            var additionalData = {};
            additionalData.workspace_file_id = this.workspaceFileId;
            additionalData.workspace_id = this.workspaceId;
            additionalData.event_id = this.eventId;
            if (skipped === true) {
                additionalData.skipped = true;
            }

            this.SelfOnboardingManager.setStepComplete(this.currUser, completedStep, additionalData).then(
                function stepCompletedSuccess(resp) {
                    self.triggerStepCompleted(completedStep);
                    self.currentStep = self.currUser.onboarding_container.onboarding_step;
                    self.triggerNextStep(self.currentStep);
                }
            );
        },

        createTemplateFromFile: function createTemplateFromFile(file) {
            if (this.isOnboardingActive()) {

                var dateFormatted = this.moment().format('MMM DD, YYYY');

                // Save the agreement as template for proposal
                if (file.isProposal()) {
                    var agreementTitle = 'Agreement, ' + dateFormatted;
                    this.CompaniesManager.saveAsAgreementTemplate(this.currUser.company, file, agreementTitle);
                }

                // Save the packages
                file.vendor_proposal.vendor_packages.forEach(function (vendorPackage) {
                    var packageTitle = vendorPackage.title + ", " + dateFormatted;
                    this.CompaniesManager.saveAsPackageTemplate(this.currUser.company, file, vendorPackage._id, packageTitle);
                }.bind(this));
            }
        },

        // Like triggerNextStep, execpt runs on the step that just completed
        triggerStepCompleted: function triggerStepCompleted(curStep) {
            switch (curStep) {
                case this.OnboardingSteps.createEvent:
                    this.shouldShowOnboardingOptionsMenu().then(function (shouldShow) {
                        if (shouldShow) {
                            this.markOnboardingOverviewTasksComplete('newLeadClicked');
                        }
                    }.bind(this));
                    break;
            }
        },

        // Only when onboarding step is completed and we need to go to the next step (not core module routing)
        triggerNextStep: function triggerNextStep(nextStep) {

            var triggerNewStage = true;
            switch (nextStep) {
                case this.OnboardingSteps.initialUserInfo:
                    break;
                case this.OnboardingSteps.paymentMethod:
                    // this is not used anymore
                    break;
                case this.OnboardingSteps.createEventTooltip:
                    break;
                case this.OnboardingSteps.createEvent:
                    break;
                case this.OnboardingSteps.addUser:
                    this.shouldShowOnboardingOptionsMenu().then(function (shouldShow) {
                        var leftPanelPersistence;
                        if (shouldShow) {
                            if (!this.onboardingOverviewPersistencyHash.newLeadClicked) {
                                this.markOnboardingOverviewTasksComplete('newLeadClicked');
                                this.trigger('onboarderCreatedEvent');
                            }
                        }
                    }.bind(this));
                    break;
                case this.OnboardingSteps.createFile:
                    break;
                case this.OnboardingSteps.sendFile:
                    break;
                case this.OnboardingSteps.completed:
                    break;
                case this.OnboardingSteps.introPanels:
                    break;
                default:
                // do nothing...
            }

            if (triggerNewStage === true) {
                this.trigger(nextStep);
            }
        },

        getCurrentStepRoute: function getCurrentStepRoute() {
            return this.getOnboardingHomeState();
        },

        getOnboardingEntryPointState: function getOnboardingEntryPointState() {
            const isJBSource = this.currUser &&
                this.currUser.attribution &&
                this.currUser.attribution.user_utm_params &&
                this.currUser.attribution.user_utm_params.utm_source === "johnbranch";
            if (isJBSource) {
                return this.AppStates.root_core_navigation_my_templates;    
            }
            
            if(this.onboardingEntryPoint) {
                switch (this.onboardingEntryPoint) {
                    case 'contract':
                        return this.AppStates.root_core_navigation_templates;
                    default:
                        return this.getOnboardingHomeState();
                }
            } else {
                return this.getOnboardingHomeState();
            }
        },

        getOnboardingQuizState: function getOnboardingQuizState() {
            // We used to have multiple paths for different quiz designs.
            // During pureRomeyQuizCriticalQuestions test, we want the designs
            // to be the same in order to better understand the 2-vs-5 Q effect
            return this.AppStates.root_core_onboardingMobileQuiz;
        },

        isContractsEntryPoint: function isContractsEntryPoint() {
            return (this.currentStep === this.OnboardingSteps.introPanels && this.onboardingEntryPoint === 'contract');
        },

        getOnboardingHomeState: function getOnboardingHomeState() {
            var isMobile = this.DeviceService.checkIfMobileSize();
            var isTrialer = this.isInTrial() || this.isTrialExpired();
            var isSubscribed = this.currUser.companyHasSubscription();

            if (isSubscribed) {
                if (isMobile) {
                    return this.AppStates.root_core_navigation_pipeline;
                } else {
                    return this.AppStates.root_core_navigation_home;
                }
            }

            if (isTrialer) {
                return this.getSetupGuideState();
            }

            // Just in case nothing matches
            return this.AppStates.root_core_navigation_pipeline;
        },

        getSetupGuideState: function getSetupGuideState() {

            return this.AppStates.root_core_navigation_home;
        },

        getCurrentStep: function getCurrentStep() {
            return this.currentStep;
        },

        isStepComplete: function isStepComplete(step) {
            return this.ONBOARDING_STAGE_ORDER[this.currentStep] > this.ONBOARDING_STAGE_ORDER[step];
        },

        shouldShowAddPackagePin: function shouldShowAddPackagePopover() {
            //If the vendor does not add anyone to the workspace, we want to show the pins on the proposal.
            return this.isOnboardingActive() &&
                (!this.isStepComplete(this.OnboardingSteps.sendFile)) &&
                (!this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.hideOnboardingAddPackage, {dontShow: false}).dontShow);
        },

        shouldShowAgreementPin: function shouldShowAgreementPin() {
            //If the vendor does not add anyone to the workspace, we want to show the pins on the proposal.
            return this.isOnboardingActive() &&
                (this.isStep(this.OnboardingSteps.addUser) || this.isStep(this.OnboardingSteps.sendFile)) &&
                (!this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.hideOnboardingAgreement, {dontShow: false}).dontShow);
        },

        hasNotYetSentFirstFile: function hasNotYetSentFirstFile() {
            // If the vendor has not sent a file
            return this.isOnboardingActive() && !this.isStepComplete(this.OnboardingSteps.sendFile);
        },

        shouldShowCreateProposal: function shouldShowCreateProposal() {
            return this.isOnboardingActive() && (this.isStep(this.OnboardingSteps.addUser) || this.isStep(this.OnboardingSteps.createEvent));
        },

        isStep: function isStep(potentialStep) {
            return this.currentStep === potentialStep;
        },

        hidePopovers: function hidePopovers() {
            this.shouldShowPopovers = false;
        },

        showPopovers: function showPopovers() {
            this.shouldShowPopovers = true;
        },

        //Popovers and other no-step-order functions
        setAddPackagePopoverPersistence: function setAddPackagePopoverPersistence() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.hideOnboardingAddPackage, {'dontShow': true});
        },

        setAgreementPopoverPersistence: function setAgreementPopoverPersistence() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.hideOnboardingAgreement, {'dontShow': true});
        },

        isInTrial: function isInTrial() {
            return this.currUser.isInTrial();
        },

        isUserSubscribed: function isUserSubscribed(){
            return this.currUser.companyHasSubscription();
        },

        isTrialExpired: function isTrialExpired() {
          return this.currUser && this.currUser.hasTrialExpired();
        },

        setSendFileComplete: function setSendFileComplete(workspaceFile) {

            if (this.isOnboardingActive() && !this.isStepComplete(this.OnboardingSteps.sendFile)) {
                this.setOnboardingWorkspaceFile(workspaceFile._id);
                this.setStepComplete(this.OnboardingSteps.sendFile);
                this.createTemplateFromFile(workspaceFile);
            }
        },

        shouldShowOnboardingOptionsMenu: function shouldShowOnboardingOptionsMenu() {
            var deferred = this.$q.defer();

            if (this.currUser && this.currUser.onboarding_container && !this.DeviceService.checkIfMobileSize()) {
                // we only want to run if user is not on mobile device, so using thi <640px heuristic
                // we dont check if mobile at the beginning of this function to account for weird edge case where user shrinks window
                // after initing this ab test. (in which we want to return the defined this.showOnboardingOptionsMenu)
                deferred.resolve(true);
            } else {
                deferred.resolve(false);
            }

            return deferred.promise;
        },

        getOnboardingMenuClosed: function getOnboardingMenuClosed() {
            return this.onboardingMenuClosedDuringSession;
        },

        showOnboardingBar: function showOnboardingBar() {
            var self = this;

            return (!self.appState.isFileRouteAndClientMode &&
                (self.currUser && self.currUser.shouldSeeTrialWarnings()));
        },

        remindUserToSetAccount: function remindUserToSetAccount(reminders) {
            return this.UsersManager.remindUserToSetAccount(this.currUser, reminders);
        },

        updateAtwsStage: function checkAtwsStage() {
            var currUser = this.UsersManager.getCurrUser();
            if (currUser.getAtwsStage() === this.Enums.AtwsStage.beforeCreatingFirstEvent) {
                //give a little head start to prepare for animations
                this.trigger('onboardingBeingAdded');

                //postpone the getting of atws data so that the animation classes will be added beforehand
                this.$timeout(function () {

                    //get the user model's onboarding data by getting the current onboarding step
                    this.SelfOnboardingManager.getAtwsData(this.currUser).then(function success(response) {
                        if (response.data.onboarding_container) {
                            this.initOnboardingData();
                            this.trigger('onboardingAdded');
                        }
                    }.bind(this));

                }.bind(this), 100);
            } else if (currUser.getAtwsStage() === this.Enums.AtwsStage.createdFirstEvent) {
                this.trigger('trialBeingAdded');

                this.$timeout(function () {
                    this.CompaniesManager.getCurrCompany(true);
                }.bind(this), 100);
            }
        },

        userStartTrial: function userStartTrial() {
            this.trigger('onboardingBeingAdded');
            this.$timeout(function () {
                this.trigger('onboardingAdded');
                this.$state.go(this.getCurrentStepRoute());
            }.bind(this), 100);
        },

        markOnboardingOverviewTasksComplete: function persistOnBoardingOverviewTasks(taskAccomplished, publishTriggerEvent) {
            if (publishTriggerEvent === undefined) {
                //default
                publishTriggerEvent = true;
            }
            this.onboardingOverviewPersistencyHash[taskAccomplished] = true;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.onBoardingOverview, this.onboardingOverviewPersistencyHash);

            if (publishTriggerEvent) {
                this.trigger('onboardingOverviewTaskAccomplished', this.onboardingOverviewPersistencyHash);
            }
        },

        getOnboardingOverviewTaskAccomplished: function getOnboardingOverviewTaskAccomplished() {
            return this.onboardingOverviewPersistencyHash;
        },

        openInProductIntakeForm: function openInProductIntakeForm(skipWarning) {
            var self = this;
            this.getIntakeFormVersion().then(function gotIntakeFormVersion(intakeFormVersion) {
                self.currUser.isLocatedInUSOrCanada().then(function(isLocationInUsOrCanada) {

                    if (isLocationInUsOrCanada || skipWarning) {
                        self.ModalService.openIntakeModal(intakeFormVersion);
                    }
                    else {
                        self.ModalService.openNonUsaWarningModal(intakeFormVersion);
                    }
                }.bind(this));
            });
        },

        triggerTrialIfNeeded: function triggerTrialIfNeeded(shouldStartTrial) {
            if ((this.currUser.shouldStartTrial() || this.currUser.isClient()) && (this.InitialAppLoadParamsService.getParam('target') === 'trial' || shouldStartTrial)) {
                this.$timeout(function() {
                    this.openInProductIntakeForm();
                    this.InitialAppLoadParamsService.removeParam('target');
                }.bind(this), 1200); // delay
            }
        },

        getIntakeFormVersion: function getIntakeFormVersion() {
            var self = this;
            var deferred = self.$q.defer();

            var intakeVersion;

            //leaving defer code, in case we do more abtests here.
            if (this.currUser.isNetworkPretrialer()) {
                intakeVersion = this.Enums.IntakeFormVersion.communityTrial;
                deferred.resolve(intakeVersion);
            } else {
                intakeVersion = this.Enums.IntakeFormVersion.workflowHome;
                deferred.resolve(intakeVersion);
            }

            return deferred.promise;
        },

        _onIntakeModalSubmission: function _onIntakeModalSubmission() {
            this.initOnboardingData();
            this.userStartTrial();
            this.trigger('intakeModalSubmitted');
        },

        onIntakeModalSubmission: function onIntakeModalSubmission() {
            var userRequest = this.UsersManager.forceFetchCurrUser();

            if (userRequest === undefined) { // handle this in times that the curr user is during fetch already
                this._onIntakeModalSubmission();
            }else {
                userRequest.then(this._onIntakeModalSubmission.bind(this));
            }
        },

        skipPreAppOnboarding: function skipPreAppOnboarding(){
            this.setStepComplete(this.OnboardingSteps.introPanels, true);
        },

        isAttributionLandingPage: function isAttributionLandingPage(page) {
            return this.currUser && this.currUser.attribution && (this.currUser.attribution.signup_landing_page === page);
        },

        shouldShowPipelineOnboarding: function shouldShowPipelineOnboarding() {
            var startedOnboardingAfterFeature = this.FeaturesService.startedOnboardingAfterFeature(this.currUser, this.FeaturesService.features.pipelineOnboarding);
            var seenPipelineOnboarding = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.pipelineOnboarding, {seen: false}).seen;
            return !seenPipelineOnboarding && (startedOnboardingAfterFeature || !this.AppConfigService.isProduction());
        },

        completeOnboardingStep: function completeOnboardingStep(step, skipUpdate) {
            if (step && step.status !== 'complete') {
                this.SelfOnboardingManager.completeOnboardingStep(this.currUser, step)
                    .then(function success(resp) {
                        if (!skipUpdate) this.onboardingSequence = resp.data;
                    }.bind(this));
            }
        },

        onTemplateLoaded: function onTemplateLoaded(type) {
            if(this.onboardingSequence && this._.includes(['invoice', 'agreement', 'proposal', 'questionnaire', 'brochure', 'package', 'email'], type)) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.templates});
                this.completeOnboardingStep(step);
            }
        },

        onProjectCreated: function onProjectCreated() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.createProject});
                this.completeOnboardingStep(step);
            }
        },

        onCompanySubscribed: function onCompanySubscribed() {
            this.$log.debug('[tracking: onboardingService] onCompanySubscribed');
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.subscribe});
                this.completeOnboardingStep(step);
            }
        },

        onContactFormPublished: function onContactFormPublished() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.contactForm});
                this.completeOnboardingStep(step);
            }
        },

        onBankAccountAdded: function onBankAccountAdded() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.bankInfo});
                this.completeOnboardingStep(step);
            }
        },

        onFlowsTemplateGalleryVisited: function onFlowsTemplateGalleryVisited() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.templateGallery});
                this.completeOnboardingStep(step);
            }
        },

        onAccountImageUploaded: function onAccountImageUploaded() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.account});
                this.completeOnboardingStep(step, true);
            }
        },

        onWorkspaceFileViewed: function onWorkspaceFileViewed(workspaceFile) {
            if(this.onboardingSequence && workspaceFile._id === this.currUser.onboarding_container.workspace_file_id) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.clientSide});
                this.completeOnboardingStep(step);
            }
        },

        onAccountSetupFilesUploaded: function onAccountSetupFilesUploaded() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.accountSetup});
                this.completeOnboardingStep(step);
            }
        },

        onTestFlowViewed: function onAccountSetupFilesUploaded() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.sendFlowTest});
                this.completeOnboardingStep(step);
            }
        },

        onAddClientDone: function onAddClientDone() {
            if(this.onboardingSequence) {
                var step = this._.findWhere(this.onboardingSequence.steps, {key: this.Enums.onboardingSteps.clients});
                this.completeOnboardingStep(step);
            }
        },

        allOnboardingStepsAreComplete: function allOnboardingStepsAreComplete() {
            return this.onboardingSequence && this.onboardingSequence.is_sequence_complete;
        },

        setShouldShowBeforeOnboardingModal: function setToOpenBeforeOnboardingModal(value) {
            this.isToOpenBeforeOnboardingModal = value;
        },
        getShouldShowBeforeOnboardingModal: function getToOpenBeforeOnboardingModal() {
            return this.isToOpenBeforeOnboardingModal;
        }

    });
}());
