(function () {
    'use strict';

    // @ngInject
    function SecondLevelNavigationBookkeepingControllerCtor($scope, $injector, $stateParams, $state, AnalyticsService, AppStates, UsersManager, moment, FeaturesService, DeviceService, FinanceAppService,
                                                            StatsigService, Enums, $q, InvoiceHubService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SecondLevelNavigationBookkeepingController';
        
        this.menuItemsViewModel = [];
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.moment = moment;

        this.user = UsersManager.getCurrUser();
        this.Enums = Enums;
        this.StatsigService = StatsigService;
        this.$q = $q;
        this.InvoiceHubService = InvoiceHubService;

        //prepare the view model
        this.title = 'Finance';

        // Default navigation menu items, starting with Payments
        this.navigationMenuItems = [{
            linkTitleKey: 'Payments',
            stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_reports_payments : AppStates.root_core_navigation_reports_payments
        }];

        var showInvoiceHubPromise = this.InvoiceHubService.isInvoiceHubVisible().then(function (showInvoiceHub) {
                if(showInvoiceHub){
                    var paymentMenuItemIndex = this.navigationMenuItems.findIndex(function (menuItem) {
                        return menuItem.linkTitleKey === 'Payments';
                    }.bind(this));

                // Make sure before payments tab
                this.navigationMenuItems.splice(paymentMenuItemIndex, 0, {
                    linkTitleKey: 'Invoices',
                    stateToGoto: AppStates.root_core_navigation_invoices
                });
            }
        }.bind(this));

        var financePromises = Promise.all([
            FinanceAppService.isFinanceAppEnabled(this.user),
            this.StatsigService.isGateEnabled('finance_app_overview'),
            this.StatsigService.isGateEnabled('finance_legacy_expenses')
        ]).then(function(results) {
            var isEligible = results[0];
            var isOverviewEnabled = results[1];
            var isExpensesEnabled = results[2];

            if (isEligible) {
                this.navigationMenuItems.unshift({
                    linkTitleKey: 'HoneyBook funds',
                    stateToGoto: AppStates.root_core_navigation_finance
                });
            }

            if (isOverviewEnabled) {
                this.navigationMenuItems.unshift({
                    linkTitleKey: 'Overview',
                    stateToGoto: AppStates.root_core_navigation_finance_overview
                });
            }

            if (this.user.hasCompanySuperAdminPermissions() && isExpensesEnabled) {
                var paymentMenuItemIndex = this.navigationMenuItems.findIndex(function (menuItem) {
                    return menuItem.linkTitleKey === 'Payments';
                });

                this.navigationMenuItems.splice(paymentMenuItemIndex + 1, 0, {
                    linkTitleKey: 'Expenses',
                    stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_expenses : AppStates.root_core_navigation_expenses
                });

                this.navigationMenuItems.splice(paymentMenuItemIndex + 2, 0, {
                    linkTitleKey: 'Profit & Loss',
                    stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_reports_profit_loss : AppStates.root_core_navigation_profit_loss
                });
            }

            if (this.user.hasCompanySuperAdminPermissions()) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Quickbooks',
                    stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_settings_company_quickbooks : AppStates.root_core_navigation_settings_companySettings_quickbooks
                });
            }
        }.bind(this));

    }

    Controllers.SecondLevelNavigationBookkeepingController = Class(Controllers.BaseController, {
        constructor: SecondLevelNavigationBookkeepingControllerCtor,

        onMenuItemClicked: function onMenuItemClicked(menuItem) {
            this.goToState(menuItem.stateToGoto);
        }
    });
}());
