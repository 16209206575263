core_module = angular.module('honeybook.core', [
    'ui.router',
    'honeybook.common',
    'ngAnimate',
]);

// this code will write to console whenever digest cycle is called
//.run(function($rootScope) {
//    $rootScope.$watch(function(){console.log("*****DIGEST")})
//});

core_module.component('hbReact', Components.ReactComponent);
core_module.controller('CoreController', Controllers.CoreController);
core_module.controller('LoginController', Controllers.LoginController);
core_module.controller('CommunityRedirectController', Controllers.CommunityRedirectController);
core_module.controller('LoginlessController', Controllers.LoginlessController);
core_module.controller('LinkResolverController', Controllers.LinkResolverController);
core_module.controller('OneFlowController', Controllers.OneFlowController);
core_module.controller('ContactViewController', Controllers.ContactViewController);
core_module.controller('OrganizationViewController', Controllers.OrganizationViewController);
core_module.controller('AutomationsController', Controllers.AutomationsController);
core_module.controller('AccountController', Controllers.AccountController);
core_module.controller('PipelineController', Controllers.PipelineController);
core_module.controller('HomeController', Controllers.HomeController);
core_module.controller('NativeController', Controllers.NativeController);
core_module.controller('ActivityNotificationsController', Controllers.ActivityNotificationsController);
core_module.controller('PipelineSettingsController', Controllers.PipelineSettingsController);
core_module.controller('ReportsController', Controllers.ReportsController);
core_module.controller('ReportsAnalyticsController', Controllers.ReportsAnalyticsController);
core_module.controller('ProfitLossController', Controllers.ProfitLossController);
core_module.controller('CalendarController', Controllers.CalendarController);
core_module.controller('CalendarAbstractController', Controllers.CalendarAbstractController);
core_module.controller('CalendarNewAbstractController', Controllers.CalendarNewAbstractController);
core_module.controller('ReportsPaymentsController', Controllers.ReportsPaymentsController);
core_module.controller('ExpensesController', Controllers.ExpensesController);
core_module.controller('LoansController', Controllers.LoansController);
core_module.controller('FinanceController', Controllers.FinanceController);
core_module.controller('InvoiceController', Controllers.InvoiceController);
core_module.controller('FilesHubController', Controllers.FilesHubController);
core_module.controller('CapitalController', Controllers.CapitalController);
core_module.controller('TaxInfoController', Controllers.TaxInfoController);
core_module.controller('SettingsController', Controllers.SettingsController);
core_module.controller('CompanySettingsPricingController', Controllers.CompanySettingsPricingController);
core_module.controller('CompanySettingsBankController', Controllers.CompanySettingsBankController);
// core_module.controller('CompanySettingsPaymentMethodsController', Controllers.CompanySettingsPaymentMethodsController);
core_module.controller('EmailSignaturesTemplateEmptyStateController', Controllers.EmailSignaturesTemplateEmptyStateController);
core_module.controller('GoogleContactsModalController', Controllers.GoogleContactsModalController);
core_module.controller('CompanySettingsGalleryController', Controllers.CompanySettingsGalleryController);
core_module.controller('BrochureTemplateEmptyStateController', Controllers.BrochureTemplateEmptyStateController);
core_module.controller('PackageTemplateEmptyStateController', Controllers.PackageTemplateEmptyStateController);
core_module.controller('QuestionnaireTemplateEmptyStateController', Controllers.QuestionnaireTemplateEmptyStateController);
core_module.controller('ProposalTemplateEmptyStateController', Controllers.ProposalTemplateEmptyStateController);
core_module.controller('GiftCardTemplateEmptyStateController', Controllers.GiftCardTemplateEmptyStateController);
core_module.controller('CompanySettingsPreferencesController', Controllers.CompanySettingsPreferencesController);
core_module.controller('CompanyAIPreferencesController', Controllers.CompanyAIPreferencesController);
core_module.controller('CompanySettingsQuickbooksController', Controllers.CompanySettingsQuickbooksController);
core_module.controller('CompanySettingsTeamController', Controllers.CompanySettingsTeamController);
core_module.controller('CompanySettingsAccountAccessController', Controllers.CompanySettingsAccountAccessController);
core_module.controller('UploadPhotoController', Controllers.UploadPhotoController);
core_module.controller('UploadFilesController', Controllers.UploadFilesController);
core_module.controller('WorkspaceFileController', Controllers.WorkspaceFileController);
core_module.controller('WorkspaceFileRedirectionController', Controllers.WorkspaceFileRedirectionController);
core_module.controller('WorkspaceFileStepAgreementController', Controllers.WorkspaceFileStepAgreementController);
core_module.controller('WorkspaceFileStepPaymentController', Controllers.WorkspaceFileStepPaymentController);
core_module.controller('WorkspaceFileStepProposalController', Controllers.WorkspaceFileStepProposalController);
core_module.controller('WorkspaceFileStepQuestionnaireController', Controllers.WorkspaceFileStepQuestionnaireController);
core_module.controller('WorkspaceFileBrochureController', Controllers.WorkspaceFileBrochureController);
core_module.controller('WorkspaceFileTimelineController', Controllers.WorkspaceFileTimelineController);
core_module.controller('AccountSetupController', Controllers.AccountSetupController);
core_module.controller('ToolsMobileController', Controllers.ToolsMobileController);
core_module.controller('BusinessToolsController', Controllers.BusinessToolsController);
core_module.controller('ClientFeedbackDashboardController', Controllers.ClientFeedbackDashboardController);
core_module.controller('AccountSetupStatusController', Controllers.AccountSetupStatusController);
core_module.directive('fileSettings', Directives.FileSettingsDirective);
core_module.controller('PlaidOAuthController', Controllers.PlaidOAuthController);

core_module.controller('WorkspaceFilesController', Controllers.WorkspaceFilesController);
core_module.controller('WorkspaceFeedSwitchController', Controllers.WorkspaceFeedSwitchController);
core_module.directive('hbWorkspaceFeed', Directives.WorkspaceFeedDirective);
core_module.controller('WorkspacePaymentsController', Controllers.WorkspacePaymentsController);
core_module.controller('WorkspaceProjectDetailsController', Controllers.WorkspaceProjectDetailsController);
core_module.controller('WorkspaceController', Controllers.WorkspaceController);
core_module.controller('BatchEmailsController', Controllers.BatchEmailsController);
core_module.controller('BulkWorkspaceEmailsController', Controllers.BulkWorkspaceEmailsController);

core_module.controller('EventController', Controllers.EventController);
core_module.controller('EmptyEventController', Controllers.EmptyEventController);
core_module.controller('EventWrapperController', Controllers.EventWrapperController);

core_module.controller('DashboardWorkspaceFilesController', Controllers.DashboardWorkspaceFilesController);
core_module.controller('ContactsController', Controllers.ContactsController);
core_module.controller('ReceiptController', Controllers.ReceiptController);
core_module.controller('SubmittedController', Controllers.SubmittedController);
core_module.controller('FileTitleController', Controllers.FileTitleController);
core_module.controller('ChooseTitleController', Controllers.ChooseTitleController);
core_module.controller('TemplateSaveAsController', Controllers.TemplateSaveAsController);
core_module.controller('ResetPasswordController', Controllers.ResetPasswordController);
core_module.controller('GoogleRedirectController', Controllers.GoogleRedirectController);
core_module.controller('ZoomRedirectController', Controllers.ZoomRedirectController);
core_module.controller('CalendlyRedirectController', Controllers.CalendlyRedirectController);
core_module.controller('IntellumSSORedirectController', Controllers.IntellumSSORedirectController);
core_module.controller('NylasRedirectController', Controllers.NylasRedirectController);
core_module.controller('QuickbooksRedirectController', Controllers.QuickbooksRedirectController);
core_module.controller('PictimeRedirectController', Controllers.PictimeRedirectController);
core_module.controller('NewWorkspaceNotesController', Controllers.NewWorkspaceNotesController);

//MODALS
core_module.controller('MalkutBankAccountInfoController', Controllers.MalkutBankAccountInfoController);
core_module.controller('MalkutTransferFundsModalController', Controllers.MalkutTransferFundsModalController);
core_module.controller('MalkutPullFundsModalController', Controllers.MalkutPullFundsModalController);
core_module.controller('MalkutDisputedPaymentController', Controllers.MalkutDisputedPaymentController);
core_module.controller('MalkutSelectTemplatesController', Controllers.MalkutSelectTemplatesController);
core_module.controller('AddWorkspaceParticipantController', Controllers.AddWorkspaceParticipantController);
core_module.controller('NewVendorReferralController', Controllers.NewVendorReferralController);
core_module.controller('NewWorkspaceAdminMoveController', Controllers.NewWorkspaceAdminMoveController);
core_module.controller('AssignWorkspaceToTeamMemberController', Controllers.AssignWorkspaceToTeamMemberController);
core_module.controller('AddTeamMemberToWorkspaceController', Controllers.AddTeamMemberToWorkspaceController);
core_module.controller('NewAddContactController', Controllers.NewAddContactController);
core_module.controller('EditContactController', Controllers.EditContactController);
core_module.controller('NewAddPreferredContactController', Controllers.NewAddPreferredContactController);
core_module.controller('NewAddCompanyTeamMemberController', Controllers.NewAddCompanyTeamMemberController);
core_module.controller('CreateProjectController', Controllers.CreateProjectController);
core_module.controller('AttachAssetModalController', Controllers.AttachAssetModalController);
core_module.controller('BlockedVendorModalController', Controllers.BlockedVendorModalController);
core_module.controller('UnsentFileController', Controllers.UnsentFileController);
core_module.controller('UploadingFilesController', Controllers.UploadingFilesController);
core_module.controller('SignatureModalController', Controllers.SignatureModalController);
core_module.controller('ConnectCalendarController', Controllers.ConnectCalendarController);
core_module.controller('ConnectNylasCalendarController', Controllers.ConnectNylasCalendarController);
core_module.controller('WorkspaceTypeIntroController', Controllers.WorkspaceTypeIntroController);
core_module.controller('GenericVideoController', Controllers.GenericVideoController);
core_module.controller('TOSChangedController', Controllers.TOSChangedController);
core_module.controller('DisableAutoPayController', Controllers.DisableAutoPaymentController);
core_module.controller('ConfirmAutoPayController', Controllers.ConfirmAutoPayController);
core_module.controller('ContactFormExamplesController', Controllers.ContactFormExamplesController);
core_module.controller('EmbededCodeContactFormController', Controllers.EmbededCodeContactFormController);
core_module.controller('GiftCardPublishModalController', Controllers.GiftCardPublishModalController);
core_module.controller('PreviewContactFormController', Controllers.PreviewContactFormController);
core_module.controller('RateBookingController', Controllers.RateBookingController);
core_module.controller('ChangeExpiredPasswordModalController', Controllers.ChangeExpiredPasswordModalController);

core_module.controller('RescheduleProjectContainerController', Controllers.RescheduleProjectContainerController);
core_module.controller('PauseProjectController', Controllers.PauseProjectController);
core_module.controller('ArchiveProjectController', Controllers.ArchiveProjectController);


core_module.controller('BulkEmailsIntroModalController', Controllers.BulkEmailsIntroModalController);
core_module.controller('EmailTemplatesModalController', Controllers.EmailTemplatesModalController);
core_module.controller('ContactFormMembersController', Controllers.ContactFormMembersController);
core_module.controller('EditPreferredVendorsListController', Controllers.EditPreferredVendorsListController);
core_module.controller('ViewPreferredVendorsListController', Controllers.ViewPreferredVendorsListController);
core_module.controller('PVLCreationGuidanceController', Controllers.PVLCreationGuidanceController);
core_module.controller('ConfirmDeleteStageController', Controllers.ConfirmDeleteStageController);
core_module.controller('ConfirmBrochureSelectionController', Controllers.ConfirmBrochureSelectionController);
core_module.controller('SendBrochureWarningController', Controllers.SendBrochureWarningController);
core_module.controller('PipelineSendBrochureController', Controllers.PipelineSendBrochureController);
core_module.controller('ScheduleMeetingModalController', Controllers.ScheduleMeetingModalController);
core_module.controller('EmailSendErrorModalController', Controllers.EmailSendErrorModalController);
core_module.controller('PausedOfferModalController', Controllers.PausedOfferModalController);
core_module.controller('ConnectProjectModalController', Controllers.ConnectProjectModalController);
core_module.controller('mWebConnectProjectModalController', Controllers.mWebConnectProjectModalController);
core_module.controller('ClientBotModalController', Controllers.ClientBotModalController);
core_module.controller('VideoCarouselModalController', Controllers.VideoCarouselModalController);
core_module.controller('TemplateExamplesModalController', Controllers.TemplateExamplesModalController);
core_module.controller('AddPromotionModalController', Controllers.AddPromotionModalController);
core_module.controller('PipelineOnboardingModalController', Controllers.PipelineOnboardingModalController);

core_module.controller('PvlRecommendationsController', Controllers.PvlRecommendationsController);
core_module.controller('EventSpacesController', Controllers.EventSpacesController);
core_module.controller('IntakeModalController', Controllers.IntakeModalController);
core_module.controller('OnboardingGuidanceController', Controllers.OnboardingGuidanceController);
core_module.controller('OnBoardingMobileGuideController', Controllers.OnBoardingMobileGuideController);
core_module.controller('OnboardingMobileQuizController', Controllers.OnboardingMobileQuizController);

core_module.controller('StartFromFileWizardModalController', Controllers.StartFromFileWizardModalController);
core_module.controller('ClientFlowModalController', Controllers.ClientFlowModalController);
core_module.controller('LoginAsClientController', Controllers.LoginAsClientController);
core_module.controller('TwoFactorAuthModalController', Controllers.TwoFactorAuthModalController);
core_module.controller('LoginTwoFactorAuthModalController', Controllers.LoginTwoFactorAuthModalController);
core_module.controller('AddSecurityPhoneNumberModalController', Controllers.AddSecurityPhoneNumberModalController);
core_module.controller('StartTrialAsClientModalController', Controllers.StartTrialAsClientModalController);
core_module.controller('NonUsaWarningController', Controllers.NonUsaWarningController);
core_module.controller('ConfirmFileExpirationController', Controllers.ConfirmFileExpirationController);
core_module.controller('TimeTrackerController', Controllers.TimeTrackerController);
core_module.controller('TimeTrackerEntryModalController', Controllers.TimeTrackerEntryModalController);
core_module.controller('TimeTrackerViewAllModalController', Controllers.TimeTrackerViewAllModalController);
core_module.controller('CreateFirstProjectController', Controllers.CreateFirstProjectController);
core_module.controller('TimeTrackerSampleModalController', Controllers.TimeTrackerSampleModalController);
core_module.controller('TimeTrackerToolInvoiceController', Controllers.TimeTrackerToolInvoiceController);
core_module.controller('BadgePillTypesModalController', Controllers.BadgePillTypesModalController);
core_module.controller('ArchiveReasonsModalController', Controllers.ArchiveReasonsModalController);

core_module.controller('BatchEmailsModalController', Controllers.BatchEmailsModalController);
core_module.controller('ConfirmGmailIntegrationNeededController', Controllers.ConfirmGmailIntegrationNeededController);
core_module.controller('RefundPaymentController', Controllers.RefundPaymentController);
core_module.controller('DebitCardController', Controllers.DebitCardController);
core_module.controller('WaiveLateFeeController', Controllers.WaiveLateFeeController);
core_module.controller('InstantDepositModalController', Controllers.InstantDepositModalController);
core_module.controller('UpcomingPaymentsModalController', Controllers.UpcomingPaymentsModalController);
core_module.controller('InstantDepositPromoteAutoModalController', Controllers.InstantDepositPromoteAutoModalController);
core_module.controller('CreditCardConfirmController', Controllers.CreditCardConfirmController);
core_module.controller('AchPushToastController', Controllers.AchPushToastController);
core_module.controller('RescheduleProjectSuccessToastController', Controllers.RescheduleProjectSuccessToastController);
core_module.controller('BrandingInspirationModalController', Controllers.BrandingInspirationModalController);
core_module.controller('UploadClientsController', Controllers.UploadClientsController);
core_module.controller('FacebookLeadsController', Controllers.FacebookLeadsController);
core_module.controller('FacebookLeadsDirectiveController', Controllers.FacebookLeadsDirectiveController);
core_module.controller('FacebookLeadsIntroModalController', Controllers.FacebookLeadsIntroModalController);
core_module.controller('EditPaymentReminderEmailController', Controllers.EditPaymentReminderEmailController);
core_module.controller('ConnectBankAccountModalController', Controllers.ConnectBankAccountModalController);
core_module.controller('SearchV2ModalController', Controllers.SearchV2ModalController);
core_module.controller('ProjectDetailsNotesModalController', Controllers.ProjectDetailsNotesModalController);
core_module.controller('ProjectDetailsCustomizeModalController', Controllers.ProjectDetailsCustomizeModalController);
core_module.controller('BirthdayModalController', Controllers.BirthdayModalController);
core_module.controller('AddBankAccountOwnerController', Controllers.AddBankAccountOwnerController);
core_module.controller('UploadAdditionalDocumentsController', Controllers.UploadAdditionalDocumentsController);
core_module.controller('BankAccountTypeSelectionController', Controllers.BankAccountTypeSelectionController);
core_module.controller('BankAccountBusinessInfoController', Controllers.BankAccountBusinessInfoController);
core_module.controller('MigrateUserModalController', Controllers.MigrateUserModalController);
core_module.controller('PreMigrateUserModalController', Controllers.PreMigrateUserModalController);
core_module.controller('TeamMembersOtamModalController', Controllers.TeamMembersOtamModalController);

core_module.controller('SelectEmailProviderController', Controllers.SelectEmailProviderController);


core_module.component('hbBankAccountIdentificationUpload', Components.BankAccountIdentificationUpload);
core_module.component('hbIdentificationDocumentUpload', Components.IdentificationDocumentUpload);



// first level and second level navigation assets
core_module.controller('TopNavController', Controllers.TopNavController);
core_module.controller('SecondLevelNavigationController', Controllers.SecondLevelNavigationController);
core_module.controller('SecondLevelNavigationBookkeepingController', Controllers.SecondLevelNavigationBookkeepingController);
core_module.controller('SecondLevelNavigationInviteController', Controllers.SecondLevelNavigationInviteController);
core_module.controller('SecondLevelNavigationHomeController', Controllers.SecondLevelNavigationHomeController);
core_module.controller('TitleSecondLevelNavigationController', Controllers.TitleSecondLevelNavigationController);
core_module.controller('SettingsSecondLevelNavigationController', Controllers.SettingsSecondLevelNavigationController);
core_module.controller('FirstLevelNavSinglePageController', Controllers.FirstLevelNavSinglePageController);
core_module.controller('ClientNavigationController', Controllers.ClientNavigationController);
core_module.controller('TemplatesSecondLevelNavigationController', Controllers.TemplatesSecondLevelNavigationController);
core_module.controller('ReferralModalController', Controllers.ReferralModalController);
core_module.directive('hbTemplatesList', Directives.TemplatesListDirective);
core_module.directive('hbTemplatesListItem', Directives.TemplatesListItemDirective);

// settings
core_module.controller('SettingsMobileController', Controllers.SettingsMobileController);
core_module.controller('CompanySettingsController', Controllers.CompanySettingsController);
core_module.controller('CompanySettingsMobileController', Controllers.CompanySettingsMobileController);
core_module.controller('CompanySettingsGeneralController', Controllers.CompanySettingsGeneralController);
core_module.controller('CompanySettingsBrandingController', Controllers.CompanySettingsBrandingController);
core_module.controller('CompanySettingsIntegrationsController', Controllers.CompanySettingsIntegrationsController);

core_module.controller('MwebBlockModalController', Controllers.MwebBlockModalController);

core_module.directive('hbBrochureTemplateEmptyState', Directives.BrochureTemplateEmptyStateDirective);
core_module.directive('hbQuestionnaireTemplateEmptyState', Directives.QuestionnaireTemplateEmptyStateDirective);
core_module.directive('hbPackageTemplateEmptyState', Directives.PackageTemplateEmptyStateDirective);

core_module.directive('hbProposalTemplateEmptyState', Directives.ProposalTemplateEmptyStateDirective);
core_module.directive('hbGiftCardTemplateEmptyState', Directives.GiftCardTemplateEmptyStateDirective);
core_module.controller('TasksMgmtController', Controllers.TasksMgmtController);
core_module.component('hbProjectActionDropdownComponent', Components.ProjectActionDropdownComponent);

core_module.directive('hbAccordion', Directives.AccordionDirective);
core_module.directive('asideDrawer', Directives.AsideDrawerDirective);
core_module.directive('hbService', Directives.ServiceDirective);
core_module.directive('hbPackage', Directives.PackageDirective);
core_module.directive('hbCompanyPackages', Directives.CompanyPackagesDirective);
core_module.directive('hbProposal', Directives.ProposalDirective);
core_module.directive('hbBrochure', Directives.BrochureDirective);
core_module.directive('hbLoginTwoFactorAuthBanner', Directives.LoginTwoFactorAuthBannerDirective);

// Dynamic content
core_module.component('hbContactFormContent', Components.ContactFormContentComponent);

core_module.component('hbAddBlock', Components.AddBlock);
core_module.component('hbBlockImage', Components.BlockImage);
core_module.component('hbBlockCoverImg', Components.BlockCoverImg);
core_module.component('hbBlockTwoImg', Components.BlockTwoImg);
core_module.component('hbBlockTwoColText', Components.BlockTwoColText);
core_module.component('hbBlockTextImg', Components.BlockTextImg);
core_module.component('hbBlockLeftAligned', Components.BlockLeftAligned);
core_module.component('hbBlockFullWidthText', Components.BlockFullWidthText);
core_module.component('hbBlockProposal', Components.BlockProposal);
core_module.component('hbBlockProposalSettings', Components.BlockProposalSettings);

core_module.component('hbBlockDynamic', Components.BlockDynamic);
core_module.component('hbBaseComponent', Components.BaseComponent);
core_module.component('hbImageComponent', Components.ImageComponent);
core_module.component('hbTextComponent', Components.TextComponent);
core_module.component('hbRedemptionPolicyComponent', Components.RedemptionPolicyComponent);
core_module.component('hbTextOverImageComponent', Components.TextOverImageComponent);
core_module.component('hbListComponent', Components.ListComponent);
core_module.component('hbFormComponent', Components.FormComponent);
core_module.component('hbProposalComponent', Components.ProposalComponent);
core_module.component('hbPromotionOfferComponent', Components.PromotionOfferComponent);
core_module.component('hbGiftCardComponent', Components.GiftCardComponent);
core_module.component('hbCreateFlowMenu', Components.CreateFlowMenuComponent);


core_module.component('hbQuestion', Components.Question);
core_module.component('hbFormComponentSettings', Components.FormComponentSettings);
core_module.component('hbTextQuestion', Components.TextQuestion);
core_module.component('hbDatePickerQuestion', Components.DatePickerQuestion);
core_module.component('hbMultiChoiceQuestion', Components.MultiChoiceQuestion);

//account setup AccountSetupSuccess
core_module.component('hbAccountSetupSuccess', Components.AccountSetupSuccess);

// Bank account
core_module.component('hbCompanySettingsBank', Components.CompanySettingsBank);
core_module.component('hbBankAccountEntityInfo', Components.BankAccountEntityInfo);
core_module.component('hbBankAccountTypeSelection', Components.BankAccountTypeSelection);
core_module.directive('hbBankAccountDependentField', Directives.BankAccountDependentField);

// Brochure new
core_module.directive('hbBrochureNew', Directives.BrochureNewDirective);
core_module.component('hbDynamicContentSection', Components.DynamicContentSection);
core_module.component('hbBrochureReviewSection', Components.BrochureReviewSection);


core_module.directive('hbQuestionnaire', Directives.QuestionnaireDirective);
core_module.directive('hbQuestionOld', Directives.QuestionDirective);
core_module.directive('hbTimeline', Directives.TimelineDirective);
core_module.directive('hbTimeTracker', Directives.TimeTrackerDirective);
core_module.directive('hbHomeCard', Directives.HomeCardDirective);
core_module.directive('hbTimeTrackerEmpty', Directives.TimeTrackerEmptyDirective);
core_module.directive('hbTimelineSection', Directives.TimelineSectionDirective);
core_module.directive('hbOpenQuestion', Directives.OpenQuestionDirective);
core_module.directive('hbFadeIn', Directives.FadeInDirective);
core_module.directive('hbTextareaQuestion', Directives.TextareaQuestionDirective);
core_module.directive('hbCheckboxQuestion', Directives.CheckboxQuestionDirective);
core_module.directive('hbRadioQuestion', Directives.RadioQuestionDirective);
core_module.directive('hbDropdownQuestion', Directives.DropdownQuestionDirective);
core_module.directive('hbSignatures', Directives.SignaturesDirective);
core_module.directive('hbSignature', Directives.SignatureDirective);
core_module.directive('hbAgreement', Directives.AgreementDirective);
core_module.directive('hbEditableAgreement', Directives.EditableAgreementDirective);
core_module.directive('hbReadableAgreement', Directives.ReadableAgreementDirective);
core_module.directive('hbInputPopup', Directives.InputPopupDirective);
core_module.directive('hbAgreementField', Directives.AgreementFieldDirective);
core_module.directive('hbEmailEditor', Directives.EmailEditorDirective);
core_module.component('hbEmailEditorSuccess', Components.EmailEditorSuccess);
core_module.directive('hbInnerEmailEditor', Directives.InnerEmailEditorDirective);
core_module.directive('hbFeedItem', Directives.FeedItemDirective);
core_module.directive('hbFeedItemSeen', Directives.FeedItemSeenDirective);
core_module.directive('hbFeedEmailViewer', Directives.FeedEmailViewerDirective);
core_module.directive('hbFeedActivityViewer', Directives.FeedActivityViewerDirective);
core_module.component('hbFeedItemSessionViewer', Components.FeedItemSessionViewer);
core_module.component('hbFeedItemGiftCardViewer', Components.FeedItemGiftCardViewer);
core_module.component('hbFeedItemFlowViewer', Components.FeedItemFlowViewer);
core_module.component('hbFeedItemFlowSignatureViewer', Components.FeedItemFlowSignatureViewer);
core_module.component('hbFeedItemFlowServiceSelectionViewer', Components.FeedItemFlowServiceSelection);
core_module.component('hbFeedItemFlowPaymentViewer', Components.FeedItemFlowPaymentViewer);
core_module.directive('hbFeedMessageViewer', Directives.FeedMessageViewerDirective);
core_module.directive('hbFeedCommentViewer', Directives.FeedCommentViewerDirective);
core_module.directive('hbFeedGenericMessageViewer', Directives.FeedGenericMessageViewerDirective);
core_module.component('hbFeedOneComposer',Components.FeedOneComposerComponent);
core_module.directive('hbFeedMessageEditor', Directives.FeedMessageEditorDirective);
core_module.directive('hbAddTeamMemberFromSession', Directives.AddTeamMemberFromSessionDirective);
core_module.component('hbFeedFileThumbnail', Components.FeedFileThumbnailComponent);
core_module.component('hbFlowCard', Components.FlowCard);
core_module.directive('hbWorkspaceFileCard', Directives.WorkspaceFileCardDirective);
core_module.directive('hbWorkspaceFileCardDropdown', Directives.WorkspaceFileCardDropdownDirective);
core_module.directive('hbWorkspaceFileCardProgress', Directives.WorkspaceFileCardProgressDirective);
core_module.directive('hbWorkspaceCard', Directives.WorkspaceCardDirective);
core_module.directive('hbWorkspacePipeline', Directives.WorkspacePipelineDirective);
core_module.directive('hbProjectsTools', Directives.ProjectToolsDirective);
core_module.directive('hbProjectToolEmptyState', Directives.ProjectToolsEmptyStateDirective);
core_module.directive('hbFileTypeTooltip', Directives.FileTypeTooltipDirective);
core_module.directive('hbFlowsFileTypeTooltip', Directives.FlowsFileTypeTooltipDirective);
core_module.directive('hbOtamBanner', Directives.OtamBannerDirective);
core_module.directive('hbTemplates', Directives.TemplatesDirective);
core_module.directive('hbBankAccount', Directives.BankAccountDirective);
core_module.directive('hbWidget', Directives.WidgetDirective);
core_module.directive('hbContactForm', Directives.ContactFormDirective);
core_module.directive('hbClientBreadcrumbs', Directives.ClientBreadcrumbsDirective);
core_module.directive('hbLargeSignatureDirective', Directives.LargeSignatureDirective);
core_module.directive('hbFileHeader', Directives.FileHeaderDirective);
core_module.directive('hbEditableHeader', Directives.EditableHeaderDirective);
core_module.directive('hbEditableHeaderBillTo', Directives.EditableHeaderBillToDirective);
core_module.directive('hbRepeatEvent', Directives.RepeatEventDirective);
core_module.directive('hbPreviewFileToggle', Directives.PreviewFileToggleDirective);
core_module.directive('hbOnboardingPopover', Directives.OnboardingPopoverDirective);
core_module.directive('hbGallery', Directives.GalleryDirective);
core_module.directive('hbActivityNotifications', Directives.ActivityNotificationsDirective);
core_module.directive('hbActivityNotification', Directives.ActivityNotificationDirective);
core_module.directive('voKeyUp', Directives.VoKeyUpDirective);
core_module.directive('messageComposer', Directives.MessageComposerDirective);
core_module.directive('hbCreateWorkspaceDropdown', Directives.CreateWorkspaceDropdownDirective);
core_module.directive('hbClientRequestPvlFeedHeader', Directives.ClientRequestPvlFeedHeaderDirective);
core_module.directive('hbAddCopyToClipboard', Directives.AddCopyToClipboardDirective);
core_module.directive('hbBadgePillList', Directives.BadgePillListDirective);
core_module.directive('hbExpense', Directives.ExpenseDirective);
core_module.directive('hbFbLeadPage', Directives.FaceboookPageLeadDirective);
core_module.directive('hbSearchForProject', Directives.SearchForProjectDirective);
core_module.directive('hbMeetingConnectedProject', Directives.MeetingConnectedProject);
core_module.directive('hbTasksMgmt', Directives.TasksMgmtDirective);
core_module.directive('hbTaskList', Directives.TaskListDirective);
core_module.directive('hbWorkspaceNotes', Directives.WorkspaceNotesDirective);
core_module.directive('hbTaxEditor', Directives.TaxEditorDirective);
core_module.directive('hbCreateFileWizard', Directives.CreateFileWizardDirective);
core_module.directive('hbConfetti', Directives.ConfettiDirective);
core_module.directive('hbSuggestions', Directives.SuggestionsDirective);
core_module.directive('hbSelectTemplates', Directives.SelectCompanyTemplatesDirective);
core_module.component('hbScheduledSessions', Components.ScheduledSessions);
core_module.directive('hbFeatureFlag', Directives.FeatureFlagDirective);
core_module.component('hbTwoFactorAuthNew', Components.TwoFactorAuthNew);
core_module.component('passkeys', Components.Passkeys);
core_module.component('hbSetupTwoFactorAuth', Components.SetupTwoFactorAuth);

core_module.directive('hbEmailAttachmentView', Directives.EmailAttachmentViewDirective);

// Stateless Feature Components
core_module.component('hbSignatureDropdown', Components.SignatureDropdown);

core_module.component('hbDiscountEditor', Components.DiscountEditor);

// Stateless mWeb Home components
core_module.component('hbHomeWelcomeMessage', Components.HomeWelcomeMessage);
core_module.component('hbHomeExploreToolsMenu', Components.HomeExploreToolsMenu);

core_module.component('hbAccountSetupTilePrompt', Components.AccountSetupTilePrompt);
core_module.component('hbContactFormEmptyState', Components.ContactFormEmptyState);
core_module.component('hbContactFormWebsiteBuilderHelpDoc', Components.ContactFormWebsiteBuilderHelpDoc);

core_module.directive('hbAccountSetupDisabled', Directives.AccountSetupDisabled);
core_module.directive('hbAccountSetupFaq', Directives.AccountSetupFaq);


// New Invoice components
core_module.directive('hbServiceNew', Directives.ServiceDirectiveNew);
core_module.directive('hbPackageNew', Directives.PackageDirectiveNew);
core_module.directive('hbProposalNew', Directives.ProposalDirectiveNew);
core_module.directive('hbProposalSummary', Directives.ProposalSummaryDirective);
core_module.directive('hbPaymentsContainer', Directives.PaymentsContainerDirective);
core_module.directive('hbPayment', Directives.PaymentDirective);
core_module.controller('MarkAsPaidController', Controllers.MarkAsPaidController);
core_module.component('hbFileSectionMobilePage', Components.FileSectionMobilePageComponent);
core_module.component('hbProposalHeader', Components.ProposalHeaderComponent);
core_module.component('hbPackageTemplate', Components.PackageTemplateComponent);
core_module.component('hbServiceAutoComplete', Components.ServiceAutoComplete);
core_module.component('hbServiceAutoCompleteResults', Components.ServiceAutoCompleteResults);
core_module.directive('hbChangeAndBlur', Directives.ChangeAndBlur);
core_module.component('hbRemitTo', Components.RemitToComponent);

// (TODO) ContainerComponents
// Feature-specific container/controller components that fetch and store state and
// pass down data as props to dumb child components.

//preferred vendors
core_module.directive('hbPreferredVendorCard', Directives.PreferredVendorCardDirective);
core_module.directive('hbClientPvlRequest', Directives.ClientPvlRequestDirective);
core_module.directive('hbFeedPvlViewer', Directives.FeedPvlViewerDirective);
core_module.directive('hbFeedLeadConflict', Directives.FeedLeadConflictDirective);

//event
core_module.directive('hbScrollToEvent', Directives.ScrollToEventDirective);
core_module.directive('hbEventsList', Directives.EventsListDirective);

//workflows
core_module.controller('WorkflowsController', Controllers.WorkflowsController);
core_module.controller('WorkflowFirstLevelNavController', Controllers.WorkflowFirstLevelNavController);

//new automations
core_module.controller('NewAutomationsController', Controllers.NewAutomationsController);


// Pipeline
core_module.directive('hbPipelineMain', Directives.PipelineMainDirective);
core_module.directive('hbPipelineWorkspace', Directives.PipelineWorkspace);
core_module.directive('hbPipelineWorkspaceLimited', Directives.PipelineWorkspaceLimited);
core_module.filter('HbPipelineWorkspacesFilter', Filters.HbPipelineWorkspacesFilter);
core_module.directive('hbMoveStageDropdown', Directives.MoveStageDropdown);
core_module.directive('hbTooltipsPipeline', Directives.PipeTooltipsStagesDirecive);
core_module.controller('PendingActionModalController', Controllers.PendingActionModalController);
core_module.component('hbPipelineTableHeading', Components.PipelineTableHeading);
core_module.component('hbPipelineActionsPopover', Components.PipelineActionsPopover);
core_module.component('hbPipelineBulkActions', Components.PipelineBulkActions);

//Account
core_module.directive('hbChangePassword', Directives.ChangePasswordDirective);
core_module.directive('hbUserInfo', Directives.UserInfoDirective);
core_module.directive('hbCompareTo', Directives.CompareToDirective);
core_module.directive('hbSocialAccounts', Directives.SocialAccountsDirective);
core_module.directive('hbEmailIntegration', Directives.EmailIntegrationDirective);
core_module.directive('hbQuickbooksIntegration', Directives.QuickbooksIntegrationDirective);
core_module.directive('hbFbLeadAdsIntegration', Directives.FbLeadAdsIntegrationDirective);
core_module.directive('hbChromeExtensionIntegration', Directives.ChromeExtensionIntegrationDirective);
core_module.directive('hbSecurityPhoneNumber', Directives.SecurityPhoneNumberDirective);
core_module.directive('hbLoginTwoFactorAuth', Directives.LoginTwoFactorAuthDirective);
core_module.directive('hbAccountSettingsProLogin', Directives.AccountSettingsProLoginDirective);
core_module.directive('hbLoginMessageBanner', Directives.LoginMessageBannerDirective);
core_module.component('hbCalendlyIntegration', Components.CalendlyIntegration);
core_module.directive('hbZoomIntegration', Directives.ZoomIntegrationDirective);

core_module.directive('hbAddTeamMemberToProject', Directives.AddTeamMemberDirective);
core_module.directive('hbCollapse', Directives.CollapseDirective);

// TOP NAV
core_module.component('hbToolsItemsList', Components.ToolsItemsList);
core_module.component('hbTools', Components.Tools);
core_module.directive('hbTopNavDropdown', Directives.TopNavDropdownDirective);
core_module.directive('hbTopNavDropdownMobile', Directives.TopNavDropdownMobileDirective);

core_module.directive('hbNavLargeScreens', Directives.NavLargeScreensDirective);
core_module.directive('hbNavSmallScreens', Directives.NavSmallScreensDirective);

core_module.directive('hbPageTitle', Directives.PageTitleDirective);
core_module.directive('hbTopNavProfile', Directives.TopNavProfileDirective);
core_module.directive('hbToggleOnHover', Directives.ToggleOnHover);
core_module.directive('hbRadialProgressBar', Directives.RadialProgressBar);
core_module.directive('hbAddCard', Directives.AddCardComponentDirective);

// Settings
core_module.directive('hbCompanyBranding', Directives.CompanyBrandingDirective);
core_module.directive('hbCompanyInfo', Directives.CompanyInfoDirective);
core_module.directive('hbCompanyTeam', Directives.CompanyTeamDirective);
core_module.directive('hbCompanyNavigation', Directives.CompanyNavigation);
core_module.directive('hbCustomizeUrl', Directives.CustomizeUrlDirective);
core_module.directive('hbNewCustomizeUrl', Directives.NewCustomizeUrlDirective);
core_module.directive('hbProAccessRequests', Directives.CompanyAccountAccessDirective);

// Client Portal
core_module.controller('ClientPortalController', Controllers.ClientPortalController);
core_module.controller('ClientPortalPreview', Controllers.ClientPortalPreviewController);
core_module.controller('ClientPortalSendLink', Controllers.ClientPortalSendLinkController);
core_module.controller('ClientPortalDemo', Controllers.ClientPortalDemoModalController);
core_module.controller('CustomUrlModalController', Controllers.CustomUrlModalController);
core_module.controller('SubdomainConfigModalController', Controllers.SubdomainConfigModalController);
core_module.component('hbClientPortalPreviewHeader', Components.ClientPortalPreviewHeader);
core_module.component('hbClientPortalPreviewSettings', Components.ClientPortalPreviewSettings);
core_module.component('hbClientPortalPreviewLogin', Components.ClientPortalPreviewLogin);
core_module.component('hbClientPortalLogin', Components.ClientPortalLogin);
core_module.component('hbClientPortalLoginCreation', Components.ClientPortalLoginCreation);
core_module.component('hbClientPortalLoginForm', Components.ClientPortalLoginForm);
core_module.component('hbClientPortalResetPassword', Components.ClientPortalResetPassword);
core_module.component('hbClientPortalLogo', Components.ClientPortalLogo);
core_module.component('hbCompanyClientPortal', Components.CompanyClientPortal);
core_module.component('hbNewCompanyClientPortal', Components.NewClientPortalComponent);

// contact form aside menu content
core_module.component('hbContactFormSettings', Components.ContactFormSettings);
core_module.component('hbContactFormDirectLink', Components.ContactFormDirectLink);
core_module.component('hbContactFormDesignFonts', Components.ContactFormDesignFonts);
core_module.component('hbContactFormDesignColors', Components.ContactFormDesignColors);

// take a tour component
core_module.component('hbStartTour', Components.HBStartTour);

// New Signup flow
core_module.controller('SignupController', Controllers.SignupController);
core_module.component('hbSignupHeader', Components.SignupHeader);
core_module.component('hbSignupProgressBar', Components.SignupProgressBar);
core_module.component('hbSignupInput', Components.SignupInput);
core_module.component('hbSignupExampleFile', Components.SignupExampleFile);
core_module.component('hbSignupLoader', Components.SignupLoader);
core_module.component('hbRomeyTooltip', Components.RomeyTooltip);
core_module.directive('hbPhoneNumber', Directives.PhoneNumberFormatter);

// Year In Review
core_module.controller('YearInReviewController', Controllers.YearInReview2022Controller);

// Interactive onboarding
core_module.component('hbInteractiveOnboarding', Components.InteractiveOnboarding);
core_module.component('hbInteractiveOnboardingProgress', Components.InteractiveOnboardingProgress);
core_module.component('hbInteractiveAccount', Components.InteractiveAccount);
core_module.component('hbInteractiveBranding', Components.InteractiveBranding);
core_module.component('hbInteractiveOnboardingWizard', Components.InteractiveOnboardingWizard);
core_module.component('hbInteractiveOnboardingList', Components.InteractiveOnboardingList);
core_module.component('hbInteractiveServices', Components.InteractiveServices);
core_module.component('hbInteractiveClientSide', Components.InteractiveClientSide);
core_module.controller('InteractiveOnboardingExplainBrandingModalController', Controllers.InteractiveOnboardingExplainBrandingModalController);
core_module.controller('InteractiveOnboardingExplainServicesModalController', Controllers.InteractiveOnboardingExplainServicesModalController);

// Flows onboarding
core_module.component('hbFlowsOnboarding', Components.FlowsOnboarding);
core_module.component('hbFlowsOnboardingWizard', Components.FlowsOnboardingWizard);
core_module.component('hbFlowsOnboardingProgress', Components.FlowsOnboardingProgress);
core_module.component('hbFlowsOnboardingWizardBranding', Components.FlowsOnboardingWizardBranding);
core_module.component('hbFlowsOnboardingWizardTemplateReady', Components.FlowsOnboardingWizardTemplateReady);
core_module.component('hbFlowsOnboardingWizardServices', Components.FlowsOnboardingWizardServices);
core_module.component('hbFlowsOnboardingWizardLegacyServices', Components.FlowsOnboardingWizardLegacyServices);
core_module.component('hbFlowsOnboardingWizardTemplates', Components.FlowsOnboardingWizardTemplates);
core_module.controller('MwebPreviewBrandingModalController', Controllers.MwebPreviewBrandingModalController);


// Financial widget
core_module.component('hbFinancialWidget', Components.FinancialWidget);

//general-purpose ui
//general-purpose ui
core_module.component('hbInjectBranding', Components.InjectBranding);
core_module.component('hbApplyBranding', Components.ApplyBranding);
core_module.component('hbExampleFile', Components.ExampleFile);
core_module.component('hbExampleFlow', Components.ExampleFlow);
core_module.component('hbVideoTutorial', Components.VideoTutorial);

// Multi Brands
core_module.controller('BrandsController', Controllers.BrandsController);
core_module.controller('AddCompanyModalController', Controllers.AddCompanyModalController);
core_module.component('hbBrand', Components.Brand);
core_module.component('hbNewHbLogo', Components.NewHbLogo);
core_module.component('hbUserBrands', Components.UserBrands);

core_module.component('hbPaymentMethods', Components.PaymentMethods);

// Calendar
core_module.component('hbInputGroupTitle', Components.InputGroupTitle);
core_module.component('hbCreateMeetingForm', Components.CreateMeetingForm);
core_module.component('hbMeetingTextField', Components.MeetingTextField);
core_module.component('hbMeetingPhoneField', Components.MeetingPhoneField);
core_module.component('hbMeetingDateField', Components.MeetingDateField);
core_module.component('hbMeetingSearchField', Components.MeetingSearchField);
core_module.component('hbMeetingSearchResults', Components.MeetingSearchResults);
core_module.component('hbMeetingLocation', Components.MeetingLocation);
core_module.component('hbAddListItemButton', Components.AddListItemButton);

// SCHEDULING
core_module.controller('SchedulingController', Controllers.SchedulingController);
core_module.controller('ConfigureSessionController', Controllers.ConfigureSessionController);
core_module.component('hbSessionAccordionItem', Components.SessionAccordionItem);
core_module.component('hbAvailabilityView', Components.AvailabilityView);
core_module.component('hbSessionsList', Components.SessionsList);
core_module.component('hbSessionCard', Components.SessionCard);
core_module.component('hbSessionReminder', Components.SessionReminder);
core_module.controller('EditDayAvailabilityModalController', Controllers.EditDayAvailabilityModalController);
core_module.controller('CreatedSessionModalController', Controllers.CreatedSessionModalController);


//SERVICES
core_module.service('AttachmentsService', Services.AttachmentsService);
core_module.service('UpcomingPaymentsService', Services.UpcomingPaymentsService);
core_module.service('NavigationService', Services.NavigationService);
core_module.service('ContactFormService', Services.ContactFormService);
core_module.service('InitialAppLoadParamsService', Services.InitialAppLoadParamsService);
core_module.service('AuthService', Services.AuthService);
core_module.service('WorkspaceFileService', Services.WorkspaceFileService);
core_module.service('PricingPlansService', Services.PricingPlansService);
core_module.service('PreviewModeService', Services.PreviewModeService);
core_module.service('QuestionService', Services.QuestionService);
core_module.service('QuestionsStateService', Services.QuestionsStateService);
core_module.service('CompanyService', Services.CompanyService);
core_module.service('AutomationService', Services.AutomationService);
core_module.service('UserService', Services.UserService);
core_module.service('WorkspaceService', Services.WorkspaceService);
core_module.service('EventService', Services.EventService);
core_module.service('RedirectService', Services.RedirectService);
core_module.service('NotificationService', Services.NotificationService);
core_module.service('UiPersistenceService', Services.UiPersistenceService);
core_module.service('AbTestService', Services.AbTestService);
core_module.service('FeedService', Services.FeedService);
core_module.service('OnboardingService', Services.OnboardingService);
core_module.service('IntentService', Services.IntentService);
core_module.service('InstantBrandingService', Services.InstantBrandingService);
core_module.service('SavingIndicatorService', Services.SavingIndicatorService);
core_module.service('ReferralService', Services.ReferralService);
core_module.service('TemplatesViewService', Services.TemplatesViewService);
core_module.service('CalendarService', Services.CalendarService);
core_module.service('FeaturesService', Services.FeaturesService);
core_module.service('NetworkConnectionService', Services.NetworkConnectionService);
core_module.service('NetworkOnboardingService', Services.NetworkOnboardingService);
core_module.service('PvlService', Services.PvlService);
core_module.service('LinksService', Services.LinksService);
core_module.service('CssVariablesService', Services.CssVariablesService);
core_module.service('HelpService', Services.HelpService);
core_module.service('WorkflowService', Services.WorkflowService);
core_module.service('RTEFactory', Services.RTEFactory);
core_module.service('AgreementValuesStrategyFactory', Services.AgreementValuesStrategyFactory);
core_module.service('SetupGuideService', Services.SetupGuideService);
core_module.service('JsSlicklightboxService', Services.JsSlicklightboxService);
core_module.service('TimeTrackingService', Services.TimeTrackingService);
core_module.service('SuggestionsService', Services.SuggestionsService);
core_module.service('HomeService', Services.HomeService);
core_module.service('PretrialersService', Services.PretrialersService);
core_module.service('AccountsService', Services.AccountsService);
core_module.service('InvoiceHubService', Services.InvoiceHubService);
core_module.service('FilesHubService', Services.FilesHubService);
core_module.service('FinanceAppService', Services.FinanceAppService);
core_module.service('LoginTwoFactorAuthService', Services.LoginTwoFactorAuthService);
core_module.service('InjectBrandingService', Services.InjectBrandingService);
core_module.service('ClientPortalService', Services.ClientPortalService);
core_module.service('MobileAppService', Services.MobileAppService);
core_module.service('FlowService', Services.FlowService);
core_module.service('FontsService', Services.FontsService);
core_module.service('ContactViewService', Services.ContactViewService);
core_module.service('SearchV2Service', Services.SearchV2Service);
core_module.service('ColorsService', Services.ColorsService);
core_module.service('SchedulingService', Services.SchedulingService);
core_module.service('CouponsService', Services.CouponsService);
core_module.service('ReactLoaderService', Services.ReactLoaderService);
core_module.service('RescheduleProjectService', Services.RescheduleProjectService);
core_module.service('FlowsBetaUserTypeService', Services.FlowsBetaUserTypeService);
core_module.service('OtamStatusService', Services.OtamStatusService);
core_module.service('TemplatesImportService', Services.TemplatesImportService);
core_module.service('FeatureRestrictionService', Services.FeatureRestrictionService);
core_module.service('DisputesService', Services.DisputesService);
core_module.service('GlobalBannerService', Services.GlobalBannerService);
core_module.service('MathService', Services.MathService);
core_module.service('NewAutomationsService', Services.NewAutomationsService);

// project
core_module.component('hbProjectForm', Components.ProjectForm);
core_module.component('hbProjectDate', Components.ProjectDate);

// templates page
core_module.controller('TemplatesPageController', Controllers.TemplatesPageController);
core_module.controller('FlowsTemplateGalleryController', Controllers.FlowsTemplateGalleryController);
core_module.controller('MyTemplatesController', Controllers.MyTemplatesController);
core_module.controller('TemplatesFirstLevelNavController', Controllers.TemplatesFirstLevelNavController);
core_module.controller('TemplatesFullPageEditorController', Controllers.TemplatesFullPageEditorController);
core_module.component('hbTemplateFileCard', Components.TemplateFileCard);
core_module.component('hbTemplateFileCardPopover', Components.TemplateFileCardPopover);
core_module.component('hbCreateTemplateDropdown', Components.CreateTemplateDropdown);
core_module.controller('TemplatesExplainModalController', Controllers.TemplatesExplainModalController);


// project details
core_module.component('hbProjectDetailsIcon', Components.ProjectDetailsIcon);
core_module.component('hbProjectDetailsInfo', Components.ProjectDetailsInfo);
core_module.component('hbProjectDetailsSpaces', Components.ProjectDetailsSpaces);
core_module.component('hbProjectDetailsDateLocation', Components.ProjectDetailsDateLocation);
core_module.component('hbProjectDetailsTimezone', Components.ProjectDetailsTimezone);
core_module.component('hbProjectDetailsNotes', Components.ProjectDetailsNotes);
core_module.component('hbProjectDetailsFieldVisibility', Components.ProjectDetailsFieldVisibility);

// Reschedule project
core_module.component('hbRescheduleChooseDate', Components.RescheduleChooseDateComponent);
core_module.component('hbRescheduleWorkflow', Components.RescheduleWorkflowComponent);
core_module.component('hbReschedulePayments', Components.ReschedulePaymentsComponent);
core_module.component('hbRescheduleChooseContract', Components.RescheduleChooseContractComponent);
core_module.component('hbRescheduleSendNotification', Components.RescheduleSendNotificationComponent);
core_module.component('hbRescheduleEditContract', Components.RescheduleEditContractComponent);
core_module.component('hbReschedulePaymentsContainer', Components.ReschedulePaymentsContainerComponent);
core_module.component('hbRescheduleExpiredFile', Components.RescheduleExpiredFileComponent);

// calendar
core_module.component('hbCalendarToolbar', Components.CalendarToolbar);
core_module.component('hbCalendarControl', Components.CalendarControl);
core_module.component('hbCalendarList', Components.CalendarList);
core_module.component('hbCalendarListItem', Components.CalendarListItem);
core_module.component('hbCalendarPopover', Components.CalendarPopover);
core_module.component('hbCalendarPopoverContent', Components.CalendarPopoverContent);
core_module.component('hbCalendarPopoverListItem', Components.CalendarPopoverListItem);

core_module.controller('ContactFormExplanationVideoModalController', Controllers.ContactFormExplanationVideoModalController)

core_module.factory('AgreementValuesStrategy', Classes.AgreementValuesStrategy);
core_module.factory('AgreementValuesTemplateStrategy', Classes.AgreementValuesTemplateStrategy);
core_module.factory('AgreementValuesFileStrategy', Classes.AgreementValuesFileStrategy);
core_module.factory('AgreementValuesSimulatedFileStrategy', Classes.AgreementValuesSimulatedFileStrategy);

// INSTANT PAY
core_module.controller('PayController', Controllers.PayController);


// NG-IN-REACT
core_module.controller('NgInReactModalOpenerController', Controllers.NgInReactModalOpenerController);
core_module.controller('NgInReactComponentRendererController', Controllers.NgInReactComponentRendererController);



// @ngInject
core_module.config(
    function ($stateProvider, $urlRouterProvider, AppStates, $httpProvider, $animateProvider) {

        $httpProvider.useApplyAsync(true);

        //////////////////////////
        // State Configurations //
        //////////////////////////

        // Defaults configurations
        // This is handy when you want to define a default state to an abstract state

        function urlOverrideHandler(toState, stateParams) {
            return ['$injector', '$match', '$location', '$stateParams', function($injector, $match, $location, $stateParams) {
                var $state = $injector.get("$state");
                var params = $match || {};
                params = angular.extend(params, $location.$$search || {});
                params = angular.extend(params, stateParams);

                if (localStorage.getItem('redirectSource') === 'react') {
                    localStorage.removeItem('redirectSource');
                    return;
                }

                $state.go(toState, params);
            }];
        }

        function setHbTitle(toWhat, subWhat) {
            return (toWhat)? (document.title = 'HoneyBook: ' + (
                              subWhat? (toWhat + ', ' + subWhat) : (toWhat)
                             ))
                           : ('Workflow And Community To Grow And Manage Your Business');
        }

        function isInClientPortal() {
            var clientPortalDomains = ['hb-site.com', 'hb-site-dev.com', 'hbportal.co'];
            var host = window.location.host;
            var parts = host.split(':')[0].split('.');
            parts.shift();
            var domain = parts.join('.');
            return (clientPortalDomains.indexOf(domain) !== -1 && window.clientPortalConfiguration)
        }

        // When needed - use regex in the when, otherwise won't work..
        $urlRouterProvider
            .when('/settings', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_overview))
            .when(/vendor\/settings/, urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_overview))
            .when(/home\/workspaces-cards/, urlOverrideHandler(AppStates.root_core_navigation_pipeline))
            .when(/home\/workspace-files/, '/dashboard/workspace_files') // no such route
            .when(/home\/contacts/, '/dashboard/contacts') // no such route
            .when('/workspace/:workspace_id', urlOverrideHandler(AppStates.root_core_navigation_pipeline))
            .when('/event/', urlOverrideHandler(AppStates.root_core_navigation_pipeline))
            .when('/my-process/', urlOverrideHandler(AppStates.root_core_navigation_process_mapping))
            .when('/event/:event_id/workspace/:workspace_id', urlOverrideHandler(AppStates.root_core_navigation_event_workspace_feed))
            .when('/event/:event_id/workspace/:workspace_id/workspace_files', urlOverrideHandler(AppStates.root_core_navigation_event_workspace_feed))
            .when('/event/:event_id/workspace/:workspace_id/feed', urlOverrideHandler(AppStates.root_core_navigation_event_workspace_feed))
            .when('/event/:event_id', urlOverrideHandler(AppStates.root_core_navigation_event_overview))
            .when('/reports', urlOverrideHandler(AppStates.root_core_navigation_reports_analytics))
            .when('/reports/analytics', urlOverrideHandler(AppStates.root_core_navigation_reports_analytics))
            .when('/reports/', urlOverrideHandler(AppStates.root_core_navigation_reports_analytics))
            .when('/reports/profit-loss', urlOverrideHandler(AppStates.root_core_navigation_profit_loss))
            .when('/profit-loss', urlOverrideHandler(AppStates.root_core_navigation_profit_loss))
            .when('/expenses', urlOverrideHandler(AppStates.root_core_navigation_expenses))
            .when('/settings/company/quickbooks', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_quickbooks))
            .when('/bookkeeping/reports', urlOverrideHandler(AppStates.root_core_navigation_bookkeeping_reports_analytics))
            .when('/bookkeeping/reports/analytics', urlOverrideHandler(AppStates.root_core_navigation_bookkeeping_reports_analytics))
            .when('/bookkeeping/reports/profit-loss', urlOverrideHandler(AppStates.root_core_navigation_bookkeeping_reports_profit_loss))
            .when('/bookkeeping/expenses', urlOverrideHandler(AppStates.root_core_navigation_bookkeeping_expenses))
            .when('/bookkeeping/settings/company', urlOverrideHandler(AppStates.root_core_navigation_bookkeeping_settings_company))
            .when('/bookkeeping/settings/company/quickbooks', urlOverrideHandler(AppStates.root_core_navigation_bookkeeping_settings_company_quickbooks))
            .when('/dashboard/workspace_cards', urlOverrideHandler(AppStates.root_core_navigation_pipeline))
            //.when('/account', urlOverrideHandler(AppStates.root_core_navigation_settings_account))
            .when('/settings/overview', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_overview))
            .when('/settings/library', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_library))
            .when('/settings/contact_form', urlOverrideHandler(AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'}))
            .when('/settings/widget', urlOverrideHandler(AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'}))
            .when('/settings/company/widget', urlOverrideHandler(AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'}))
            .when('/settings/company/contact_form', urlOverrideHandler(AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'}))
            .when('/settings/bank', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_bankInfo))
            .when('/settings/subscription', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_subscription))
            .when('/settings/templates', urlOverrideHandler(AppStates.root_core_navigation_templates))
            .when('/settings/automation', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_automation))
            .when('/settings/team', urlOverrideHandler(AppStates.root_core_navigation_settings_companySettings_team))
            .when('/settings/company/templates', urlOverrideHandler(AppStates.root_core_navigation_templates))
            .when('/reset/:user_id/:guid/network/project/:project_id', urlOverrideHandler(AppStates.root_core_resetPasswordForNetworkProject))
            .when('/dashboard', urlOverrideHandler(AppStates.root_core_navigation_pipeline))
            .when('/people/clients', urlOverrideHandler(AppStates.root_core_navigation_contacts))
            .when('/templates_editor/:templateModelKey?templateId', urlOverrideHandler(AppStates.root_core_navigation_templatesEditor))
            .when('/contacts', urlOverrideHandler(AppStates.root_core_navigation_contacts))
            .when('/native', urlOverrideHandler(AppStates.root_core_native))
            .when('/workflows', urlOverrideHandler(AppStates.root_core_navigation_automations))
            .when('/workflows/:automation_id', urlOverrideHandler(AppStates.root_core_navigation_automation_setup))
            .when('/newautomations', urlOverrideHandler(AppStates.root_core_navigation_newautomations))
            .when('/newautomations/:automation_id', urlOverrideHandler(AppStates.root_core_navigation_newautomations_builder))
            .when('/newautomations/runs/:execution_id', urlOverrideHandler(AppStates.root_core_navigation_newautomations_execution_view))
            .when ('/import-template-loader', urlOverrideHandler(AppStates.root_core_import_template))
            .when ('/import-template-redeem-error', urlOverrideHandler(AppStates.root_core_import_template_redeem_error))
            .when ('/pipeline', 'pipeline/')
        ;



        // Use $stateProvider to configure your states.
        $stateProvider

            .state(AppStates.ng_in_react_modal_opener, {
                url: '/modal-opener?modalServiceMethodName&modalParams',
                views: {
                    'main-view': {
                        template: '<div></div>', // TODO: do we need a template?
                        controller: 'NgInReactModalOpenerController'
                    }
                }
            })
            .state(AppStates.ng_in_react_component_renderer, {
                url: '/component-renderer?componentName&componentProps',
                views: {
                    'main-view': {
                        templateUrl: 'angular/app/modules/core/features/ng_in_react/ng_in_react_component_renderer_template.html',
                        controller: 'NgInReactComponentRendererController',
                        controllerAs: 'ngInReactComponentRendererVm'
                    }
                }
            })
            .state(AppStates.root_core, {
                abstract: true,

                // url to allow params on abstract state
                url: '?dummy',

                params: {
                    disableScrollToTop: false,
                },

                views: {
                    'main-view': {
                        templateUrl: 'angular/app/modules/core/core_template.html',
                        controller: 'CoreController',
                        controllerAs: 'coreVm'

                    }
                }
            })

            .state(AppStates.root_core_googleOAuth2Callback, {
                url: 'googleOAuth2Callback',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/google_integration/google_redirect_page_template.html',
                        controller: 'GoogleRedirectController',
                        controllerAs: 'googleRedirectVm'

                    }
                }
            })

            .state(AppStates.root_core_zoomOAuth2Callback, {
                url: 'zoomOAuth2Callback',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/zoom_integration/zoom_redirect_page_template.html',
                        controller: 'ZoomRedirectController',
                        controllerAs: 'zoomRedirectVm'

                    }
                }
            })

            .state(AppStates.root_core_calendlyOAuth2Callback, {
                url: 'calendlyOAuth2Callback',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/calendly/calendly_redirect_page_template.html',
                        controller: 'CalendlyRedirectController',
                        controllerAs: 'calendlyRedirectVm'

                    }
                }
            })

            .state(AppStates.root_core_intellumSSORedirect, {
                url: 'intellumOAuth',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/intellum/intellum_sso_redirect_page_template.html',
                        controller: 'IntellumSSORedirectController',
                        controllerAs: 'intellumRedirectVm'
                    }
                },

                params: {
                    intellumCode: true
                }
            })

            .state(AppStates.root_core_nylasOAuth2Callback, {
                url: 'nylasOAuth2Callback',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/nylas_integration/nylas_redirect_page_template.html',
                        controller: 'NylasRedirectController',
                        controllerAs: 'nylasRedirectVm'

                    }
                }
            })

            .state(AppStates.root_core_quickbooksOAuth2Callback, {
                url: 'quickbooksOAuth2Callback',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/quickbooks_integration/quickbooks_redirect_page_template.html',
                        controller: 'QuickbooksRedirectController',
                        controllerAs: 'quickbooksRedirectVm'

                    }
                }
            })
            
            
            .state(AppStates.root_core_pictimeOAuth2Callback, {
                url: 'pictimeOAuth2Callback',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/pictime/pictime_redirect_page_template.html',
                        controller: 'PictimeRedirectController',
                        controllerAs: 'pictimeRedirectVm'

                    }
                }
            })

            .state(AppStates.root_core_navigation, {

                abstract: true,

                url: '',
                views: {

                    'first-level-navigation': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/top_nav_template.html',
                        controller: 'TopNavController',
                        controllerAs: 'topNavVm'
                    },


                }
            })

            .state(AppStates.root_core_navigation_client, {

                url: 'client_navigation',

                views: {
                    'first-level-navigation': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/top_nav_template.html',
                        controller: 'TopNavController',
                        controllerAs: 'topNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/navigation/client_navigation_template.html',
                        controller: 'ClientNavigationController',
                        controllerAs: 'clientNavigationVm'
                    }
                }
            })

            .state(AppStates.root_core_login, {

                url: "login?email&guid&id&error&community&vendor",
                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/login/login_template.html',
                        controller: 'LoginController',
                        controllerAs: 'loginVm'
                    }
                }
            })

            .state(AppStates.root_core_community, {
                url: "community/login",
                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/login/login_template.html',
                        controller: 'LoginController',
                        controllerAs: 'loginVm'
                    }
                }
            })
            .state(AppStates.root_core_community_redirect, {
                url: 'community_redirect',
                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/login/community/community_redirect_template.html',
                        controller: 'CommunityRedirectController',
                        controllerAs: 'redirectVm'
                    }
                }
            })
            .state(AppStates.root_core_clientLogin, {

                url: "client_login?link_id",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/login/login_template.html',
                        controller: 'LoginController',
                        controllerAs: 'loginVm'
                    }
                }
            })

            .state(AppStates.root_core_signup, {

                url: "signup",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/signup/signup.html',
                        controller: 'SignupController',
                        controllerAs: 'signupVm'
                    }
                }
            })

            .state(AppStates.root_core_linkExpired, {

                url: "link/expired/:link_id/:link_token",
                params: {vendor: false, user: false, link: false},

                data: {
                    noNavigation: true
                },

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/loginless/loginless_template.html',
                        controller: 'LoginlessController',
                        controllerAs: 'loginlessVm'
                    }
                }
            })

            .state(AppStates.root_core_resolveLink, {

                url: "link/resolve/:link_id/:link_token",

                data: {
                    noNavigation: true
                },

                params: {
                    state: null,
                    stateParams: null
                },

                views: {
                    'center-view': {
                        controller: 'LinkResolverController'
                    }
                }
            })

            .state(AppStates.root_core_resetPassword, {

                url: "reset",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/reset_password/reset_password_template.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'resetPasswordVm'
                    }
                },
                params: {
                    guid: null,
                    user_id: null,
                    link_source: null,
                    event_id: null
                }
            })
            .state(AppStates.root_core_setPassword, {

                url: "set/:user_id/:guid",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/reset_password/reset_password_template.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'resetPasswordVm'
                    }
                }
            })

            .state(AppStates.root_core_resetPasswordForFile, {

                url: "reset/:user_id/:guid/file/:file_id",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/reset_password/reset_password_template.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'resetPasswordVm'
                    }
                }
            })
            .state(AppStates.root_core_resetPasswordForWorkspace, {

                url: "reset/:user_id/:guid/event/:event_id/workspace/:workspace_id",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/reset_password/reset_password_template.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'resetPasswordVm'
                    }
                }
            })

            .state(AppStates.root_core_resetPasswordForEvent, {

                url: "reset/:user_id/:guid/event/:event_id",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/reset_password/reset_password_template.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'resetPasswordVm'
                    }
                }
            })

            .state(AppStates.root_core_resetPasswordForExternalSite, {

                url: "reset/:user_id/:guid/external_site",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/reset_password/reset_password_template.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'resetPasswordVm'
                    }
                }
            })

            .state(AppStates.root_core_admin, {

                url: 'admin/',
                abstract: true
            })

            .state(AppStates.root_core_navigation_activity_notifications, {
                url: 'activity_notifications',

                data: {
                    title: 'Notifications',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/activity_notifications/activity_notification_page_controller_template.html',
                        controller: 'ActivityNotificationsController',
                        controllerAs: 'activityNotificationsPageVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_files, {
                url: 'files',

                data: {
                    title: 'Files'
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/dashboard/workspace_files/dashboard_workspace_files_template.html',
                        controller: 'DashboardWorkspaceFilesController',
                        controllerAs: 'dashboardWorkspaceFilesVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_tools_mobile, {
                url: 'tools',
                onEnter: function() { setHbTitle('Tools'); },

                data: {
                    title: 'Tools',
                    mobileNavPrimary: true
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/tools/tools_mobile_template.html',
                        controller: 'ToolsMobileController',
                        controllerAs: 'toolsMobileVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_business_tools, {
                url: 'business_tools',
                onEnter: function() { setHbTitle('Business Tools'); },

                data: {
                    title: 'Business Tools',
                    backToState: AppStates.root_core_navigation_pipeline
                    //mobileNavPrimary: true
                },

                views: {

                    // 'second-level-navigation@root.core': {
                    //     templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                    //     controller: 'TitleSecondLevelNavigationController',
                    //     controllerAs: 'secondLevelNavVm'
                    // },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/tools/business_tools_template.html',
                        controller: 'BusinessToolsController',
                        controllerAs: 'businessToolsVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_contacts, {
                url: 'contacts?list&cid&search&giftCard&tab',

                params: {
                    googleIntegrationCreated: null,
                    fromAddContactModal: null
                },

                data: {
                    title:"Contacts",
                    hideSecondLevelNavigation: true,
                },

                views: {
                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/contacts/contacts_template.html',
                        controller: 'ContactsController',
                        controllerAs: 'contactsVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_contact_view, {
                url: '/:contact_id',
                onEnter: function() { setHbTitle('Client'); },

                params: {
                    source: null
                },

                data: {
                    hideSecondLevelNavigation: true,
                    disableTopViewShadow: true,
                    hideMobileNav: true
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/contact_view/contact_view_template.html',
                        controller: 'ContactViewController',
                        controllerAs: 'contactViewVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_contact_view_tab, {
                url: '/:selectedTab'
            })

            .state(AppStates.root_core_navigation_organizations, {
                url: 'organizations',
                abstract: true
            })

            .state(AppStates.root_core_navigation_organization_view, {
                url: '/:organization_id',

                params: {
                    source: null
                },

                data: {
                    hideSecondLevelNavigation: true,
                    disableTopViewShadow: true,
                    hideMobileNav: true
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/organization_view/organization_view_template.html',
                        controller: 'OrganizationViewController',
                        controllerAs: 'organizationViewVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_pipeline, {
                url: 'pipeline/:view_id?initialStageId&customize&onboarding&extendTrial',
                onEnter: function() { setHbTitle('Pipeline'); },

                data: {
                    title: 'Projects',
                    mobileNavPrimary: true
                },

                params: {
                    openSettingsOnLoad: false,
                    initialStageId: null,
                    initialSort: null,
                    shouldStartTrial: false,
                    showFeatureOnboarding: null,
                    extendTrial: null
                },

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="PipelineRoot"></hb-react>'
                    }
                }
            })

            .state(AppStates.root_core_navigation_home, {
                url: 'home',

                onEnter: function() { setHbTitle('Home'); },

                data: {
                    title: 'Home',
                    mobileNavPrimary: true
                },

                params: {
                    openOnboardingCard: null,
                    invitation_id: null,
                    isInstantBrandingEnabled: null,
                    email_verification_succeeded: null,
                    picTimeIntegrationSucceeded: null,
                    noModalOnLoad: null,
                    displayPicTimeWelcomeModal: null,
                    openCreateClientModal: null,
                    openUploadPDFModal: null,
                    pre_otam: null,
                },

                views: {
                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_home_template.html',
                        controller: 'SecondLevelNavigationHomeController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/home/home_template.html',
                        controller: 'HomeController',
                        controllerAs: 'homeVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_automations, {
                url: 'automations',

                data: {
                    title: 'Automations',
                    hideSecondLevelNavigation: true,
                    disableTopViewShadow: true
                },

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="AutomationsListRoot" ng-if="workflowsVm.shouldLoadOldAutomations"></hb-react>',
                        controller: 'WorkflowsController',
                        controllerAs: 'workflowsVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_newautomations, {
                url: 'newautomations?tab?workspaceId?workflowId?exportOldAutomations',

                data: {
                    title: 'New Automations',
                    // hideSecondLevelNavigation: true,
                    disableTopViewShadow: true
                },

                params: {
                    tab: null,
                    workspaceId: null,
                    workflowId: null
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/new_automations/new_automations_template.html',
                        controller: 'NewAutomationsController',
                        controllerAs: 'newAutomationsVm'
                    },

                    // 'second-level-navigation@root.core': {
                    //     templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                    //     controller: 'TitleSecondLevelNavigationController',
                    //     controllerAs: 'secondLevelNavVm'
                    // }
                }
            })

            .state(AppStates.root_core_navigation_newautomations_builder, {
                url: '/:automation_id',
                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/new_automations/new_automations_template.html',
                        controller: 'NewAutomationsController',
                        controllerAs: 'newAutomationsVm'
                    },
                    'first-level-navigation@root.core': {
                        // templateUrl: 'angular/app/modules/core/features/workflows/workflow_setup/workflow_first_level_nav_template.html',
                        // controller: 'WorkflowFirstLevelNavController',
                        // controllerAs: 'workflowTopNavVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_newautomations_execution_view, {
                url: '/runs/:execution_id',
                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/new_automations/new_automations_template.html',
                        controller: 'NewAutomationsController',
                        controllerAs: 'newAutomationsVm'
                    },
                    'first-level-navigation@root.core': {
                        // templateUrl: 'angular/app/modules/core/features/workflows/workflow_setup/workflow_first_level_nav_template.html',
                        // controller: 'WorkflowFirstLevelNavController',
                        // controllerAs: 'workflowTopNavVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_automation_setup, {
                url: 'automations/:automation_id',
                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/automations/automations.html',
                        controller: 'AutomationsController',
                        controllerAs: 'automationsVm'
                    },
                    'first-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/workflows/workflow_setup/workflow_first_level_nav_template.html',
                        controller: 'WorkflowFirstLevelNavController',
                        controllerAs: 'workflowTopNavVm'
                    }
                }
            })

            .state(AppStates.root_core_automations_apply, {
                url: 'workspace/:workspace_id/automations/:automation_id',
                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/automations/automations.html',
                        controller: 'AutomationsController',
                        controllerAs: 'automationsVm'
                    }
                },
                data: {
                    noNavigation: true,
                },
                
                params: {
                    shouldOpenInEditMode: false,
                }
            })

            .state(AppStates.root_core_oneFlow, {
                url: 'flow/:flow_id',
                // onEnter: function() { setHbTitle('Settings', 'Team'); },
                params: {
                    isGoToDefault: false,
                    openConfigureModal: false,
                    blockToSelect: null,
                    builderTourIntentVariation: null,
                    isFromOnboarding: false,
                    page: null,
                    scrollTo: null,
                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/one_flow/one_flow_template.html',
                        controller: 'OneFlowController',
                        controllerAs: 'OneFlowVm'
                    }
                },
                data: {
                    noNavigation: true
                },
            })

            .state(AppStates.root_core_oneFlowWithAction, {
                url: '/:action?noModalOnLoad&importTemplateSuccess&isGalleryTemplate&isPriced',

                params: {
                    noModalOnLoad: false,
                    importTemplateSuccess: null,
                    isGalleryTemplate: null,
                    isPriced: null,

                }
            })

            .state(AppStates.root_core_oneFlowPreview, {

                url: '/preview?activeMode',
                
                params: {
                    goBackTo: null,
                    is_preview: false,
                    is_setup_guide: false,
                    activeMode: false
                }
            })

            .state(AppStates.root_core_oneFlowWizardPreview, {
                url: '/edit/preview?isFromShareModal',
                params: {
                    goBackTo: null,
                    isFromShareModal: true
                }
            })

            .state(AppStates.root_core_oneFlowPreviewWithPageName, {
                url: '/:pageName'
            })

          .state(AppStates.root_core_oneFlowTemplateGalleryPreview, {
              url: 'flow/:flow_id/template/:template_id/preview/:pageName',

              views: {

                  'center-view@root.core': {
                      templateUrl: 'angular/app/modules/core/features/one_flow/one_flow_template.html',
                      controller: 'OneFlowController',
                      controllerAs: 'OneFlowVm'
                  }
              },
              data: {
                  noNavigation: true,
              },
          })

            .state(AppStates.root_core_native, {
                url: 'native',

                data: {
                    noNavigation: true
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/home/native/native_template.html',
                        controller: 'NativeController',
                        controllerAs: 'NativeVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_invite, {
                url: 'invite',

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ReferralsRoot"></hb-react>'
                    }
                }
            })

            .state(AppStates.root_core_navigation_invite_referrals, {
                url: '/referrals',

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ReferralsRoot"></hb-react>'
                    }
                }
            })

            .state(AppStates.root_core_navigation_invite_track, {
                url: '/track',

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ReferralsRoot"></hb-react>'
                    }
                }
            })

            .state(AppStates.root_core_navigation_invite_resources, {
                url: '/resources',

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ReferralsRoot"></hb-react>'
                    }
                }
            })

            .state(AppStates.root_core_navigation_invite_perks, {
                url: '/perks',

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ReferralsRoot"></hb-react>'
                    }
                }
            })

            .state(AppStates.root_core_navigation_calendarAbstract, {
                abstract: true,
                onEnter: function() { setHbTitle('Calendar'); },

                data: {
                    title: 'Calendar',
                    noNavigation: true,
                    disableTopViewShadow: true,
                    showCreateSchedule: true,
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/calendar/calendar_abstract_template.html',
                        controller: 'CalendarAbstractController',
                        controllerAs: 'calendarAbstractVm'
                    }
                },

                params: {
                    //this is a place holder for parameters to be sent without showing them on the rout (from the activity notification or feed message)
                    calendar_item_id: null,
                    dateTimeStart: null,
                    googleIntegrationCreated: null,
                    integrateFromScheduling: null
                }
            })

            .state(AppStates.root_core_navigation_calendar, {
                url: 'calendar?dateTimeStart&stc',
                onEnter: function() { setHbTitle('Calendar'); },

                data: {
                    title: 'Calendar',
                    noNavigation: true,
                    disableTopViewShadow: true,
                    showCreateSchedule: true,
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                templateUrl: 'angular/app/modules/core/features/calendar/calendar_template.html',
                controller: 'CalendarController',
                controllerAs: 'calendarVm',

                // views: {
                //     'center-view@root.core': {
                //         templateUrl: 'angular/app/modules/core/features/calendar/calendar_template.html',
                //         controller: 'CalendarController',
                //         controllerAs: 'calendarVm'
                //     }
                // },

                params: {
                    //this is a place holder for parameters to be sent without showing them on the rout (from the activity notification or feed message)
                    calendar_item_id: null,
                    dateTimeStart: null,
                    googleIntegrationCreated: null,
                    integrateFromScheduling: null,
                    nylasIntegrationCreated: null
                }
            })

            .state(AppStates.root_core_navigation_scheduling, {
                url: '^/scheduling',
                onEnter: function() { setHbTitle('Scheduler'); },

                data: {
                    title: 'Scheduling'
                },

                templateUrl: 'angular/app/modules/core/features/calendar/scheduling/scheduling_template.html',
                controller: 'SchedulingController',
                controllerAs: 'schedulingPageVm',

                params: {

                }
            })

            .state(AppStates.root_core_sessionConfig, {
                url: 'session/:sessionId/user/:userId',
                onEnter: function() { },

                data: {

                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/calendar/scheduling/configure_session/configure_session_template.html',
                        controller: 'ConfigureSessionController',
                        controllerAs: 'configureSessionVm'
                    }
                },

                params: {
                    returnTo: null
                }
            })

            .state(AppStates.root_core_navigation_calendarNewAbstract, {
                abstract: true,
                onEnter: function() { setHbTitle('Calendar'); },

                data: {
                    title: 'Calendar',
                    noNavigation: true,
                    disableTopViewShadow: true,
                    showCreateSchedule: true,
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/calendar/calendar_new_abstract_template.html',
                        controller: 'CalendarNewAbstractController',
                        controllerAs: 'calendarAbstractVm'
                    }
                },

                params: {
                    //this is a place holder for parameters to be sent without showing them on the rout (from the activity notification or feed message)
                    calendar_item_id: null,
                    dateTimeStart: null,
                    googleIntegrationCreated: null,
                    integrateFromScheduling: null
                }
            })

            .state(AppStates.root_core_navigation_calendar_new, {
                url: 'calendarnew?dateTimeStart&stc',
                onEnter: function() { setHbTitle('Calendar'); },

                data: {
                    title: 'Calendar',
                    noNavigation: true,
                    disableTopViewShadow: true,
                    showCreateSchedule: true,
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                params: {
                    //this is a place holder for parameters to be sent without showing them on the rout (from the activity notification or feed message)
                    calendar_item_id: null,
                    dateTimeStart: null,
                    googleIntegrationCreated: null,
                    integrateFromScheduling: null,
                    nylasIntegrationCreated: null
                }
            })

            .state(AppStates.root_core_navigation_scheduling_new, {
                url: '^/schedulingnew',
                onEnter: function() { setHbTitle('Scheduling'); },

                data: {
                    title: 'Scheduling'
                },

                params: {

                }
            })

            .state(AppStates.root_core_navigation_batch_email, {
                url: 'batch_emails',
                onEnter: function() { setHbTitle('Batch Emails'); },

                data: {
                    title: 'Batch Emails',
                    hideSecondLevelNavigation: true,
                    disableTopViewShadow: true
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/emails/batch_emails/batch_emails_template.html',
                        controller: 'BatchEmailsController',
                        controllerAs: 'batchEmailsVm'
                    }
                }
            })

            .state(AppStates.root_core_onboardingGuidance, {

                url: 'onboading_guide',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/onboarding_guidance/onboarding_guidance_template.html',
                        controller: 'OnboardingGuidanceController',
                        controllerAs: 'onboardingGuidanceVm'
                    }
                }
            })

            .state(AppStates.root_core_onboardingMobileQuiz, {
                url: 'welcome/:step?purchasedPlan',

                params: {
                    step: {
                        value: null,
                        squash: true
                    }
                },

                data: {
                    noNavigation: true
                },

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/onboarding/mobile_quiz/onboarding_mobile_quiz_template.html',
                        controller: 'OnboardingMobileQuizController',
                        controllerAs: 'onboardingMobileQuizVm'
                    }
                }
            })

            .state(AppStates.root_core_onboardingQuiz, {
                url: 'freetrial/:step',

                params: {
                    step: {
                        value: null,
                        squash: true
                    },
                    isFacebookMobileFlow: false
                },

                data: {
                    noNavigation: true
                },

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/onboarding/mobile_quiz/onboarding_quiz_template.html',
                        controller: 'OnboardingMobileQuizController',
                        controllerAs: 'onboardingMobileQuizVm'
                    }
                }
            })
            .state(AppStates.root_core_navigation_yearInReview, {
                url: 'year_in_review',

                data: {
                    noNavigation: true,
                    title: 'Your Year In Review'
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/year_in_review/2022/year_in_review.html',
                        controller: 'YearInReviewController',
                        controllerAs: 'yearInReview2022Vm'
                    }
                }
            })


            .state(AppStates.root_core_accountSetup, {
                url: 'account_setup',
                params: {
                    importFiles: false,
                    isFromSetupPopup: false
                },
                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/account_setup/account_setup_template.html',
                        controller: 'AccountSetupController',
                        controllerAs: 'accountSetupVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_emptyEvent, {

                views: {

                    'center-view@root.core': {

                        templateUrl: 'angular/app/modules/core/features/event/empty_event_template.html',
                        controller: 'EmptyEventController',
                        controllerAs: 'emptyEventVm'
                    }
                }


            })

            .state(AppStates.root_core_navigation_event_wrapper, {
                abstract: true,
                url: 'event',

                data: {
                    hideMobileNav: true
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/event/event_wrapper_template.html',
                        controller: 'EventWrapperController',
                        controllerAs: 'eventVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_event, {
                url: '/:event_id',
                templateUrl: 'angular/app/modules/core/features/event/event_template.html',
                controller: 'EventController',
                controllerAs: 'eventVm',
                params: {
                    openEventsListOnLoad: false
                }
            })

            .state(AppStates.root_core_navigation_event_overview, {
                url: '/overview?action&source&workspace_type',
                // IMPORTANT: do not delete this state!!. This view was removed on active commando sprint a14-nutella/event-header-iv
                // this state needs to be left here so that old links will be re-routed to the first workspace view
                // this re-routing is done on the $stateChangeStart ui-router event. it's done there so there will not be any side effects
                // on the browser's history and for it to work the state needs to be registed in ui-router.
            })

            .state(AppStates.root_core_navigation_event_workspace, {

                url: '/workspace/:workspace_id?action&source&action_data',

                templateUrl: 'angular/app/modules/core/features/workspace/workspace_template.html',
                controller: 'WorkspaceController',
                controllerAs: 'workspaceVm',
                params: {
                    origin: '',
                    openEventsListOnLoad: false,
                    scrollTo: null
                }

            })

            .state(AppStates.root_core_navigation_event_workspace_feed, {

                url: '/activity',
                onEnter: function($state) {
                    isInClientPortal() ?
                        document.title = window.clientPortalConfiguration.title
                        :
                        setHbTitle('Activity', window.currentSubframe);
                },

                params: {
                    template_id: null
                },

                views: {

                    'workspace-content': {

                        templateUrl: 'angular/app/modules/core/features/workspace/feed/workspace_feed_switch_template.html',
                        controller: 'WorkspaceFeedSwitchController',
                        controllerAs: 'workspaceFeedSwitchVm'
                    }
                }

            })

            .state(AppStates.root_core_navigation_event_workspace_files, {

                url: '/files',
                onEnter: function($state) {
                    isInClientPortal() ?
                        document.title = window.clientPortalConfiguration.title
                        :
                        setHbTitle('Files', window.currentSubframe);
                },

                views: {

                    'workspace-content': {
                        templateUrl: 'angular/app/modules/core/features/workspace/files/workspace_files_template.html',
                        controller: 'WorkspaceFilesController',
                        controllerAs: 'workspaceFilesVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_event_workspace_payments, {

                url: '/payments',
                onEnter: function() {
                    isInClientPortal() ?
                        document.title = window.clientPortalConfiguration.title
                        :
                        setHbTitle('Payments', window.currentSubframe);
                },

                views: {

                    'workspace-content': {
                        templateUrl: 'angular/app/modules/core/features/workspace/payments/workspace_payments_template.html',
                        controller: 'WorkspacePaymentsController',
                        controllerAs: 'workspacePaymentsVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_event_workspace_notes, {

                url: '/notes',
                onEnter: function() {
                    if (isInClientPortal()) {
                        document.title = window.clientPortalConfiguration.title;
                    } else {
                        setHbTitle('Notes', window.currentSubframe);
                    }
                },

                views: {
                    'workspace-content': {
                        templateUrl: 'angular/app/modules/core/features/workspace/new_notes/workspace_notes_template.html',
                        controller: 'NewWorkspaceNotesController',
                        controllerAs: 'newWorkspaceNotesVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_event_workspace_details, {
                url: '/details',

                onEnter: function() {
                    isInClientPortal() ?
                        document.title = window.clientPortalConfiguration.title
                        :
                        setHbTitle('Details', window.currentSubframe);
                },

                views: {

                    'workspace-content': {
                        templateUrl: 'angular/app/modules/core/features/workspace/project_details/workspace_project_details_template.html',
                        controller: 'WorkspaceProjectDetailsController',
                        controllerAs: 'workspaceProjectDetailsVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_time_tracker, {
                url: 'time_tracker',

                data: {
                    title: 'Time Tracker',
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile,
                    hideSecondLevelNavigation: true
                },

                views: {
                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/time_tracker/time_tracker_template.html',
                        controller: 'TimeTrackerController',
                        controllerAs: 'timeTrackerCtrlVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_reports, {

                abstract: true,
                url: 'reports/',
                onEnter: function() { setHbTitle('Reports'); },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/reports/reports_template.html',
                        controller: 'ReportsController',
                        controllerAs: 'reportsVm'
                    }
                }
            })
            
            .state(AppStates.root_core_navigation_bookkeeping, {

                abstract: true,
                url: 'bookkeeping/',
                onEnter: function() { setHbTitle('Reports'); },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/reports/reports_template.html',
                        controller: 'ReportsController',
                        controllerAs: 'reportsVm'
                    }
                }
            })
            
            .state(AppStates.root_core_navigation_bookkeeping_reports, {

                abstract: true,
                url: 'reports/',
                onEnter: function() { setHbTitle('Reports'); },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/reports/reports_template.html',
                        controller: 'ReportsController',
                        controllerAs: 'reportsVm'
                    }
                }
            })
            .state(AppStates.root_core_navigation_reports_analytics, {

                url: 'analytics',

                data: {
                    title: 'Reports',
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    '': {
                        templateUrl: 'angular/app/modules/core/features/reports/analytics/reports_analytics_template.html',
                        controller: 'ReportsAnalyticsController',
                        controllerAs: 'reportsAnalyticsVm'
                    }
                },
                params: {
                    section: null,
                    disableScrollToTop: false
                }
            })
            
            .state(AppStates.root_core_navigation_bookkeeping_reports_analytics, {

                url: 'analytics',

                data: {
                    title: 'Reports',
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    '': {
                        templateUrl: 'angular/app/modules/core/features/reports/analytics/reports_analytics_template.html',
                        controller: 'ReportsAnalyticsController',
                        controllerAs: 'reportsAnalyticsVm'
                    }
                },
                params: {
                    section: null,
                    auditee_company_id: null,
                    disableScrollToTop: false
                }
            })

            .state(AppStates.root_core_navigation_reports_payments, {

                url: 'payments',

                data: {
                    title: 'Payments',
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    },

                    '': {
                        templateUrl: 'angular/app/modules/core/features/reports/payments/reports_payments_template.html',
                        controller: 'ReportsPaymentsController',
                        controllerAs: 'reportsPaymentsVm'
                    }
                },
                params: {
                    section: null,
                    disableScrollToTop: false
                }
            })
            
            .state(AppStates.root_core_navigation_bookkeeping_reports_payments, {

                url: 'payments',

                data: {
                    title: 'Payments',
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    },

                    '': {
                        templateUrl: 'angular/app/modules/core/features/reports/payments/reports_payments_template.html',
                        controller: 'ReportsPaymentsController',
                        controllerAs: 'reportsPaymentsVm'
                    }
                },
                params: {
                    section: null,
                    disableScrollToTop: false
                }
            })

            .state(AppStates.root_core_navigation_expenses, {
                url: 'expenses',

                data: {
                    title: 'Bookkeeping'
                },

                views: {


                    'center-view@root.core': {
                        templateUrl:
                        'angular/app/modules/core/features/expenses/expenses_template.html',
                        controller: 'ExpensesController',
                        controllerAs: 'expensesVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    }
                }
            })
            
            .state(AppStates.root_core_navigation_bookkeeping_expenses, {
                url: 'expenses',

                data: {
                    title: 'Bookkeeping'
                },

                views: {


                    'center-view@root.core': {
                        templateUrl:
                        'angular/app/modules/core/features/expenses/expenses_template.html',
                        controller: 'ExpensesController',
                        controllerAs: 'expensesVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_finance, {
                url: 'finance',

                data: {
                    title: 'Finance'
                },

                views: {
                    'center-view@root.core': {
                        templateUrl:
                            'angular/app/modules/core/features/finance/finance_template.html',
                        controller: 'FinanceController',
                        controllerAs: 'financeVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_finance_overview, {
                url: 'overview',

                data: {
                    title: 'Finance'
                },

                views: {
                    'center-view@root.core': {
                        templateUrl:
                            'angular/app/modules/core/features/finance/finance_overview_template.html',
                        controller: 'FinanceController',
                        controllerAs: 'financeVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_invoices, {
                url: 'invoices',

                data: {
                    title: 'Invoices'
                },

                views: {
                    'center-view@root.core': {
                        templateUrl:
                            'angular/app/modules/core/features/invoices/invoice_hub.html',
                        controller: 'InvoiceController',
                        controllerAs: 'invoiceVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_files_hub, {
                url: 'files_hub',

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="FilesHubRoot"></hb-react>'
                    }
                },

                params: {
                    tableItemType: null,
                    date: null,
                    tableType: null
                }
            })

            .state(AppStates.root_core_navigation_profit_loss, {

                url: 'profit-loss',

                data: {
                    title: 'Bookkeeping'
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    },

                    '': {
                        templateUrl: 'angular/app/modules/core/features/profit_loss/profit_loss_template.html',
                        controller: 'ProfitLossController',
                        controllerAs: 'profitLossVm'
                    }
                }
            })
            
            .state(AppStates.root_core_navigation_bookkeeping_reports_profit_loss, {

                url: 'profit-loss',

                data: {
                    title: 'Bookkeeping'
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    },

                    '': {
                        templateUrl: 'angular/app/modules/core/features/profit_loss/profit_loss_template.html',
                        controller: 'ProfitLossController',
                        controllerAs: 'profitLossVm'
                    }
                }
            })
            .state(AppStates.root_core_navigation_capital, {
                url: 'capital',
                onEnter: function() { setHbTitle('Capital'); },
                data: {
                    title: 'Capital'
                },
                views: {
                    'center-view@root.core': {
                        templateUrl:
                            'angular/app/modules/core/features/capital/capital_template.html',
                        controller: 'CapitalController',
                        controllerAs: 'capitalVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    }
                }
            })

            .state(AppStates.root_core_plaid_oauth, {
                url: 'plaid-oauth',
                views: {
                    'center-view@root.core': {
                        templateUrl:
                            'angular/app/modules/core/features/plaid_oauth/plaid_oauth_template.html',
                        controller: 'PlaidOAuthController',
                        controllerAs: 'PlaidOAuthVm'
                    }
                }
            })

            ////////////////////////////////////////////////
            // Settings
            ////////////////////////////////////////////////
            .state(AppStates.root_core_navigation_settings, {
                abstract: true,
                url: 'settings',

                data: {
                    title: 'Settings',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/settings_second_level_navigation_template.html',
                        controller: 'SettingsSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/settings/settings_template.html',
                        controller: 'SettingsController',
                        controllerAs: 'settingsVm'
                    }
                }
            })
            .state(AppStates.root_core_navigation_bookkeeping_settings, {
                abstract: true,
                url: 'settings',

                data: {
                    title: 'Settings',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/settings_second_level_navigation_template.html',
                        controller: 'SettingsSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/settings/settings_template.html',
                        controller: 'SettingsController',
                        controllerAs: 'settingsVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_settings_mobile, {
                url: '/m',

                data: {
                    title: 'Settings',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline,
                    mobileNavPrimary: true,
                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/settings/mobile/settings_mobile_template.html',
                        controller: 'SettingsMobileController',
                        controllerAs: 'settingsMobileVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_settings_account, {
                url: '/account',

                onEnter: function() {
                    isInClientPortal() ?
                        document.title = window.clientPortalConfiguration.title
                        :
                        setHbTitle('Settings', 'Account');
                },

                data: {
                    title: 'My Account',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline
                },

                templateUrl: 'angular/app/modules/core/features/settings/account/account_template.html',
                controller: 'AccountController',
                controllerAs: 'accountVm',

                params: {
                    ooo: false,
                    googleIntegrationCreated: null,
                    shouldStartTrial: false,
                    openTwoFactorAuthModal: false,
                    openAddSecurityPhoneNumberModal: false
                }
            })

            .state(AppStates.root_core_navigation_settings_company, {
                abstract: true,
                url: '/company',

                data: {
                    title: 'Company Settings',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline
                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/settings/company/company_settings_template.html',
                        controller: 'CompanySettingsController',
                        controllerAs: 'companySettingsVm'
                    }
                }
            })
            .state(AppStates.root_core_navigation_bookkeeping_settings_company, {
                abstract: true,
                url: '/company',

                data: {
                    title: 'Company Settings',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline
                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/settings/company/company_settings_template.html',
                        controller: 'CompanySettingsController',
                        controllerAs: 'companySettingsVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_settings_company_mobile, {
                url: '/m',
                data: {
                    title: 'Company',
                    backToHistoryOrState: AppStates.root_core_navigation_pipeline
                },
                templateUrl: 'angular/app/modules/core/features/settings/mobile/company_settings_mobile_template.html',
                controller: 'CompanySettingsMobileController',
                controllerAs: 'companySettingsMobileVm'
            })

            .state(AppStates.root_core_navigation_settings_company_general, {
                url: '/general',
                onEnter: function() { setHbTitle('Settings', 'General'); },
                params:{
                    isShowCompanyThemeModal: false
                },
                templateUrl: 'angular/app/modules/core/features/settings/company/general/company_settings_general_template.html',
                controller: 'CompanySettingsGeneralController',
                controllerAs: 'companySettingsGeneralVm'
            })

            .state(AppStates.root_core_navigation_settings_company_integrations, {
                url: '/integrations',
                params: {
                    googleIntegrationCreated: null,
                    integrationType: null,
                    picTimeIntegrationSucceeded: null,
                    noModalOnLoad: null
                },
                onEnter: function() { setHbTitle('Settings', 'Integrations'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/integrations/company_settings_integrations_template.html',
                controller: 'CompanySettingsIntegrationsController',
                controllerAs: 'companySettingsIntegrationsVm',
            })

            .state(AppStates.root_core_navigation_settings_company_branding, {
                url: '/branding',
                onEnter: function() { setHbTitle('Settings', 'Team'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/branding/company_settings_branding_template.html',
                controller: 'CompanySettingsBrandingController',
                controllerAs: 'companySettingsBrandingVm'
            })

            .state(AppStates.root_core_navigation_settings_company_client_portal, {
                url: '/client-portal',
                onEnter: function() { setHbTitle('Settings', 'Team'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/client_portal_template.html',
                controller: 'ClientPortalController',
                controllerAs: 'clientPortalVm'
            })

            .state(AppStates.root_core_navigation_settings_company_client_portal_preview, {
                url: "settings/company/client-portal/preview",

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/preview/client_portal_preview_template.html',
                        controller: 'ClientPortalPreview',
                        controllerAs: 'clientPortalPreviewVm'
                    }
                }
            })


            .state(AppStates.root_core_navigation_settings_company_bankInfo, {
                url: '/bank',
                onEnter: function() { setHbTitle('Settings', 'Financials'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/bank/company_settings_bank_wrapper_template.html',
            })

            .state(AppStates.root_core_navigation_settings_company_paymentMethod, {
                url: '/payment-methods',
                onEnter: function() { setHbTitle('Settings', 'Financials'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/payment_methods/company_settings_payment_methods_template.html'
            })

            .state(AppStates.root_core_navigation_settings_company_subscription, {
                url: '/subscription?openBillingModal',
                templateUrl: 'angular/app/modules/core/features/settings/company/subscription/company_settings_pricing_template.html',
                controller: 'CompanySettingsPricingController',
                controllerAs: 'pricingVm',
                params: {
                    discountCode: null,
                    promo: null,
                    picTimeIntegrationSucceeded: null,
                    noModalOnLoad: null
                },

                data: {
                    showChangedSavedIndicator: false
                }
            })

            .state(AppStates.root_core_navigation_settings_company_team, {
                url: '/team',
                onEnter: function() { setHbTitle('Settings', 'Team'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/team/company_settings_team_template.html',
                controller: 'CompanySettingsTeamController',
                controllerAs: 'companySettingsTeamVm'
            })

            .state(AppStates.root_core_navigation_settings_company_account_access, {
                url: '/account-access',
                onEnter: function () {
                    setHbTitle('Settings', 'Account Access');
                },
                templateUrl: 'angular/app/modules/core/features/settings/company/account_access/company_settings_account_access_template.html',
                controller: 'CompanySettingsAccountAccessController',
                controllerAs: 'companySettingsAccountAccessVm'
            })

            .state(AppStates.root_core_navigation_settings_company_preferences, {
                url: '/preferences?data_management',
                params: { data_management: null },
                onEnter: function() { setHbTitle('Settings', 'Preferences'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/preferences/company_settings_preferences_template.html',
                controller: 'CompanySettingsPreferencesController',
                controllerAs: 'companySettingsAutomationVm'
            })

            .state(AppStates.root_core_navigation_settings_company_ai_preferences, {
                url: '/ai_preferences',
                onEnter: function() { setHbTitle('Settings', 'AI Preferences'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/ai_preferences/company_ai_preferences_template.html',
                controller: 'CompanyAIPreferencesController',
                controllerAs: 'companyAIPreferencesVM'
            })

            .state(AppStates.root_core_navigation_settings_company_tax_information, {
                url: '/tax-info',
                onEnter: function() { setHbTitle('Settings', 'Tax'); },
                templateUrl: 'angular/app/modules/core/features/settings/company/tax-information/company_settings_tax_information_template.html',
                controller: 'TaxInfoController',
                controllerAs: 'TaxInfoVm'
            })

            .state(AppStates.root_core_navigation_settings_company_reviews, {
                url: '/reviews',
                onEnter: function() { setHbTitle('Settings', 'Reviews'); },

                data: {
                    title: 'Client Feedback',
                    backToUrl: AppStates.root_core_navigation_settings_company_preferences
                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/settings/company/reviews/client_feedback_dashboard_template.html',
                        controller: 'ClientFeedbackDashboardController',
                        controllerAs: 'clientFeedbackDashboardVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_settings_company_account_setup, {
                url: '/account-setup',
                onEnter: function() { setHbTitle('Settings', 'Account Setup Status'); },

                data: {
                    title: 'Account Setup Status',
                    backToUrl: AppStates.root_core_navigation_settings_company_preferences
                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/settings/company/account_setup/account_setup_status_template.html',
                        controller: 'AccountSetupStatusController',
                        controllerAs: '$ctrl'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    }
                }
            })

            ////////////////////////////////////////////////
            // Settings End
            ////////////////////////////////////////////////

            .state(AppStates.root_core_navigation_settings_companySettings, {
                abstract: true,

                url: '/company',

                data: {
                    title: 'Account'
                },

                views: {

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/company_settings/settings_template.html',
                        controller: 'SettingsController',
                        controllerAs: 'settingsVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_settings_companySettings_library, {
                url: '/library',
                onEnter: function() { setHbTitle('Settings', 'Library'); },

                data: {
                    title: 'Library'
                },

                views: {
                    '': {
                        templateUrl: 'angular/app/modules/core/features/company_settings/gallery/company_settings_gallery_template.html',
                        controller: 'CompanySettingsGalleryController',
                        controllerAs: 'companySettingsGalleryVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_templates, {
                url: 'templates/:templateTypeKey',

                data: {
                    title: 'Templates',
                    backToUrl: AppStates.root_core_navigation_home
                },

                params: {
                    templateTypeKey: {squash: true, value: null},
                    isOriginatedInTabClick: false,
                    pre_otam: false
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/company_settings/templates/templates_page_template.html',
                        controller: 'TemplatesPageController',
                        controllerAs: 'templatesPageVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/templates_second_level_navigation_template.html',
                        controller: 'TemplatesSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavTemplatesVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_my_templates, {
                url: 'my-templates',

                data: {
                  title: 'Templates',
                  backToUrl: AppStates.root_core_navigation_home
                },


                params: {
                    redirectToFolderId: null,
                    redirectToPanel: null,
                    isOriginatedInTabClick: false
                },

                views: {
                  'center-view@root.core': {
                      templateUrl: 'angular/app/modules/core/features/company_settings/templates/my_templates.html',
                      controller: 'MyTemplatesController',
                      controllerAs: 'myTemplatesVm'
                  },

                  'second-level-navigation@root.core': {
                      templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/templates_second_level_navigation_template.html',
                      controller: 'TemplatesSecondLevelNavigationController',
                      controllerAs: 'secondLevelNavTemplatesVm'
                  }
                }
            })


            .state(AppStates.root_core_navigation_my_templates_preview, {
                url: '?preview-shared=',
                data: {
                    title: 'My Templates',
                    backToUrl: AppStates.root_core_navigation_home
                }
    })

            .state(AppStates.root_core_navigation_flows_template_gallery, {
              url: 'template-gallery',

              data: {
                  title: 'Templates',
                  backToUrl: AppStates.root_core_navigation_home
              },
                params: {
                    isOriginatedInTabClick: false,
                    preview: null,
                    search: null,
                    filters: null,
                    industryFilters: null,
                    hardFiltering: null
                },

              views: {

                  'center-view@root.core': {
                      templateUrl: 'angular/app/modules/core/features/flows_template_gallery/flows_template_gallery.html',
                      controller: 'FlowsTemplateGalleryController',
                      controllerAs: 'flowsGalleryVm'
                  },

                  'second-level-navigation@root.core': {
                      templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/templates_second_level_navigation_template.html',
                      controller: 'TemplatesSecondLevelNavigationController',
                      controllerAs: 'secondLevelNavTemplatesVm'
                  }
              }
            })

            .state(AppStates.root_core_navigation_tasks, {
                url: 'tasks',

                data: {
                    title: 'Task Management',
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/tasks/tasks_mgmt_template.html',
                        controller: 'TasksMgmtController',
                        controllerAs: 'tasksMgmtVm'
                    }
                },
                params: {
                    filter: null
                }
            })

            .state(AppStates.root_core_navigation_facebook_leads, {
                url: 'facebook_leads',

                data: {
                    title: 'Facebook/Instagram Leads',
                    backToHistoryOrState: AppStates.root_core_navigation_tools_mobile
                },

                views: {
                    'center-view@root.core': {
                        templateUrl:'angular/app/modules/core/features/facebook_leads/facebook_leads_template.html',
                        controller: 'FacebookLeadsController',
                        controllerAs: 'facebookLeadsVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    }
                },
                params: {
                    filter: null
                }
            })

            .state(AppStates.root_core_navigation_templatesEditor, {
                url: 'templates_editor/:templateModelKey/:templateId',

                params: {
                    isDrawerOpen: false,
                    shouldShowEmptyState: false
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/company_settings/templates/full_page_editor/templates_full_page_editor_template.html',
                        controller: 'TemplatesFullPageEditorController',
                        controllerAs: 'templatesFullPageEditorVm'
                    },

                    'first-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/company_settings/templates/full_page_editor/templates_first_level_nav_template.html',
                        controller: 'TemplatesFirstLevelNavController',
                        controllerAs: 'templatesTopNavVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_service_templates, {
                url: 'services',

                params: {},

                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ServiceTemplatesRoot"></hb-react>'
                    }
                }
            })

            .state(AppStates.root_core_oneComposer, {
                url: 'templates_editor/email/:templateId',

                data: {
                    noNavigation: true
                },	                

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/one_composer_template_editor/one_composer_template_editor_template.html',
                    }
                }
                
            })

            .state(AppStates.root_core_import_template, {
                url: 'import-template-loader',

                data: {
                    noNavigation: true
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/import_template_loader/import_template_loader_template.html',
                    }
                }

            })

            .state(AppStates.root_core_import_template_redeem_error, {
                url: 'import-template-redeem-error?originalTemplateId&redeemCode&isGalleryTemplate',

                data: {
                    noNavigation: true
                },

                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/template_redeem_error/template_redeem_error.html',
                    }
                },
                params: {
                    originalTemplateId: null,
                    redeemCode: null,
                    isGalleryTemplate: null
                }

            })

            .state(AppStates.root_core_navigation_settings_companySettings_automation, {

                url: '/automation',
                onEnter: function() { setHbTitle('Settings', 'Automation'); },

                data: {
                    title: 'Automation'
                },

                views: {

                    '': {
                        templateUrl: 'angular/app/modules/core/features/settings/company/preferences/company_settings_preferences_template.html',
                        controller: 'CompanySettingsPreferencesController',
                        controllerAs: 'companySettingsAutomationVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/title_second_level_navigation_template.html',
                        controller: 'TitleSecondLevelNavigationController',
                        controllerAs: 'secondLevelNavVm'
                    },
                }
            })

            .state(AppStates.root_core_navigation_settings_companySettings_quickbooks, {

                url: '/quickbooks',

                data: {
                    title: 'QuickBooks'
                },

                views: {
                    '': {
                        templateUrl: 'angular/app/modules/core/features/company_settings/quickbooks/company_settings_quickbooks_template.html',
                        controller: 'CompanySettingsQuickbooksController',
                        controllerAs: 'companySettingsQuickbooksVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_bookkeeping_settings_company_quickbooks, {

                url: '/quickbooks',

                data: {
                    title: 'QuickBooks'
                },

                views: {
                    '': {
                        templateUrl: 'angular/app/modules/core/features/company_settings/quickbooks/company_settings_quickbooks_template.html',
                        controller: 'CompanySettingsQuickbooksController',
                        controllerAs: 'companySettingsQuickbooksVm'
                    },

                    'second-level-navigation@root.core': {
                        templateUrl: 'angular/app/modules/core/features/top_nav/second_level_navigation/second_level_navigation_bookkeeping_template.html',
                        controller: 'SecondLevelNavigationBookkeepingController',
                        controllerAs: 'secondLevelNavBookkeepingVm'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFileRedirection, {
                url: 'workspace_file/:workspace_file_id/redirect?preview',

                views: {
                    'center-view': {
                        controller: 'WorkspaceFileRedirectionController'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFilePaymentRedirection, {
                url: 'workspace_file/:workspace_file_id/:payment_id/redirect',

                views: {
                    'center-view': {
                        controller: 'WorkspaceFileRedirectionController'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFile, {

                url: 'workspace_file/:workspace_file_id?file_version&preview',

                params: {force: false, preventStateChangeModals: false},

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/workspace_file_template.html',
                        controller: 'WorkspaceFileController',
                        controllerAs: 'workspaceFileVm'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFile_stepProposal, {

                url: 'workspace_file/:workspace_file_id/package',
                params: {
                    preventStateChangeModals: false,
                    showClientPreviewModal: false,
                    templateOriginId: '',
                    templateOriginModelKey: '',
                },

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/proposal/workspace_file_step_proposal_template.html',
                        controller: 'WorkspaceFileStepProposalController',
                        controllerAs: 'workspaceFileStepProposalVm'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFile_stepQuestionnaire, {

                url: 'workspace_file/:workspace_file_id/questionnaire',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/questionnaire/workspace_file_step_questionnaire_template.html',
                        controller: 'WorkspaceFileStepQuestionnaireController',
                        controllerAs: 'workspaceFileStepQuestionnaireVm'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFile_brochure, {

                url: 'workspace_file/:workspace_file_id/brochure',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/brochure/workspace_file_brochure_template.html',
                        controller: 'WorkspaceFileBrochureController',
                        controllerAs: 'workspaceFileBrochureVm'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFile_timeline, {

                url: 'workspace_file/:workspace_file_id/timeline',

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/timeline/workspace_file_timeline_template.html',
                        controller: 'WorkspaceFileTimelineController',
                        controllerAs: 'workspaceFileTimelineVm'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFile_stepAgreement, {

                url: 'workspace_file/:workspace_file_id/agreement',
                params: {preventStateChangeModals: false},
                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/agreement/workspace_file_step_agreement_template.html',
                        controller: 'WorkspaceFileStepAgreementController',
                        controllerAs: 'workspaceFileStepAgreementVm'
                    }
                }
            })

            .state(AppStates.root_core_workspaceFile_stepPayment, {

                url: 'workspace_file/:workspace_file_id/payment/:payment_id',
                params: {preventStateChangeModals: false, editCardMode: false, isOnRedirect: false},
                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/payment/workspace_file_step_payment_template.html',
                        controller: 'WorkspaceFileStepPaymentController',
                        controllerAs: 'workspaceFileStepPaymentVm'
                    }
                }
            })

            .state(AppStates.root_core_questionnaireSubmitted, {

                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/questionnaire/submitted/submitted_template.html',
                        controller: 'SubmittedController',
                        controllerAs: 'submittedVm'
                    }
                }
            })

            .state(AppStates.root_core_receipt, {
                params: {isLoggedInAsClient: false},
                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/workspace_file/payment/receipt/receipt_template.html',
                        controller: 'ReceiptController',
                        controllerAs: 'receiptVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_brands, {
                url: 'brands',

                data: {
                    title: 'Company brands',
                    showMinimalNav: true,
                    showPlusButton: true
                },

                views: {

                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/brands/brands_template.html',
                        controller: 'BrandsController',
                        controllerAs: 'brandsVm'
                    }

                }
            })

            .state(AppStates.root_core_pay, {

                url: "pay?hb_token",
                views: {
                    'center-view': {
                        templateUrl: 'angular/app/modules/core/features/pay/pay_template.html',
                        controller: 'PayController',
                        controllerAs: 'payVm'
                    }
                }
            })

            .state(AppStates.root_core_navigation_lead_capture, {
                url: "lead_capture?template",
                data: {},
                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/lead_capture/lead_capture_template.html'
                    }
                }
            })

            .state(AppStates.root_core_navigation_gallery_dashboard, {
                url: "configure/flow_gallery",
                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/gallery_dashboard/gallery_dashboard_template.html'
                    }
                }
            })

            .state(AppStates.root_core_lifecycle_marketing_link, {
                url: "lifecycle_marketing_link/:link_type",
                views: {
                    'center-view@root.core': {
                        templateUrl: 'angular/app/modules/core/features/lifecycle_marketing_links/lifecycle_marketing_links_template.html'
                    }
                }
            })
            .state(AppStates.root_core_navigation_process_mapping, {
                url: "my-process",
                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ProcessMappingRoot"></hb-react>'
                    }
                }
            })
            .state(AppStates.root_core_process_mapping_editor, {
                url: "my-process/:process_id",
                views: {
                    'center-view@root.core': {
                        template: '<hb-react component="ProcessMappingRoot"></hb-react>'
                    }
                },
                data: {
                    noNavigation: true
                }
            });

        $animateProvider.classNameFilter(/angular-animate/);
    }
);


// @ngInject
//Verify user logged in when changing states
core_module.run(function (
    $rootScope, $log, $window, $location, Enums, DeviceService,
    UsersManager, EventsManager, _,
    APIService, RedirectService, WorkspaceFileService, OnboardingService, NetworkOnboardingService,
    AppStates, Gon, AppConfigService, $state, EventService, HomeService, PretrialersService, HelpService,
    InitialAppLoadParamsService, FlowService, $urlRouter, ClientPortalService, UserService, TemplatesImportService,
    AnalyticsService, CompanyService, Constants, ngInReactService) {

        console.debug("[DEBUG] core_module.run start");

        FlowService.registerAppEvents();
        TemplatesImportService.init();

        // todo yona:
        // 1. what log do we want ot use?
        // 2. why don't we get tot the error statie?
        // 3. where do we want the boolean?

        $rootScope.debugStateMode = true;

        $rootScope.$on('$stateChangeNotFound',
            function (event, toState, toParams, fromState, fromParams) {
                if ($rootScope.debugStateMode) {
                    $log.debug("##[ STATE NOT FOUND: " + fromState.name + " -> " + toState.name + " ]##");
                    $log.debug(event);
                }
            });

        $rootScope.$on('$stateChangeSuccess',
            function (event, toState, toParams, fromState, fromParams) {
                HelpService.onStateChange(fromState, toState);

                if ($rootScope.debugStateMode || DeviceService.isInAppBrowser()) {
                    $log.debug("##[ STATE SUCCESS: " + toState.name + " ]##");
                }

                // Relood the page on the next route change if we need to
                if (AppConfigService.doesNeedsRefresh()) {
                    // Setting the next route in the href so a full reload will happen
                    window.location.href = "/app" + $location.url();
                }
            });

        $rootScope.$on('$stateChangeError',
            function (event, toState, toParams, fromState, fromParams) {
                if ($rootScope.debugStateMode) {
                    $log.debug("##[ STATE ERROR: " + fromState.name + " -> " + toState.name + " ]##");
                    $log.debug(event);
                }
            });

        var unauthenticatedStatesArray = [
            AppStates.root_core_login,
            AppStates.root_core_community,
            AppStates.root_core_signup,
            AppStates.root_core_clientLogin,
            AppStates.root_core_linkExpired,
            AppStates.root_core_resolveLink,
            AppStates.root_core_setPassword,
            AppStates.root_core_resetPassword,
            AppStates.root_core_resetPasswordForFile,
            AppStates.root_core_resetPasswordForWorkspace,
            AppStates.root_core_resetPasswordForEvent,
            AppStates.root_core_resetPasswordForNetwork,
            AppStates.root_core_resetPasswordForNetworkProject,
            AppStates.root_core_resetPasswordForNetworkProfile,
            AppStates.root_core_pay
        ];

        var dontGoBackToTheseStates = [
            AppStates.root_core_onboardingMobileQuiz,
            AppStates.root_core_onboardingQuiz,
            AppStates.root_core_accountSetup,
            AppStates.root_core_googleOAuth2Callback,
            AppStates.root_core_workspaceFileRedirection,
            AppStates.root_core_zoomOAuth2Callback,
            AppStates.root_core_quickbooksOAuth2Callback,
            AppStates.root_core_nylasOAuth2Callback,
            AppStates.root_core_calendlyOAuth2Callback,
            AppStates.root_core_lifecycle_marketing_link
        ];

        var vendorOnlyStateNames = [
            AppStates.root_core_navigation_settings_companySettings,
            AppStates.root_core_navigation_settings_companySettings_new_general,
            AppStates.root_core_navigation_pipeline,
            AppStates.root_core_navigation_home,
            AppStates.root_core_navigation_settings_company,
            AppStates.root_core_navigation_settings_mobile,
            AppStates.root_core_navigation_settings_company_integrations,
            AppStates.root_core_navigation_settings_company_general,
            AppStates.root_core_navigation_settings_company_branding,
        ];

        var permissionsLockedStateNames = {};
        permissionsLockedStateNames[AppStates.root_core_navigation_expenses] = CompanyService.superAdminUser;
        permissionsLockedStateNames[AppStates.root_core_navigation_profit_loss] = CompanyService.superAdminUser;

        APIService.on(Gon.hb_base_error_codes.under_construction_error, function() {
            $window.location.href = $window.location.origin + '/construction';
        });

        /////////////////
        //State decisions
        /////////////////


        var goToUserDefaultState = function goToUserDefaultState(event, currUser) {
            event.preventDefault();

            if (currUser.isClient()) {
                $rootScope.$state.go(AppStates.root_core_navigation_client);
            } else if (InitialAppLoadParamsService.getParam('utm_source') === 'johnbranch') {
                localStorage.setItem(Constants.storage.IMPORT_JB_TEMPLATES, 1);
                $rootScope.$state.go(AppStates.root_core_navigation_my_templates);
            } else if (InitialAppLoadParamsService.getParam('utm_source') === 'freelancersunion') {
                localStorage.setItem(Constants.storage.IMPORT_FU_TEMPLATE, true);
            } else if (OnboardingService.isOnboardingActive() && !currUser.hasTrialExpired()) { // onboarding flow
                var params = {};
                var currentStepRoute = OnboardingService.getCurrentStepRoute();
                $rootScope.$state.go(currentStepRoute, params);
            } else if (currUser.shouldStartTrial()) { // pre-trialer vendor
                // vendor routing base on source
                if (PretrialersService.shouldShowCleanAtwsUI()){
                    PretrialersService.gotoLastWorkspace();
                } else if (currUser.isBookkeeper()){
                    $rootScope.$state.go(AppStates.root_core_navigation_bookkeeping_reports_analytics);
                } else {
                    $rootScope.$state.go(AppStates.root_core_navigation_pipeline);
                }
            }else if (currUser.hasTrialExpired() && !currUser.companyHasSubscription()){ // trial expired so only community signups will be directed to community
                    HomeService.routeToPipelineOrHome();
            } else{
                // all the rest - subscribers, trials and old users
                if (UsersManager.shouldRedirect){
                    $rootScope.$state.go(UsersManager.shouldRedirect.state, UsersManager.shouldRedirect.stateParams);
                }else{
                    HomeService.routeToPipelineOrHome();
                }
            }
        };

        var isLoginlessState = function isLoginlessState() {
            var link_id = InitialAppLoadParamsService.getParam('link_id');
            var link_token = InitialAppLoadParamsService.getParam('link_token');
            return link_id && link_token;
        };

        var backStack = [];

        function setBackwardsState(currState) {
            backStack.pop();
            if (backStack.length) {
                $state.previous = backStack[backStack.length -1];
            } else {
                $state.previous = null;
            }
        }

        // Scroll to top when routing to new page
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, options) {
            if (!toParams.disableScrollToTop) {
                $("html, body").animate({ scrollTop: 0 }, 200);
            }

            if ($state.previous && _.isEqual($state.previous.route, toState) && _.isEqual($state.previous.routeParams, toParams))  {
                // going backwards
                setBackwardsState(fromState)
            } else {
                options = options || {};

                if(fromState.name && !_.contains(unauthenticatedStatesArray, fromState.name) && !_.contains(dontGoBackToTheseStates, fromState.name) && (options.location !== 'replace') && UsersManager.isLoggedIn()){
                    $state.previous = { route: fromState, routeParams: fromParams };
                    backStack.push($state.previous);
                } else if (_.contains(dontGoBackToTheseStates, fromState.name) || _.contains(unauthenticatedStatesArray, fromState.name) || !UsersManager.isLoggedIn()) {
                    $state.previous = null;
                }
            }

        });

        $rootScope.$on('$locationChangeSuccess', function(_, newUrl, currentUrl) {
            if (ngInReactService.isInIframe && newUrl !== currentUrl) {
                ngInReactService.sendUrlChange(newUrl.replace(location.origin + '/app', ''))
            }
        });

        $rootScope.$on('$stateChangeStart',
            function (event, toState, toParams, fromState, fromParams) {                
                var currUser = UsersManager.getCurrUser();

                if ($rootScope.debugStateMode) {
                    $log.debug("##[ STATE CHANGE: " + fromState.name + " -> " + toState.name + " ]##");
                }

                var isMalkutHost = AppConfigService.isMalkutServer();
                //dont let a malkut user jump into the admin or regular user and vise versa.
                if (currUser && UsersManager.isLoggedIn()) {
                    if (Gon.environment === 'production') {
                        if ((!currUser.isMalkut() && isMalkutHost) || (currUser.isMalkut() && !isMalkutHost && !currUser.isAdmin() )) {
                            UsersManager.getMalkutUrl().then(
                                function success(resp) {
                                    UsersManager.logout().then(function afterLogin() {
                                        $window.location.href = resp.data.url;
                                    }.bind(this));
                                }
                            );
                        }
                    }

                    if (toState.name.indexOf(AppStates.ng_in_react) !== -1) {
                        $log.debug('## NGINREACT STATE CHANGE: ' + fromState.name + ' -> ' + toState.name + ' ##');
                        return toState.name;
                    }

                    if(ClientPortalService.isInClientPortal() && currUser.isVendor()) {
                        UserService.redirectToServerDomain();
                    }

                    if (currUser.isMalkut() && toState.name.indexOf(AppStates.root_malkut) === -1 && (isMalkutHost || Gon.environment !== 'production')) {
                        if (!(Gon.environment !== 'production' && currUser.isAdmin() && toState.name.indexOf(AppStates.root_core_admin_login) !== -1)) {
                            event.preventDefault();
                            $rootScope.$state.go(AppStates.root_malkut_cs);
                        }

                    }
                    
                    else if (!currUser.isMalkut() && toState.name.indexOf(AppStates.root_malkut) !== -1) {
                        goToUserDefaultState(event, currUser);

                    } else if (currUser.isClient() && vendorOnlyStateNames.some(function(vendorOnlyStateName) {return toState.name.indexOf(vendorOnlyStateName) === 0})) {
                        goToUserDefaultState(event, currUser);
                    } else {
                        try {
                            var permissionLevel = permissionsLockedStateNames[toState.name];
                            if (permissionLevel && !CompanyService.hasPermissionsOrHigher(currUser.company, currUser, permissionLevel)) {
                                goToUserDefaultState(event, currUser);
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }

                // community login redirect
                var currentUrl = window.location.href;
                if ((currentUrl.includes('/app/login') && currentUrl.includes('community')) ||
                    currentUrl.includes('community/login')) {
                    var state = AppStates.root_core_community_redirect;
                    var params = { community: true };
                    RedirectService.setToState(state, params);
                    $rootScope.$state.go(state, params);
                } else if (toState.name === AppStates.root_core_admin_login) {

                    if (!UsersManager.isLoggedIn() || !currUser) {
                        event.preventDefault();
                        $rootScope.$state.go(AppStates.root_core_login);

                    } else if (!currUser.isAdmin()) {
                        goToUserDefaultState(event, currUser);
                    }

                } else if (currUser && currUser.isAdmin() && !isMalkutHost){

                    if (!(Gon.environment !== 'production' && currUser.isMalkut() && (toState.name.indexOf(AppStates.root_malkut) !== -1)))
                    {
                        console.log('preventDefault for: ' + toState.name + ' is malkut user: ' + currUser.isMalkut());
                        event.preventDefault();
                        $rootScope.$state.go(AppStates.root_core_admin_login);
                    }

                } else if ($.inArray(toState.name, unauthenticatedStatesArray) === -1 && !UsersManager.isLoggedIn()) {
                    event.preventDefault();
                    if(isLoginlessState()) {
                        var link_id = InitialAppLoadParamsService.getParam('link_id');
                        var link_token = InitialAppLoadParamsService.getParam('link_token');

                        params = { link_id: link_id, link_token: link_token, state: toState.name, stateParams: toParams};
                        $rootScope.$state.go(AppStates.root_core_resolveLink, params);
                    } else{
                        UsersManager.logout();

                        var queryParams = $location.search();
                        if (queryParams) {
                            toParams = toParams || {};
                            angular.extend(toParams, queryParams);
                        }

                        RedirectService.setToState(toState, toParams);
                        $rootScope.$state.go(AppStates.root_core_login);
                    }
                    return;

                } else if (toState.name === AppStates.root_core_login && UsersManager.isLoggedIn()) {
                    goToUserDefaultState(event, currUser);
                } else if (toState.name === AppStates.root_core_signup && UsersManager.isLoggedIn()) {
                    goToUserDefaultState(event, currUser);
                }

                if (toState.name === AppStates.root_core_navigation_dashboard) {
                    goToUserDefaultState(event, currUser);
                }

                if (toState.name === AppStates.root_core_workspaceFile) {

                    // if we got here from a refresh, we want to check the state, and decide where to go
                    if (!fromState.name || fromState.name === AppStates.root_core_login) {

                        // if this is the correct state, we should have force =  true.
                        if (!toParams.force) {

                            event.preventDefault();
                            WorkspaceFileService.gotoWorkspaceFileById(toParams.workspace_file_id, toParams.preview === 'true', {}, true);
                        }

                    }

                } else if (toState.name === AppStates.root_core_navigation_settings_companySettings_bankInfo && (!currUser || !currUser.is_company_owner)) {
                    event.preventDefault();
                    $rootScope.$state.go(AppStates.root_core_navigation_settings_companySettings_overview);
                } else if (toState.name === AppStates.root_core_navigation_settings_company_subscription && (!currUser || !currUser.isAccountOwner())) {
                    event.preventDefault();
                    $rootScope.$state.go(AppStates.root_core_navigation_settings_companySettings_overview);
                }


                if(toState.name === AppStates.root_core_native) {
                    return AppStates.root_core_native;
                }
                 else if (toState.name === AppStates.root_core_navigation_event_overview){
                    event.preventDefault();
                    var eventId = toParams.event_id;
                    var hbEvent = EventsManager.getEventFromCache(eventId);

                    function gotoFirstWorkspace(hbEvent) {
                        //navigate to the right place according to the persistency
                        var stateToGoTo = EventService.getEventDefaultState(hbEvent, true);
                        $rootScope.$state.go(stateToGoTo.name, stateToGoTo.params);
                    }

                    if (hbEvent && hbEvent.workspaces &&hbEvent.workspaces.length) {
                        gotoFirstWorkspace(hbEvent);
                        return;
                    }

                    EventsManager.getEvent(eventId, true, true)
                        .then(function success(project) {
                            gotoFirstWorkspace(project);
                        }.bind(this))
                        .catch(function fail(error) {
                            // fallback
                            goToUserDefaultState(event, currUser);
                        }.bind(this))
                        .finally(function finallyHandler() {
                        }.bind(this));
                }

            });

        console.debug("[DEBUG] core_module.run end");

    }
);
