(function () {
    "use strict";

    // @ngInject
    function HomeControllerCtor($scope, $injector, $stateParams, HomeManager, TasksManager, WorkspacesManager, ReportsManager, UsersManager, _, AppStates, NavigationService, WorkspaceFileService, $translate,
                                $filter, moment, HomeService, $q, ModalService, ReactModalService, AnalyticsService, ReferralService, SetupGuideService, UiPersistenceService, Enums, DeviceService, AccountsService, $state, IntentService,
                                OnboardingService, UserService, SelfOnboardingManager, EventsManager, FeaturesService, $window, FlowService, TemplatesViewService, OtamStatusService, $timeout, FlowsBetaUserTypeService, Routes,
                                FinanceAppService, AppConfigService, StatsigService, FullStoryService, ToastService, DatadogRUMService, IntercomService, Gon) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'HomeController';

        this.AccountsService = AccountsService;
        this.Gon = Gon;
        this.IntentService = IntentService;
        this.AppStates = AppStates;
        this.HomeManager = HomeManager;
        this.TasksManager = TasksManager;
        this.WorkspacesManager = WorkspacesManager;
        this.ReportsManager = ReportsManager;
        this._ = _;
        this.$filter = $filter;
        this.$stateParams = $stateParams;
        this.moment = moment;
        this.HomeService = HomeService;
        this.$q = $q;
        this.$window = $window;
        this.AnalyticsService = AnalyticsService;
        this.ReferralService = ReferralService;
        this.$scope = $scope;
        this.ModalService = ModalService;
        this.ReactModalService = ReactModalService;
        this.UiPersistenceService = UiPersistenceService;
        this.Enums = Enums;
        this.SetupGuideService = SetupGuideService;
        this.DeviceService = DeviceService;
        this.NavigationService = NavigationService;
        this.OnboardingService = OnboardingService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.UsersManager = UsersManager;
        this.$translate = $translate;
        this.UserService = UserService;
        this.SelfOnboardingManager = SelfOnboardingManager;
        this.EventsManager = EventsManager;
        this.FeaturesService = FeaturesService;
        this.FlowService = FlowService;
        this.TemplatesViewService = TemplatesViewService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.Routes = Routes;
        this.StatsigService = StatsigService;
        this.FullStoryService = FullStoryService;
        this.FinanceAppService = FinanceAppService;
        this.AppConfigService = AppConfigService;
        this.shouldShowTaxMismatchBanner = false;
        this.shouldShowForm1099DownloadBanner = false;
        this.ToastService = ToastService;
        this.isMobileDevice = DeviceService.nxSmallBreakpoint();
        this.DatadogRUMService = DatadogRUMService;
        this.IntercomService = IntercomService;

        this.isLoading = true;

        this.mwebDesktopBannerClosed = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.MwebBannerGoToDesktop, {}).isHide || false;

        this.user = UsersManager.getCurrUser();
        this.activeUser =  this.user.is_activated;
        this.INQUIRIES_TO_SHOW = 2;
        this.MAX_CF_AWARENESS_SHOWS = 3;

        this.isShowFlowsOnboarding = this.FlowsBetaUserTypeService.hasOnlyFlows;
        this.homePaymentsCardVariant = this.user.getExistingAbTestVariation('home_payments_poc');

        this.isHomeForTrialer = this.HomeService.shouldShowHomeForTrialers();
        
        this.onCloseHomeBanner = this.onCloseHomeBanner.bind(this);


        this.checkShouldPresentTINResolveModal();
        this.fetchShouldShowForm1099DownloadBanner().then(result => {
            this.shouldShowForm1099DownloadBanner = result;
        });

        this.wasInvoiceSent = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.wasInvoiceSent, { value: false }).value;
        const ownerSentInvoice = (!this.wasInvoiceSent && this.user.hasCompanySuperAdminPermissions() && !this.user.is_company_owner) ? this.UiPersistenceService.getOwnerUiPersistence(this.UiPersistenceService.keys.wasInvoiceSent, { value: false }).value : this.wasInvoiceSent;
        this.showBigPaymentsCard =  (this.homePaymentsCardVariant && (this.homePaymentsCardVariant !== 'control_trialer_home' || (this.homePaymentsCardVariant === 'control_trialer_home' && this.wasInvoiceSent))) ||
                                    this.FlowsBetaUserTypeService.hasFiles || this.AppConfigService.isE2E() || ownerSentInvoice;

        const emptyActivePaymentsCardPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.emptyActivePaymentsCard, 'showActivePaymentsCard', false);

        // Who should get the big card: Anyone with any variant of the old test except from control, only admins, all legacy users,
        emptyActivePaymentsCardPromise
            .then(function success(variant) {
                if (!this.showBigPaymentsCard) {
                    const showSmallPaymentsCard = !this.user.hasCompanySuperAdminPermissions() || (!variant && !this.wasInvoiceSent);
                    this.showBigPaymentsCard = !showSmallPaymentsCard;
                }
            }.bind(this)).catch(function(e) {
            this.DatadogRUMService.addError(new Error('Failed to get emptyActivePaymentsCard statsig variant', {cause: e}), {user_id: this.user._id});
            this.showBigPaymentsCard = true;
        }.bind(this)).finally(function (){
            if (this.showBigPaymentsCard){
                this.FullStoryService.trackEvent("show big payments card in home", {
                    wasInvoiceSent: this.wasInvoiceSent,
                    action: "loaded"
                });
            }
        }.bind(this));

        this.getOnboardingData();

        if (this.user.isCompanyOwner()) {
            this._loadOnboardingContent();
        }

        const yearLastSawBirthdayModal = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.yearLastSawBirthdayModal, {year: ''}).year;
        const todayIsBirthday = this.UserService.isTodayUsersBirthday(this.user);
        const userHasIndividualBankAccountType = this.UserService.isBankAccountTypeIndividual(this.user);
        const thisYear = moment().format('YYYY');
        const haveNotSeenBirthdayModalThisYear = yearLastSawBirthdayModal !== thisYear;

        const urlParams = new URLSearchParams(this.$window.location.search);
        const myParam = urlParams.get('pre_otam');
        if(myParam === 'inProduct' || this.$stateParams.pre_otam === 'inProduct') {
            this.ReactModalService.openTemplatesForLegacyUsersModal();
        }

        if (
            todayIsBirthday
            && userHasIndividualBankAccountType
            && haveNotSeenBirthdayModalThisYear
            && !this.isMobileDevice
        ) {
            this.ModalService.openBirthdayModal();
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.yearLastSawBirthdayModal, {year: thisYear});
            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.birthday_modal);
        }

        UserService.showGmailRevokedPopup(this.user);

        this.OtamStatusService = OtamStatusService;
        this.OtamStatusService.initializeAfterLogin();
        this.otamGifSrc = "//res.cloudinary.com/honeybook/image/upload/fl_lossy/v1643730536/honeybook_cdn/otam/home_page_banner/home_page_animation.gif";
        this.otamTeamMemberSrc = "//res.cloudinary.com/honeybook/image/upload/v1659536444/honeybook_cdn/otam/home_page_banner/otam_banner_team_members.svg";
        $timeout(function () {
            this.changeBannerImg = true;
            this.otamGifSrc = "//res.cloudinary.com/honeybook/image/upload/v1644998224/honeybook_cdn/otam/home_page_banner/static_banner_gif.svg";
        }.bind(this), 18500);

        this.isIntentOnboardingCompleted = this.UiPersistenceService.getUiPersistence(
            this.UiPersistenceService.keys.intentOnboardingIsCompleted, { value: false }
        ).value;

        $scope.$watch(()=> {
            return this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.MwebBannerGoToDesktop, {}).isHide || false;
        }, (newVal, oldVal) => {
            if (newVal && newVal !== oldVal) {
                this.mwebDesktopBannerClosed = true;
            }
        });

        const tourVisits = this.UiPersistenceService.getUiPersistence(
            this.UiPersistenceService.keys.tourVisits, {}
        );

        const hasSeenTeamMembersModal = this.UiPersistenceService.getUiPersistence(
            this.UiPersistenceService.keys.hasSeenTeamMembersSplashModal, { value: false }
        ).value;

        const hasSeenBuilderTour = tourVisits && !!tourVisits['ONBOARDING_BUILD_TAB'];

        const shouldShowTeamMemberOtamModal = this.OtamStatusService.isMigrated() && this.OtamStatusService.isTeamMember() && !hasSeenBuilderTour && !hasSeenTeamMembersModal;
        if (shouldShowTeamMemberOtamModal) {
            this.ModalService.openTeamMembersOtamModal('splash');
            tourVisits['TEAM_MEMBERS_OTAM_MODAL'] = true;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.hasSeenTeamMembersSplashModal, { value: true });
        }
        if (this.$stateParams.picTimeIntegrationSucceeded === 'true') {
            this.HomeManager.getIsPicTimeCouponEligible(this.user.company.account_id).then(function(resp) {
                this.ReactModalService.openPicTimeSuccessModal(resp.data.eligible, resp.data.source);
            }.bind(this));
        }
        if (this.$stateParams.displayPicTimeWelcomeModal === true) {
            this.ReactModalService.openPicTimeWelcomeModal();
        }
        if (this.$stateParams.openCreateClientModal) {
            this.ReactModalService.openCreateClientModal();
        }
        if (this.$stateParams.openUploadPDFModal) {
            this.ReactModalService.openUploadPDFModal();
        }

        const shouldShowOneHomePromise = this.HomeService.getShouldShowOneHome();

        shouldShowOneHomePromise.then(function(showNewHome) {
            this.isShowOneHome = showNewHome;
            if (!showNewHome) {
                this.applyOldHomeContent();
            }
        }.bind(this)).finally(function() {
            this.isLoading = false;
        }.bind(this));


        Promise.all([shouldShowOneHomePromise, emptyActivePaymentsCardPromise]).finally(function(results) {
            this.AnalyticsService.trackPageView(this, this.AnalyticsService.analytics_events.home_page,
                {
                    home_poc_abtest_variant: this.homePaymentsCardVariant,
                    is_big_card: this.showBigPaymentsCard,
                    was_invoice_sent: this.wasInvoiceSent,
                    screen_is_fade: !!this.isFadeActive(),
                    is_one_home: this.isShowOneHome,
                    is_new_header_delight: this.isShowOneHome
                });
        }.bind(this));

        this.isLeadFormsScheduleIntentOnboarding =  false;

        const isShowSchedulerIntentOnboarding = this.IntentService.shouldGetSchedulerIntentOnboarding();
        if (isShowSchedulerIntentOnboarding) {
            this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.leadFormsScheduleIntentOnboarding,  'lead_forms_schedule_onboarding_enabled', false)
                .then(function (result) {
                    this.isLeadFormsScheduleIntentOnboarding = result;
                }.bind(this));

        }

        this.isInvoiceServiceTemplatesIntentOnboarding = false;

        const iioServiceTemplatesOldVaraintPromise = this.StatsigService.getExperimentConfigValue(
            this.Enums.StatsigExperiments.servicesHubPageForConsultants,
            'show_page',
            false
        );
        const iioServiceTemplatesNewVaraintPromise = this.StatsigService.getExperimentConfigValue('iio_services_all', 'is_enabled', false);
        
        Promise.all([iioServiceTemplatesOldVaraintPromise, iioServiceTemplatesNewVaraintPromise])
            .then(function ([isOldEnabled, isNewEnabled]) {
                this.isInvoiceServiceTemplatesIntentOnboarding = 
                    isOldEnabled ||
                    (this.user.isConsultantTrialStartedAfterServicesHubRolloutDate() && !this.user.isConsultantTrialStartedAfterNewIIOServiceTemplatesABTestDate()) ||
                    isNewEnabled;
            }.bind(this))
            .catch(function (e) {
                this.DatadogRUMService.addError(new Error('Failed to get iio service templates statsig variant', {cause: e}), {user_id: this.user._id});
            }.bind(this));
    }


    Controllers.HomeController = Class(Controllers.BaseController, {
        constructor: HomeControllerCtor,

        onCloseHomeBanner: function onCloseHomeBanner() {
            this.HomeService.setVisibilityHomeBanner(false);
        },

        getOnboardingData() {
            this.onboardingType = this.OnboardingService.getOnboardingType();
            this.onboardingAbTest = this.OnboardingService.getOnboardingAbTest();
        },

        _hideCfAwareness() {
            this.UiPersistenceService.setUiPersistenceInnerKey(
                this.UiPersistenceService.keys.contactFormDynamicOnboarding,
                'homeVisitCount',
                this.MAX_CF_AWARENESS_SHOWS
            );
        },

        _setVisibleContactFormAwareness() {

            let cfOnboardingPersistanceHash = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.contactFormDynamicOnboarding,
                {homeVisitCount: 0}
            );

            let countVisited = cfOnboardingPersistanceHash.homeVisitCount || 0;

            let isFeatureVisible = countVisited < this.MAX_CF_AWARENESS_SHOWS && this.user.isShowContactFormAwareness();

            if (isFeatureVisible) {
                this.UiPersistenceService.setUiPersistenceInnerKey(
                    this.UiPersistenceService.keys.contactFormDynamicOnboarding,
                    'homeVisitCount',
                    countVisited + 1
                );
            }

            return isFeatureVisible;
        },

        _load() {
            this.HomeService.validateUserShouldSeeHome();

            var mayExtendTrial = this.user.hasTrialExpired() && !this._.isEmpty(this.UsersManager.fillExtendTrialData({}));

            const bookeepingPromise = this.fetchBookkeeping();
            const tasksPromise = this.fetchTasks();
            const calendarPromise = this.fetchCalendar();
            const inquiriesAndActivityPromise = this.fetchInquiriesAndRecentActivity();
            const reportPromise = this.fetchReport();

            this.$q.all([bookeepingPromise, tasksPromise, calendarPromise, inquiriesAndActivityPromise, reportPromise]).finally(() => {
                this.$window.performance && this.$window.performance.mark('hb_home_shown');
            });
            // We should keep the '=== false' check here, because we want to show the toast only when the user came from
            // the email verification mail with the 'email_verification_succeeded' param set to false.
            if (this.$stateParams.email_verification_succeeded === false) {
                this.ToastService.showError({
                    titleTranslation: 'EMAIL_VERIFICATION.TOAST._EMAIL_VERIFICATION_FAILED_TITLE_',
                    contentTranslation: 'EMAIL_VERIFICATION.TOAST._EMAIL_VERIFICATION_FAILED_SUB_TITLE_',
                    dismissOnTimeout: true,
                    timeout: 5000,
                    dismissButton: true
                });
            } else if (this.$stateParams.email_verification_succeeded) {
                this.ToastService.showSuccess({
                    titleTranslation: 'EMAIL_VERIFICATION.TOAST._EMAIL_WAS_VERIFIED_TITLE_',
                    contentTranslation: 'EMAIL_VERIFICATION.TOAST._EMAIL_WAS_VERIFIED_SUB_TITLE_',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16 charcoal-60',
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout: 5000
                });
            }
        },
        _loadOnboardingContent: function _loadOnboardingContent() {
            // Prevent streamlined users from seeing 'interactive'
            this.userIsRecentSetupGuide = this.FeaturesService.wasCreatedAfterFeature(this.user, this.FeaturesService.features.interactiveSetupGuideEndTest);
            this.shouldShowInteractive = !this.user.is_activated && this.userIsRecentSetupGuide;
            this.onboardingCardPersistence = this.SetupGuideService.getPersistenceHomeOnboardingCard();
            this.hasClickedCompleteMwebWizard = this.onboardingCardPersistence.hasClickedCompleteMwebWizard;
            this.hasMarkedComplete = this.onboardingCardPersistence.hasMarkedComplete;
            if (this.$stateParams.openOnboardingCard) {
                this.isOnboardingCardCollapsed = false;
                this.SetupGuideService.setPersistenceHomeOnboardingCard({ isCollapsed: this.isOnboardingCardCollapsed });
            } else {
                this.isOnboardingCardCollapsed = this.onboardingCardPersistence.isCollapsed;
            }

            this.onboardingResources = this.SetupGuideService.getInteractiveResources();
            this.intentOnboardingPersistence = this.SetupGuideService.getPersistenceIntentOnboarding();
        },

        applyOldHomeContent: function applyOldHomeContent() {
            this._load();

            this.register(this.AccountsService, this.Enums.PubSubTriggers.companyChanged, () => {
                this._load();
            });
            this.testimonial = this.getTestimonial();
            this.footerLinks = this.getHomeForTrialFooterLinks();

            this.leadsSourceTooltip = {
                title : this.$translate.instant('HOME.TOOLTIPS.REPORTS.LEADS._TITLE_'),
                text: this.$translate.instant('HOME.TOOLTIPS.REPORTS.LEADS._TEXT_'),
                ctaText: this.$translate.instant('HOME.TOOLTIPS.REPORTS.LEADS._CTA_TEXT_'),
                ctaHandler: () => {
                    this.goToState(this.AppStates.root_core_navigation_reports_analytics, {section: 'lead_source'});
                }
            };

            this.cfAwarenessProTip = {
                isVisible: this._setVisibleContactFormAwareness(),
                onClick: () => {
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.home_contact_form_awareness);
                    this._hideCfAwareness();
                    this.goToState(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
                },
                boldText: this.$translate.instant('CONTACT_FORM.HOME_AWARENESS._PRO_TIP_'),
                text: this.$translate.instant('CONTACT_FORM.HOME_AWARENESS._TEXT_'),
                linkText: this.$translate.instant('CONTACT_FORM.HOME_AWARENESS._LINK_TEXT'),
            };
        },

        isShowIntentOnboarding: function isShowIntentOnboarding() {
            if(this.isIntentOnboardingCompleted || this.user.is_activated){
                return false;
            }
            return this.IntentService.isShowReactOnboarding();
        },

        isCompletedOnboarding: function isCompletedOnboarding () {
            const wizardSteps = this.OnboardingService && this.OnboardingService.onboardingSequence && this.OnboardingService.onboardingSequence.steps &&
                this.OnboardingService.onboardingSequence.steps.filter(step => step.phase === 'wizard') || [];
            const wizardStepsAreComplete = !!wizardSteps.length && this._.every(
                wizardSteps, wizardStep => wizardStep.status === 'complete'
            );
            return this.isIntentOnboardingCompleted || wizardStepsAreComplete || this.user.is_activated;
        },

        getIntentOnboardingType: function getIntentOnboardingType() {
            if (this.IntentService.shouldGetUploadPdfOnboarding()) {
                return 'uploadPdf';
            }
            if (this.IntentService.shouldGetLeadCaptureIntentOnboarding()) {
                return 'leadCapture';
            }
            if (this.IntentService.shouldGetCrmIntentOnboarding()) {
                return 'crm';
            }
            if (this.IntentService.shouldGetSchedulerIntentOnboarding()) {
                return this.isLeadFormsScheduleIntentOnboarding ? 'schedulerLeadForm' : 'scheduler';
            }
            if (this.IntentService.shouldGetContractV4IntentOnboarding()) {
                return 'contract';
            }
            if (this.isInvoiceServiceTemplatesIntentOnboarding) {
                return 'invoiceServiceTemplates';
            }
            return 'invoice';
        },

        openPreMigrationModal: function openPreMigrationModal(){
        this.ModalService.openPreMigrateUserModal('home');
        },

        openTeamMembersOtamModal: function openTeamMembersOtamModal(source){
            this.ModalService.openTeamMembersOtamModal(source);
        },

        openPicTimeSuccessModal: function openPicTimeSuccessModal(eligible, source){
            this.ModalService.openPicTimeSuccessModal(eligible, source);
        },

        setBackToState: function setBackToState(itemType) {
            this.NavigationService.setBackToState({ name: this.$state.current.name, params: this.$state.params });
        },

        openMwebBlockModal: function openMwebBlockModal() {
            this.ModalService.openMwebBlockModal();
        },

        clickHandlerSetupGuide: function (step) {
            // run the click function
            if (step.clickHandler && angular.isFunction(step.clickHandler)) {
                step.clickHandler();
            }

            // track analytics
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.setup_guide_modal_task, {
                type: step.listKey === 'exploreItems' ? 'explore' : 'customize',
                setup_task: step.key,
                is_completed: step.isComplete,
                source: 'home_for_trialers',
                cta_location: 'list',
            });
        },

        getTestimonial: function getTestimonial() {
            const testimonialsForConsultants = [
                {
                    author: 'Danielle B, Business Consultant',
                    avatarPublicId : 'marketing_site/member-love/photos/hero/danielle_boucher',
                    body: 'I love that I can use one system to manage client projects and payments. I previously had to use 5 different platforms to do what HoneyBook does. The automation and organization saves me so much time every day!'
                }
            ];

            const defaultTestimonials = [
                {
                    author: 'Jesse Debusk',
                    avatarPublicId : 'marketing_site/member-love/photos/hero/jesse_debusk',
                    body: 'HoneyBook has made the most tedious part of my job easy! It\'s given me more time to focus on doing what I love.'
                },
                {
                    author: 'Mark & Nicole Pasternack',
                    avatarPublicId : 'marketing_site/member-love/photos/hero/mark_nicole_pasternack',
                    body: 'HoneyBook has been a LIFE changer. HoneyBook has saved countless hours... and helped me book more clients.',
                },
                {
                    author: 'Samantha Heberlein',
                    avatarPublicId : 'marketing_site/member-love/photos/hero/Samantha_Heberlein',
                    body: 'HoneyBook has made booking and scheduling clients so much easier. It\'s like adding another employee to our business!',
                },
                {
                    author: 'Jacob Connell Photography',
                    avatarPublicId : 'marketing_site/member-love/photos/hero/jacob_connell',
                    body: 'All entrepreneurs should be using this platform to streamline their client communications, organize projects and manage invoicing.',
                },
            ];

            const testimonials = this.user.isConsultantCopyChangedEnabled() ? testimonialsForConsultants : defaultTestimonials;

            const randomIndex = this._.random(0, testimonials.length - 1);
            return testimonials[randomIndex];
        },

        getHomeForTrialFooterLinks : function getHomeForTrialFooterLinks() {
            return [
                {url: 'https://www.honeybook.com/product-education', title: 'Webinars'},
                {url: 'https://help.honeybook.com/', title: 'Help Center'},
                {url: 'https://www.honeybook.com/blog', title: 'Blog'},
            ];
        },

        fetchBookkeeping: function fetchBookkeeping() {
            this.fetchingBookkeeping = true;
            return this.HomeManager.getHomebookkeeping().then(function success(resp) {
                    this.overduePayments = resp.data.overdue_payments;
                    this.recentPayments = resp.data.recent_payments;
                    this.emptyStateBookkeeping = this.overduePayments === 0 && this.recentPayments === 0;
            }.bind(this)).finally(function final() {
                this.fetchingBookkeeping = false;
            }.bind(this));
        },

        fetchTasks: function fetchTasks() {
            this.fetchingTasks = true;
            return this.TasksManager.fetchUserTasksCount().then(function success(resp) {
                this.allTasks = resp.data.all;
                this.todayTasks = resp.data.today;
                this.emptyStateTasks = this.allTasks === 0 && this.todayTasks === 0;
            }.bind(this)).finally(function final() {
                this.fetchingTasks = false;
            }.bind(this));
        },

        fetchCalendar: function fetchCalendar() {
            this.fetchingCalendar = true;
            var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            return this.HomeManager.getCalendar(timezone).then(function success(resp) {
                this.eventsToday = resp.data.today;
                this.eventsThisWeek = resp.data.this_week;
                this.emptyStateCalendar = this.eventsToday === 0 && this.eventsThisWeek === 0;
            }.bind(this)).finally(function final() {
                this.fetchingCalendar = false;
            }.bind(this));
        },

        fetchInquiriesAndRecentActivity: function fetchInquiriesAndRecentActivity() {
            this.fetchingInquiriesAndRecentActivity = true;
            var inquiriesPromise = this.WorkspacesManager.getPipelineWorkspacesCounts([this.user._id]).then(function success(resp) {
                var stages = resp.data.user_pipeline_stages;
                var inquiryStage = this._.find(stages, function(stage) {
                   return stage.type_cd === 0;
                });

                this.inquiryStageId = inquiryStage._id;

                this.pendingInquiriesCount = inquiryStage.counts.active;
                var params = {
                    page: 1,
                    perPage: this.INQUIRIES_TO_SHOW,
                    leanFormat: true,
                    sortBy: 'created_at',
                    sortDesc: true,
                    stageId: this.inquiryStageId
                };

                var promise = this.$q.when({data: {data: []}});

                if (this.pendingInquiriesCount) {
                    promise = this.WorkspacesManager.getPipelineWorkspacesPromise(params);
                }

                return promise;
            }.bind(this)).then(function success(resp) {
                this.pendingInquiries = resp.data.data;
            }.bind(this));

            var recentActivityPromise = this.HomeManager.getRecentActivity().then(function(resp) {
                this.recentActivitiesCount = resp.data.recent_activities_count;
                this.recentActivities = resp.data.recent_activities;
            }.bind(this));

            return this.$q.all([inquiriesPromise, recentActivityPromise]).finally(function final() {
                this.fetchingInquiriesAndRecentActivity = false;
            }.bind(this));
        },


        isShowReportsEmptyState: function isShowReportsEmptyState() {
            return this.activeUser && !this.topLeadSourceLabel && !this.valueOfBookingForYear;
        },

        isShowOnboarding: function isShowOnboarding () {
            return this.user.onboarding_container &&
                (this.onboardingCardPersistence && !this.onboardingCardPersistence.hasMarkedComplete);
        },

        fetchReport: function fetchReport() {
            this.fetchingReport = true;
            var startDate = this.moment().startOf('year').format('YYYY-MM-DD');
            var endDate = this.moment().endOf('year').format('YYYY-MM-DD');
            var leadSourcePromise = this.ReportsManager.getLeadsReportTotals(undefined, startDate, endDate).then(function success(resp) {

                this.leadSourceNewBadgeSeenCount = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showLeadsReportNewBadge, {count: 0}).count;
                if (this.leadSourceNewBadgeSeenCount < 3) {
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadsReportNewBadge, {count: this.leadSourceNewBadgeSeenCount + 1});
                }


                var topLeadSource = this._.find(resp.data.leads, function (leadSource) {
                    return leadSource.label !== undefined && leadSource.label !== 'Unknown';
                });
                if (topLeadSource) {
                    this.topLeadSourceLabel = topLeadSource.label;
                }
            }.bind(this));

            var reportAnalyticsPromise = this.ReportsManager.getUserBookings(null, 'home').then(function success(resp) {
                this.bookingAmount = resp.data && resp.data[0] ? resp.data[0].booked_value_sum : 0;
                if (this.bookingAmount) {
                    this.valueOfBookingForYear = this.$filter('hbthousandSuffix')(this.bookingAmount);
                }
            }.bind(this));

            return this.$q.all([leadSourcePromise, reportAnalyticsPromise]).finally(function final() {
                this.fetchingReport = false;
            }.bind(this));
        },

        goTo: function goTo(view, analyticsEvent) {
            // track
            this.trackAnalytics(analyticsEvent);

            // route
            this.goToState(view, {});
        },

        trackAnalytics: function(analyticsEvent){
            this.AnalyticsService.trackClick(this.$scope, analyticsEvent, {
                variant: this.isHomeForTrialer ? 'home_for_trialers' : 'home_for_subscribers',
                is_big_card: analyticsEvent === 'payments' ? this.showBigPaymentsCard : undefined,
                home_poc_abtest_variant: analyticsEvent === 'payments' ? this.homePaymentsCardVariant : undefined,
                was_invoice_sent: this.wasInvoiceSent
            });
        },

        analyticsProperties: function analyticsProperties() {
            return { source: 'home' };
        },

        goToReferrals: function goToReferrals() {
            this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.my_benefits_button_click, {
                campaign_uid: this.ReferralService.userCampaignUid
            });
        },

        clickOnRecentActivities: function clickOnRecentActivities(recentActivity) {
            const homeTypes = this.Enums.homeRecentActivityTypes;
            if (recentActivity.type === this.Enums.homeRecentActivityTypes.feed_message) {
                this.NavigationService.setBackToState({ name: this.$state.current.name, params: this.$state.params });
                this.goToState(this.AppStates.root_core_navigation_event_workspace_feed, {event_id: recentActivity.project_id, workspace_id: recentActivity.workspace_id});
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.go_to_workspace);
            } else if (recentActivity.type === this.Enums.homeRecentActivityTypes.contract_signed) {
                this.goToState(this.AppStates.root_core_workspaceFile, {workspace_file_id: recentActivity.workspace_file_id});
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.go_to_contract);
            } else if (recentActivity.type === this.Enums.homeRecentActivityTypes.flow_contract_signed) {
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.go_to_flow_contract);
                this.FlowService.goToResponses(recentActivity.flow_id);
            } else if (recentActivity.type === this.Enums.homeRecentActivityTypes.flow_payment_paid) {
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.go_to_flow_payment);
                this.FlowService.goToResponses(recentActivity.flow_id);
            } else if ([homeTypes.payment_paid, homeTypes.legacy_file_created, homeTypes.legacy_file_sent, homeTypes.legacy_file_completed, homeTypes.legacy_file_viewed].includes(recentActivity.type)) {
                this.goToState(this.AppStates.root_core_workspaceFile, {workspace_file_id: recentActivity.workspace_file_id});
            } else if ([homeTypes.smart_file_created, homeTypes.smart_file_sent, homeTypes.smart_file_completed, homeTypes.smart_file_viewed, homeTypes.flow_services_selected].includes(recentActivity.type)) {
                this.FlowService.goToResponses(recentActivity.flow_id);
            } else if ([homeTypes.session_scheduled, homeTypes.session_rescheduled, homeTypes.session_canceled].includes(recentActivity.type)) {
                this.goToState(this.AppStates.root_core_navigation_calendar, {dateTimeStart: recentActivity.date_time_start});
            }

        },

        openMobileAppIntro: function openMobileAppIntro() {
            if (!this.isMobileDevice) {
                this.ReactModalService.openMobileAppQRCodeModalRoot().finally(function (result) {
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.mobileAppIntro, {dontShow: true});
                }.bind(this));
            }
        },

        isOnboardingCollapsed: function isOnboardingCollapsed() {
            return (
            // T1 collapsed state
            this.isOnboardingCardCollapsed
            ||
            // React intent onboarding collapsed by click
            this.OnboardingService.isIntentOnboardingCollapsed
            ||
            // React intent onboarding persistency collapsed
            this.intentOnboardingPersistence.isCollapsed
            );
        },

        isFadeActive: function isFadeActive() {

            var userIsNotActivated = !this.user.is_activated;

            var setupGuideIsActive =
                this.userIsRecentSetupGuide
                && (!this.OnboardingService.allOnboardingStepsAreComplete()
                || !this.onboardingCardPersistence.hasMarkedComplete);

                const shouldFade = this.isShowOnboarding()
                && setupGuideIsActive
                && userIsNotActivated
                && !this.isOnboardingCollapsed()
                && !this.OnboardingService.allOnboardingStepsAreComplete();

            return shouldFade;
        },

        fetchShouldShowForm1099DownloadBanner: function fetchShouldShowForm1099DownloadBanner() {
            return new Promise((resolve) => {
                const user = this.UsersManager.getCurrUser();
                const isUSCompany = user.company.country === 'US';
                const persistencyKey = this.UiPersistenceService.keys.hideForm1099DownloadBanner;
                const persistency = this.UiPersistenceService.getUiPersistence(persistencyKey, { value: false }).value;

                if (persistency) {
                    resolve(false);
                }

                this.StatsigService.isGateEnabled('tax_documents_download_banner').then(function (isEnabled) {
                    if (isUSCompany && isEnabled) {
                        return this.UsersManager.getUserTaxDocuments()
                            .then(function success(response) {
                                const hasDocuments = response.data.length > 0;
                                resolve(hasDocuments);
                            })
                            .catch(function fail(error) {
                                resolve(false);
                            });
                    } else {
                        resolve(false);
                    }
                }.bind(this));
            });
        },
        closeForm1099DownloadBanner: function closeForm1099DownloadBanner() {
            this.AnalyticsService.trackClick(this.$scope, this.AnalyticsService.analytics_events.finance_1099_download_banner_dismiss);
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.hideForm1099DownloadBanner, { value: true });
            this.shouldShowForm1099DownloadBanner = false;
        },
      goToCoronaPage: function goToCoronaPage() {
            this.goToState(this.AppStates.root_core_navigation_corona);
      },

      dismissCoronaBanner() {
            this.shouldShowCoronaBanner = false;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showHomeCoronaBanner, { show: false })
      },

      getShouldShowCoronaBanner() {
            const userIsActivated = this.user.isActivated();
            const coronaBannerPersistence = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.showHomeCoronaBanner,
                { show: true }
            )
            return userIsActivated && coronaBannerPersistence.show;
      },

      checkShouldPresentTINResolveModal() {
          this.StatsigService.isGateEnabled('tin_resolve_modal').then(function (isEnabled) {
              if (this.user.company.country !== 'US' || !isEnabled) {
                  return;
              }

              const persistency = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.taxEntityUpdated);

              if (persistency && Number(persistency.lastUpdateYear) >= Number(this.Gon.finance_tax_year)) {
                  return;
              }

              this.UsersManager.getUserTaxEntities(this.user._id).then(res => {
                  const shouldPresentTINResolveModal = res.data.some(entity => entity.last_tin_verification.status === 'mismatch') || res.data.length > 1;

                  if (shouldPresentTINResolveModal) {
                      this.ReactModalService.openTINResolveModal();
                  }
              });
          }.bind(this));
      },

      taxInfoPageRedirect() {
            this.AnalyticsService.trackClick(this.$scope, this.AnalyticsService.analytics_events.finance_1099_download_banner_cta);
            this.$state.go(this.AppStates.root_core_navigation_settings_company_tax_information);
      },

      financeAppRedirect() {
            this.AnalyticsService.trackClick(this.$scope, this.AnalyticsService.analytics_events.finance_invite_banner);
            this.goToState(this.AppStates.root_core_navigation_finance);
      }

    });
}());
