/**
 * Created by inonstelman on 12/31/14.
 */

common_module.constant('Constants', {

    storage: {
        AUTH_TOKEN: 'HB_AUTH_TOKEN',
        TRUSTED_DEVICE: 'HB_TRUSTED_DEVICE',
        MFA_SESSION: 'HB_MFA_SESSION',
        API_VERSION: 'HB_API_VERSION',
        AUTH_USER_ID: 'HB_AUTH_USER_ID',
        CURR_USER: 'HB_CURR_USER',
        SESSION_COMPANY_ID: 'SESSION_COMPANY_ID',
        IS_TEMP_PASS: 'HB_IS_TEMP_PASS',
        COUPON: 'HB_COUPON',
        TEMPLATES: 'HB_TEMPLATES',
        FLOW_TEMPLATES: 'HB_FLOW_TEMPLATES',
        IMPORT_JB_TEMPLATES: 'import_jb_templates',
        IMPORT_FU_TEMPLATE: 'hb_import_fu_template',
        IMPORT_PUBLIC_TEMPLATE: 'import_public_template',
        OWN_ID_SESSION_TOKEN: 'HB_OWN_ID_SESSION_TOKEN',
    },

    events: {
        SHOW_AVATAR_POPUP: 'SHOW_AVATAR_POPUP',
        CLOSE_AVATAR_POPUP: 'CLOSE_AVATAR_POPUP'
    },

    AppStyleTypes: {
        small: 0,
        medium: 1,
        large: 2,
        xlarge: 3,
        xxlarge: 4
    },

    OnboardingOverviewStates: {
        INCOMPLETE: 0,
        COMPLETE: 1
    },

    BlockReasons: [
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._NO_RESPONSE_', serverValue: 'NoResponse'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._OUTSIDE_BUDGET_', serverValue: 'OutsideBudget'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._UNAVAILABLE_', serverValue: 'Unavailable'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._CORONA_', serverValue: 'Corona'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._LOST_TO_COMPETITOR_', serverValue: 'LostToCompetitor'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._NOT_A_GOOD_FIT_', serverValue: 'NotGoodFit'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._DUPLICATE_', serverValue: 'Duplicate'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._PROPOSAL_EXPIRED_', serverValue: 'ProposalExpired'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._CANCELED_', serverValue: 'Canceled'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._COMPLETED_', serverValue: 'Completed'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._OTHER_', serverValue: 'Other'}
    ],


    // we use this only for displaying old block tags in the pipeline
    BlockReasonsExt: [
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._LACKING_SERVICES_', serverValue: 'LackingServices'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._PROJECT_CANCELED_', serverValue: 'ProjectWasCancelled'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._SPAM_', serverValue: 'Spam'},
        {displayNameKey: 'PIPELINE.BLOCK_REASONS._ARCHIVED_', serverValue: 'Archived'}
    ],

    BlockReasonsForReferrals: ['OutsideBudget', 'Unavailable', 'NotGoodFit', 'LackingServices', 'Other'],

    MeetingTypes: [
        {label: 'In person', key: 'in_person', subField: 'location'},
        {label: 'Phone call', key: 'phone_call', subField: 'phoneCallNumber'},
        {label: 'Video call', key: 'video_call', subField: 'videoConferenceType'},
        {label: 'Personal', key: 'personal'},
        {label: 'Other', key: 'other'}
    ],

    WindowTypes: [
        { label: 'Indefinitely', key: 'indefinitely' },
        { label: 'Rolling window', key: 'rolling_window'},
        { label: 'Fixed date range', key: 'fixed_date_range'}
    ],

    VideoConferenceTypes: [
        { label: 'Google', key: 'google' },
        { label: 'Zoom', key: 'zoom' },
        { label: 'Other', key: 'other' }
    ],

    CalendarAvailabilityTypes: [
        { label: 'Free', key: 'free' },
        { label: 'Busy', key: 'busy' }
    ]
});

